export const CICO__LOAD_PACKAGES__REQUEST = 'CICO__LOAD_PACKAGES__REQUEST';
export const CICO__LOAD_PACKAGES__SUCCESS = 'CICO__LOAD_PACKAGES__SUCCESS';
export const CICO__LOAD_PACKAGES__FAILURE = 'CICO__LOAD_PACKAGES__FAILURE';

export const CICO__CANCEL_SUBSCRIPTION__REQUEST = 'CICO__CANCEL_SUBSCRIPTION__REQUEST';
export const CICO__CANCEL_SUBSCRIPTION__SUCCESS = 'CICO__CANCEL_SUBSCRIPTION__SUCCESS';
export const CICO__CANCEL_SUBSCRIPTION__FAILURE = 'CICO__CANCEL_SUBSCRIPTION__FAILURE';

export const CICO__CONFIRM_ORDER__REQUEST = 'CICO__CONFIRM_ORDER__REQUEST';
export const CICO__CONFIRM_ORDER__SUCCESS = 'CICO__CONFIRM_ORDER__SUCCESS';
export const CICO__CONFIRM_ORDER__FAILURE = 'CICO__CONFIRM_ORDER__FAILURE';

export const CICO__GET_AVAILABLE_PRODUCTS__REQUEST = 'CICO__GET_AVAILABLE_PRODUCTS__REQUEST';
export const CICO__GET_AVAILABLE_PRODUCTS__SUCCESS = 'CICO__GET_AVAILABLE_PRODUCTS__SUCCESS';
export const CICO__GET_AVAILABLE_PRODUCTS__FAILURE = 'CICO__GET_AVAILABLE_PRODUCTS__FAILURE';

export const CICO__GET_ORDER_URL__REQUEST = 'CICO__GET_ORDER_URL__REQUEST';
export const CICO__GET_ORDER_URL__SUCCESS = 'CICO__GET_ORDER_URL__SUCCESS';
export const CICO__GET_ORDER_URL__FAILURE = 'CICO__GET_ORDER_URL__FAILURE';

export const CICO__GET_SUBSCRIPTION_STATUS__REQUEST = 'CICO__GET_SUBSCRIPTION_STATUS__REQUEST';
export const CICO__GET_SUBSCRIPTION_STATUS__SUCCESS = 'CICO__GET_SUBSCRIPTION_STATUS__SUCCESS';
export const CICO__GET_SUBSCRIPTION_STATUS__FAILURE = 'CICO__GET_SUBSCRIPTION_STATUS__FAILURE';

export const CICO__TEST__REQUEST = 'CICO__TEST__REQUEST';
export const CICO__TEST__SUCCESS = 'CICO__TEST__SUCCESS';

export const CICO__SET_PARAM = 'CICO__SET_PARAM';

export const RESET_STATE = 'RESET_STATE';
