/**
 * Remove "" prefix in event json object
 * @param  {json} epgItem [description]
 * @return {json}         [description]
 */
export const removeEpgEventsPrefix = (epgItem) =>
  Object.keys(epgItem).reduce((res, dataItem) => (
    {...res,
      [dataItem.replace("", "")]: epgItem[dataItem]
    }
  ), {});
