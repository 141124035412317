import { getClientConfig } from '../helpers/portal/getClientConfig';
import { post } from '../helpers';
import { actionTypes as types, urls } from '../constants';

export const fetch_profile_eventAudioChange = (dispatch, param) => post({
  url: `${getClientConfig().mwUrl}/public/profile/eventAudioChange`,
  body: {
    ...(param.selectedChannelItem) ? {channelsId: param.selectedChannelItem.channels_id} : {},
    ...(param.selectedVod) ? {vodsId: param.selectedVod.vods_id} : {},
    language: param.variantItem.role,
  },
  param,
  request: types.PROFILE_EVENT_AUDIO_CHANGE_REQUEST,
  success: types.PROFILE_EVENT_AUDIO_CHANGE_SUCCESS,
  failure: types.PROFILE_EVENT_AUDIO_CHANGE_FAILURE,
  dispatch,
});

export const fetch_profile_eventSubtitleChange = (dispatch, param) => post({
  url: `${getClientConfig().mwUrl}/public/profile/eventSubtitleChange`,
  body: {
    ...(param.selectedChannelItem) ? {channelsId: param.selectedChannelItem.channels_id} : {},
    ...(param.selectedVod) ? {vodsId: param.selectedVod.vods_id} : {},
    language: param.variantItem.role,
  },
  param,
  request: types.PROFILE_EVENT_SUBTITLE_CHANGE_REQUEST,
  success: types.PROFILE_EVENT_SUBTITLE_CHANGE_SUCCESS,
  failure: types.PROFILE_EVENT_SUBTITLE_CHANGE_FAILURE,
  dispatch,
});

export const fetch_profile_update = (dispatch, param) => post({
  url: `${getClientConfig().mwUrl}/public/profile/update`,
  body: { ...param },
  request: types.PROFILE_UPDATE_REQUEST,
  success: types.PROFILE_UPDATE_SUCCESS,
  failure: types.PROFILE_UPDATE_FAILURE,
  dispatch,
  param,
});

export const fetch_profile_addFavoriteChannel = (dispatch, param) => post({
  url: `${getClientConfig().mwUrl}/public/profile/addFavoriteChannel`,
  body: { ...param },
  request: types.PROFILE_ADD_FAVORITE_CHANNEL_REQUEST,
  success: types.PROFILE_ADD_FAVORITE_CHANNEL_SUCCESS,
  failure: types.PROFILE_ADD_FAVORITE_CHANNEL_FAILURE,
  dispatch,
  param,
});

export const fetch_profile_removeFavoriteChannel = (dispatch, param) => post({
  url: `${getClientConfig().mwUrl}/public/profile/removeFavoriteChannel`,
  body: { ...param },
  request: types.PROFILE_REMOVE_FAVORITE_CHANNEL_REQUEST,
  success: types.PROFILE_REMOVE_FAVORITE_CHANNEL_SUCCESS,
  failure: types.PROFILE_REMOVE_FAVORITE_CHANNEL_FAILURE,
  dispatch,
  param,
});
