import { getUnixTime } from 'date-fns';

export const getProgressTimeForEpgItem = (epgItem) =>
  epgItem?.start
  && epgItem?.end
  && parseInt(
  (
    (getUnixTime(new Date()) - getUnixTime(new Date(epgItem.start))) / // was played
    (getUnixTime(new Date(epgItem.end)) - getUnixTime(new Date(epgItem.start))) // duration
  ) * 100,
  10,
);
