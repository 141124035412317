export const CUSTOMER_LOGIN_FAILURE = 'CUSTOMER_LOGIN_FAILURE';
export const CUSTOMER_LOGIN_REQUEST = 'CUSTOMER_LOGIN_REQUEST';
export const CUSTOMER_LOGIN_SUCCESS = 'CUSTOMER_LOGIN_SUCCESS';

export const CUSTOMER_LOGIN_GOOGLE_FAILURE = 'CUSTOMER_LOGIN_GOOGLE_FAILURE';
export const CUSTOMER_LOGIN_GOOGLE_REQUEST = 'CUSTOMER_LOGIN_GOOGLE_REQUEST';

export const CUSTOMER_LOGIN_FACEBOOK_FAILURE = 'CUSTOMER_LOGIN_FACEBOOK_FAILURE';
export const CUSTOMER_LOGIN_FACEBOOK_REQUEST = 'CUSTOMER_LOGIN_FACEBOOK_REQUEST';

export const CUSTOMER_LOGIN_APPLE_FAILURE = 'CUSTOMER_LOGIN_APPLE_FAILURE';
export const CUSTOMER_LOGIN_APPLE_REQUEST = 'CUSTOMER_LOGIN_APPLE_REQUEST';
export const CUSTOMER_REGISTER_APPLE_FAILURE = 'CUSTOMER_REGISTER_APPLE_FAILURE';
export const CUSTOMER_REGISTER_APPLE_REQUEST = 'CUSTOMER_REGISTER_APPLE_REQUEST';

export const CUSTOMER_GET_DATA_REQUEST = 'CUSTOMER_GET_DATA_REQUEST';
export const CUSTOMER_GET_DATA_SUCCESS = 'CUSTOMER_GET_DATA_SUCCESS';
export const CUSTOMER_GET_DATA_FAILURE = 'CUSTOMER_GET_DATA_FAILURE';

export const CUSTOMER_REQUEST_LOGIN_QR_CODE_REQUEST = 'CUSTOMER_REQUEST_LOGIN_QR_CODE_REQUEST';
export const CUSTOMER_REQUEST_LOGIN_QR_CODE_SUCCESS = 'CUSTOMER_REQUEST_LOGIN_QR_CODE_SUCCESS';
export const CUSTOMER_REQUEST_LOGIN_QR_CODE_FAILURE = 'CUSTOMER_REQUEST_LOGIN_QR_CODE_FAILURE';

export const CUSTOMER_LOGIN_WITH_QR_CODE_REQUEST = 'CUSTOMER_LOGIN_WITH_QR_CODE_REQUEST';
export const CUSTOMER_LOGIN_WITH_QR_CODE_SUCCESS = 'CUSTOMER_LOGIN_WITH_QR_CODE_SUCCESS';
export const CUSTOMER_LOGIN_WITH_QR_CODE_FAILURE = 'CUSTOMER_LOGIN_WITH_QR_CODE_FAILURE';

export const CUSTOMER_GET_QR_CODE_DATA_REQUEST = 'CUSTOMER_GET_QR_CODE_DATA_REQUEST';
export const CUSTOMER_GET_QR_CODE_DATA_SUCCESS = 'CUSTOMER_GET_QR_CODE_DATA_SUCCESS';
export const CUSTOMER_GET_QR_CODE_DATA_FAILURE = 'CUSTOMER_GET_QR_CODE_DATA_FAILURE';

export const CUSTOMER_RESET_SCHEME_AND_DATA = 'CUSTOMER_RESET_SCHEME_AND_DATA';
