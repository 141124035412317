import { actionTypes as types } from '../constants';

import {
  fetch_cico_confirmOrder,
  fetch_cico_getAvailableProducts,
  fetch_cico_getOrderUrl,
  fetch_cico_getSubscriptionStatus,
  fetch_cico_subscriptionSummaryCall,
} from '../fetchs';

export const cico_subscriptionSummaryCall = (param) => dispatch => {
  // dispatch({ type: types.CICO__LOAD_PACKAGES__REQUEST });
  const {viewers_id, redirect_url} = param;

  // 1) zjistit subscrition
  // 2) zjistit balíčky
  // 3) ke každému udělat order linky
  dispatch({
    type: types.CICO__LOAD_PACKAGES__REQUEST,
    param,
  });
  fetch_cico_getSubscriptionStatus(dispatch, {viewers_id})
  .then(
    subscriptionsData => {
      const subscriptions = subscriptionsData.status === 1 && subscriptionsData.response;
      fetch_cico_getAvailableProducts(dispatch, {viewers_id})
      .then(
        productsData => {
          const products = productsData.status === 1 && productsData.response;

          const productsWithUrlRequest = products.map(productItem => {
            return fetch_cico_getOrderUrl(dispatch, {viewers_id, redirect_url, products_id: productItem.products_id}).then(
              orderUrlData => {
                const orderUrl = orderUrlData.status === 1 && orderUrlData.response;
                return {...productItem,
                  orderUrl,
                  status: {
                    ...( // Kuba tuto podmínku ví a chápe, dotazy zodpoví
                      (
                        (subscriptions?.status?.products_id === 3 || subscriptions?.status?.products_id === 4)
                        && (productItem.products_id === 3 || productItem.products_id === 4)
                      )
                      ||
                      (
                        (productItem.products_id === 8)
                        && subscriptions?.status?.products_id === 8
                      )
                    )
                      ? {...subscriptions}
                      : undefined
                  },
                };
              },
              error => {
                console.error("fetch_cico_getOrderUrl", {error});
              },
            )
          });

          const test = async() => {

          }

          Promise.all(productsWithUrlRequest)
          .then(
            productsWithUrl => {
              dispatch({
                type: types.CICO__LOAD_PACKAGES__SUCCESS,
                dataForReducer: {
                  availableProducts: productsWithUrl,
                  status: subscriptions,
                  needRefreshAllService: false,
                  userHasPayedAnyPackages: subscriptions?.status?.cico_orders_status
                }
              });

            },
            error => {
              console.error({error});
            }
          )

        },
        error => {
          console.error("fetch_cico_getAvailableProducts", error);
        }
      )
    },
    error => {
      console.error("fetch_cico_getSubscriptionStatus", error);
    }
  )


  // dispatch(cico_getAvailableProducts());
  // // dispatch(cico_getOrderUrl(param));
  // dispatch(cico_getSubscriptionStatus());
}

export const cico_cancelSubscription = (param) => dispatch =>
  fetch_cico_subscriptionSummaryCall(dispatch, param);

export const cico_confirmOrder = (param) => dispatch =>
  fetch_cico_confirmOrder(dispatch, param);

export const cico_getAvailableProducts = (param) => dispatch =>
  fetch_cico_getAvailableProducts(dispatch, param);

export const cico_getOrderUrl = (param) => dispatch =>
  fetch_cico_getOrderUrl(dispatch, param);

export const cico_getSubscriptionStatus = (param) => dispatch =>
  fetch_cico_getSubscriptionStatus(dispatch, param);

export const cico_setParam = (param) => dispatch => {
  dispatch({
    type: types.CICO__SET_PARAM,
    param: param,
  });
}
