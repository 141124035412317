import { actionTypes as types } from '../constants';

const defaultState = {
  loaded: true,
  authorization: undefined,
  profilesId: undefined,
  confirmRegistrationIsDone: undefined,
  confirmRegistrationIsFailed: false,
  qrCodeForLogin: undefined,
  qrCodeIsLoading: false,
};

const customer = (
  state = defaultState,
  action,
) => {
  switch (action.type) {

    case types.SMS__DEVICES__API__CONFIRM_REGISTRATION__REQUEST:
      return {...state,
        loaded: false,
      };
    case types.SMS__DEVICES__API__CONFIRM_REGISTRATION__SUCCESS:
      return {...state,
        loaded: true,
        confirmRegistrationIsDone: true,
      };
    case types.SMS__DEVICES__API__CONFIRM_REGISTRATION__FAILURE:
      return {...state,
        loaded: true,
        confirmRegistrationIsDone: false,
        confirmRegistrationIsFailed: true,
      };

    case types.CUSTOMER_REQUEST_LOGIN_QR_CODE_REQUEST:
      return {...state,
        qrCodeIsLoading: true,
      };
    case types.CUSTOMER_REQUEST_LOGIN_QR_CODE_SUCCESS:
      return {...state,
        qrCodeIsLoading: false,
        qrCodeForLogin: action.data.response,
      };
    case types.CUSTOMER_REQUEST_LOGIN_QR_CODE_FAILURE:
      return {...state,
        qrCodeIsLoading: false,
      };

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;

    case types.RESET_STATE:
      return defaultState;
    default:
      return state;
  }
};

export default customer;
