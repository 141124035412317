import { filterOnlySafeBoxItemInArr } from './filterOnlySafeBoxItemInArr';
import { getExtendedBoxItem } from './getExtendedBoxItem';
import * as boxItemTypes from '../../constants/boxItemTypes';

export const transformRecommendationRowResponseForReduce = ({
  includeData = false,
  myListItems = [],
  prevRecommendationRowGen = {},
  recommendationRowResponse,
  recordingItems = [],
  rowStyle,
} = {}) => {
  return [
    {
      ...recommendationRowResponse,
      generation: (prevRecommendationRowGen.generation || 0) + 1,
      loaded: true,
      itemsWasLoaded: includeData,
      wasLoaded: includeData,
      items: includeData
        ? filterOnlySafeBoxItemInArr(recommendationRowResponse.data).map(
            (boxItem) => ({
              ...getExtendedBoxItem(boxItem,{
                myListItems,
                recordingItems,
              }, rowStyle),
            })
          )
        : [],
      data: undefined, // small memory help
    },
  ].map((rowItem) => ({
    ...rowItem,
    isOnlyImageItems: rowItem.items.every(
      (boxItem) => boxItem.type === boxItemTypes.BOX_ITEM_IMAGE_TYPE
    ),
  }))[0];
};
