import { formatISO } from "date-fns";

import { getClientConfig } from "../helpers/portal/getClientConfig";
import { isBetween } from "../helpers/datetime";
import {
  fetch_epg_loadEventForPlayerV2,
  fetch_recommendation_addToMyList,
  fetch_recommendation_getEventRecommendationRows,
  fetch_recommendation_getHomepage,
  fetch_recommendation_getHomepageRow,
  fetch_recommendation_getMyList,
  fetch_recommendation_removeFromMyList,
  fetch_recording_getRecordingsByProfile,
} from "../fetchs";
import { actionTypes as types } from "../constants";
import { getExtendEpgItem } from "../helpers/epg/getExtendEpgItem";
import { insertNewBoxItemsToRecommendation } from "../helpers/recommendation/insertNewBoxItemsToRecommendation";
import { isSuccessResponse } from "../helpers/fetch/isSuccessResponse";
import { post } from "../helpers";
import { removeRowWithIdFromRecommendationState } from "../helpers/recommendation/removeRowWithIdFromRecommendationState";
import { rowsDataInFlatView } from "../helpers/recommendation/rowsDataInFlatView";
import { transformRecommendationResponseForReduce } from "../helpers/recommendation/transformRecommendationResponseForReduce";

export const recommendation_reset = () => (dispatch) => {
  dispatch({ type: types.RECOMMENDATION_RESET });
};

export const recommendation_getHomepage =
  ({ includeData = false } = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.RECOMMENDATION_HOMEPAGE_REQUEST });

    // (key && dispatch({type: types.RECOMMENDATION_LOAD_ROWS_WITH_KEY_REQUEST, key}));

    const { hpRowsResponse, myListResponse, recordingsResponse } =
      await Promise.all([
        dispatch(fetch_recommendation_getHomepage({ includeData })),
        // my přímo ty další dva nepotřebujeme, ale připravíme si to skrze fetchCache pro budoucnost
        dispatch(fetch_recommendation_getMyList()),
        dispatch(fetch_recording_getRecordingsByProfile()),
      ]).then(([hpRowsResponse, myListResponse, recordingsResponse]) => ({
        hpRowsResponse,
        myListResponse,
        recordingsResponse,
      }));

    if (!isSuccessResponse(hpRowsResponse)) {
      dispatch({ type: types.RECOMMENDATION_HOMEPAGE_FAILURE });
      return;
    }

    const myListItems = isSuccessResponse(myListResponse)
      ? myListResponse.response
      : getState().recommendation.myList;

    const recordingItems = isSuccessResponse(recordingsResponse)
      ? rowsDataInFlatView(recordingsResponse.response)
      : getState().recording.items;

    dispatch({
      type: types.RECOMMENDATION_HOMEPAGE_SUCCESS,
      recommendationUpdate: transformRecommendationResponseForReduce({
        recommendationResponse: hpRowsResponse.response,
        includeData,
        myListItems,
        recordingItems,
        prevRecommendationRows: getState().recommendation.rows,
      }),
      myListItems,
      recordingItems,
    });
  };

export const recommendation_getHomepageRow =
  ({ id: insertToRowWithId }) =>
  async (dispatch, getState) => {
    dispatch({ type: types.RECOMMENDATION_HOMEPAGE_ROW_REQUEST });

    const { rowItemResponse, myListResponse, recordingsResponse } =
      await Promise.all([
        dispatch(
          fetch_recommendation_getHomepageRow({ id: insertToRowWithId })
        ),
        dispatch(fetch_recommendation_getMyList()),
        dispatch(fetch_recording_getRecordingsByProfile()),
      ]).then(([rowItemResponse, myListResponse, recordingsResponse]) => ({
        rowItemResponse,
        myListResponse,
        recordingsResponse,
      }));

    if (!isSuccessResponse(rowItemResponse)) {
      dispatch({
        type: types.RECOMMENDATION_HOMEPAGE_ROW_FAILURE,
        recommendationUpdate: removeRowWithIdFromRecommendationState(
          insertToRowWithId,
          getState().recommendation
        ),
      });
      return;
    }

    const myListItems = isSuccessResponse(myListResponse)
      ? myListResponse.response
      : getState().recommendation.myList;

    const recordingItems = isSuccessResponse(recordingsResponse)
      ? rowsDataInFlatView(recordingsResponse.response)
      : getState().recording.items;

    dispatch({
      type: types.RECOMMENDATION_HOMEPAGE_ROW_SUCCESS,
      recommendationUpdate:
        rowItemResponse.response.data.length > 0
          ? insertNewBoxItemsToRecommendation({
              boxItems: rowItemResponse.response.data,
              insertToRowWithId,
              recommendationPrevState: getState().recommendation,
              myListItems,
              recordingItems,
              rowStyle: rowItemResponse.response.style,
            })
          : removeRowWithIdFromRecommendationState(
              insertToRowWithId,
              getState().recommendation
            ),
    });
  };

export const recommendation_remove = (param) => (dispatch) => {
  dispatch({
    type: types.RECOMMENDATION_REMOVE_REQUEST,
    param: param,
  });
  post({
    url: `${
      getClientConfig().mwUrl
    }/public/recommendationEngine/removeRecommendation`,
    body: param,
    param: param,
    success: types.RECOMMENDATION_REMOVE_SUCCESS,
    failure: types.RECOMMENDATION_REMOVE_FAILURE,
    dispatch,
  });
};

export const recommendation_getEventRecommendationRows = ({id, type}) => async(dispatch, getState) => {
    if (!id || !type) {
      console.error("Missing param in recommendation_getEventRecommendationRows",
        { id, type }
      );
      dispatch({
        type: types.RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_FAILURE,
        param,
      });
      return;
    }

    const { rowsResponse, myListResponse, recordingsResponse } =
      await Promise.all([
        dispatch(fetch_recommendation_getEventRecommendationRows({id, type})),
        dispatch(fetch_recommendation_getMyList()),
        dispatch(fetch_recording_getRecordingsByProfile()),
      ]).then(([rowsResponse, myListResponse, recordingsResponse]) => ({
        rowsResponse,
        myListResponse,
        recordingsResponse,
      }));

    if (!isSuccessResponse(rowsResponse)) {
      dispatch({ type: types.RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_FAILURE });
      return;
    }

    const myListItems = isSuccessResponse(myListResponse)
      ? myListResponse.response
      : getState().recommendation.myList;

    const recordingItems = isSuccessResponse(recordingsResponse)
      ? rowsDataInFlatView(recordingsResponse.response)
      : getState().recording.items;

    dispatch({
      type: types.RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_SUCCESS,
      recommendationUpdate: transformRecommendationResponseForReduce({
        recommendationResponse: rowsResponse.response,
        includeData : true,
        myListItems,
        recordingItems,
        prevRecommendationRows: getState().recommendation.rows,
      }),
      myListItems,
      recordingItems,
    });

  };

export const recommendation_getSubviewEventRecommendationRows =
  ({id, type}) => async(dispatch, getState) => {
    if (!id || !type) {
      console.error(
        "Missing param in recommendation_getSubviewEventRecommendationRows",
        { id, type }
      );
      dispatch({
        type: types.RECOMMENDATION_GET_SUBVIEW_EVENT_RECOMMENDATION_ROWS_FAILURE,
        param,
      });
      return;
    }

    const { rowsResponse, myListResponse, recordingsResponse } =
      await Promise.all([
        dispatch(fetch_recommendation_getEventRecommendationRows({id, type})),
        dispatch(fetch_recommendation_getMyList()),
        dispatch(fetch_recording_getRecordingsByProfile()),
      ]).then(([rowsResponse, myListResponse, recordingsResponse]) => ({
        rowsResponse,
        myListResponse,
        recordingsResponse,
      }));

    if (!isSuccessResponse(rowsResponse)) {
      dispatch({ type: types.RECOMMENDATION_GET_SUBVIEW_EVENT_RECOMMENDATION_ROWS_FAILURE });
      return;
    }

    const myListItems = isSuccessResponse(myListResponse)
      ? myListResponse.response
      : getState().recommendation.myList;

    const recordingItems = isSuccessResponse(recordingsResponse)
      ? rowsDataInFlatView(recordingsResponse.response)
      : getState().recording.items;

    dispatch([{
      type: types.RECOMMENDATION_GET_SUBVIEW_EVENT_RECOMMENDATION_ROWS_SUCCESS,
      recommendationUpdate: transformRecommendationResponseForReduce({
        recommendationResponse: rowsResponse.response,
        includeData : true,
        myListItems,
        recordingItems,
        prevRecommendationRows: getState().recommendation.rowsSubview,
      }),
      myListItems,
      recordingItems,
    }]
    .map(dispatchContentItem => ({...dispatchContentItem,
      recommendationUpdate: {...dispatchContentItem.recommendationUpdate,
        rows: getState().recommendation.rows, // řádky musíme nechat
        rowsSubview: dispatchContentItem.recommendationUpdate.rows, // a nové řádky si dáme do druhého pohledu
      }
    }))[0]);



    // post({
    //   url: `${
    //     getClientConfig().mwUrl
    //   }/public/recommendationEngine/getEventRecommendationRows`,
    //   body: param,
    //   request:
    //     types.RECOMMENDATION_GET_SUBVIEW_EVENT_RECOMMENDATION_ROWS_REQUEST,
    //   success:
    //     types.RECOMMENDATION_GET_SUBVIEW_EVENT_RECOMMENDATION_ROWS_SUCCESS,
    //   failure:
    //     types.RECOMMENDATION_GET_SUBVIEW_EVENT_RECOMMENDATION_ROWS_FAILURE,
    //   dispatch,
    //   param,
    // });
  };

export const  recommendation_addToMyList = (param) => async (dispatch, getState) => {
  await fetch_recommendation_addToMyList(dispatch, param);
  const myListResponse = await dispatch(fetch_recommendation_getMyList());

  const myListItems = isSuccessResponse(myListResponse)
    ? myListResponse.response
    : getState().recommendation.myList;

  dispatch({
    type: types.RECOMMENDATION_UPDATE_MY_LIST_SUCCESS,
    recommendationUpdate: transformRecommendationResponseForReduce({
      myListItems,
    }),
    myListItems,
  });
};

export const recommendation_removeFromMyList = (param) => async (dispatch, getState) => {
  await fetch_recommendation_removeFromMyList(dispatch, param);
  const myListResponse = await dispatch(fetch_recommendation_getMyList())

  const myListItems = isSuccessResponse(myListResponse)
    ? myListResponse.response
    : getState().recommendation.myList;

  dispatch({
    type: types.RECOMMENDATION_UPDATE_MY_LIST_SUCCESS,
    recommendationUpdate: transformRecommendationResponseForReduce({
      myListItems,
    }),
    myListItems,
  });
};

export const recommendation_loadExtendedInfoForRowItem =
  ({ rowItem }) =>
  async (dispatch) => {
    dispatch({
      type: types.RECOMMENDATION_LOAD_EXTENDED_INFO_FOR_ROW_ITEM_REQUEST,
    });

    const channelItems = rowItem.items
      .filter((item) => item.type === "Channel")
      .map((channelItem) => channelItem.id);
    if (channelItems.length === 0) return;
    const epgResponse = await fetch_epg_loadEventForPlayerV2(dispatch, {
      channels: channelItems,
      timestamp: 0,
      from: formatISO(new Date()),
      to: formatISO(new Date()),
    });

    if (!isSuccessResponse(epgResponse)) return;
    const extendedRowItem = {
      ...rowItem,
      items: rowItem.items.map((item) => ({
        ...item,
        actualPlayingEpg: [
          epgResponse.response.find(
            (epgItem) =>
              epgItem.channels_id === item.id &&
              isBetween(
                new Date(),
                new Date(epgItem.start),
                new Date(epgItem.end)
              )
          ),
        ]
          .map((epgItem) => getExtendEpgItem(epgItem, {}))
          .map((epgItem) => ({
            ...epgItem,
            description: epgItem.desc,
            type: "TV",
          }))[0],
      })),
    };

    dispatch({
      type: types.RECOMMENDATION_LOAD_EXTENDED_INFO_FOR_ROW_ITEM_SUCCESS,
      extendedRowItem,
    });
  };
