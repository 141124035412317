import { actionTypes as types, urls } from '../constants';
import { post } from '../helpers';
import { getClientConfig } from '../helpers/portal/getClientConfig';


export const messaging_unsubscribeToken = (token) => dispatch => {
  post({
    url: `${getClientConfig().mwUrl}/public/messaging/unsubscribeToken`,
    body: { token },
    request: types.MESSAGING_UNSUBSCRIBE_TOKEN_REQUEST,
    success: types.MESSAGING_UNSUBSCRIBE_TOKEN_SUCCESS,
    failure: types.MESSAGING_UNSUBSCRIBE_TOKEN_FAILURE,
    dispatch,
  });
}
