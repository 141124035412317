import { actionTypes as types, urls } from '../constants';
import { fetch_edges_itemSpeedTest, fetch_edges_getList } from '../fetchs/edge';
import { isSuccessResponse } from '../helpers/fetch/isSuccessResponse';
import { post, get } from '../helpers';


export const edges_init = (param) => async(dispatch) => {
  dispatch({ type: types.EDGE_INIT_REQUEST });
  const edgesListResponse = await fetch_edges_getList(dispatch, param);

  if(
    !isSuccessResponse(edgesListResponse)
    || !(!!edgesListResponse?.response)
    || edgesListResponse?.response.length === 0
  ) dispatch({ type: types.EDGE_INIT_FAILURE });

  await allSettled( // original Promise.allSettled, but tizen doesn know it
    edgesListResponse.response.map(responseItem =>
      fetch_edges_itemSpeedTest(dispatch, {edgeItem: responseItem})
    )
  ).then(
    success => {
      dispatch({ type: types.EDGE_INIT_SUCCESS });
    },
    error => {
      dispatch({ type: types.EDGE_INIT_FAILURE, error });
    }
  );
};



export const edges_refresh = (param) => dispatch => {
  fetch_edges_getList(dispatch, param);
};

export const edges_itemSpeedTest = (param) => dispatch => {
  fetch_edges_itemSpeedTest(dispatch, param);
};

const allSettled = async(promises) => {
  let wrappedPromises = promises.map(p => Promise.resolve(p)
    .then(
      val => ({ status: 'fulfilled', value: val }),
      err => ({ status: 'rejected', reason: err })));
  return Promise.all(wrappedPromises);
};
