import { actionTypes as types, urls } from '../constants'

import { post } from '../helpers'

export * from './category';
export * from './confirmDialog';
export * from './customer';
export * from './device';
export * from './edge';
export * from './epg';
export * from './error';
export * from './firebase';
export * from './channel';
export * from './messaging';
export * from './person';
export * from './player';
export * from './portal';
export * from './profile';
export * from './provider';
export * from './recommendation';
export * from './recording';
export * from './search';
export * from './sms';
export * from './tvGuide';
export * from './user';
export * from './universal';
export * from './vendor';
export * from './vod';

export * from '../client-extensions/cico/actions/index';


export const resetState = () => dispatch => {
  dispatch({type: types.RESET_STATE});
};

export const loginWithToken = () => (dispatch, getState) => {
  const token = getState().user.token;

  if (typeof token === 'undefined') return;

  dispatch({ type: types.LOGIN_REQUEST });
  post({
    url: urls.LOGIN_WITH_TOKEN,
    body: { token },
    success: types.LOGIN_SUCCESS,
    failure: types.LOGIN_FAILURE,
    dispatch,
  });
};
