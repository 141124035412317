import { getJSONCheckSum } from '../json/getJSONCheckSum';

export const getPrevOrLastElement = (selectedElement, array) => {
  // console.log("getPrevOrLastElement", {
  //   selectedElement, array,
  //   index: getIndexOfElementByCheckSum(selectedElement, array),
  // });
  const indexOfActualElement = getIndexOfElementByCheckSum(selectedElement, array);

  return (indexOfActualElement < 0) ? array[0] // nic jsme nenašli, vrátíme první objekt
  : (indexOfActualElement === 0) ? array[array.length - 1] // jsme na první položce, vrátíme poslední
  : array[indexOfActualElement - 1]; // vrátíme předchozí element
};

const getIndexOfElementByCheckSum = (selectedElement, array) =>
  array.findIndex(arrayItem =>
    true || isVariableJSONType(selectedElement) // je vybraný element JSON a nebo String? Nyní umíme jen JSON
      ? getJSONCheckSum(arrayItem) === getJSONCheckSum(selectedElement) // jsou JSON stejné?
      : arrayItem === selectedElement // nebo to jsou stejné string?
  );

const isVariableJSONType = (variable) => {
  try {
      return JSON.parse(variable);
  } catch (e) {
      return false;
  }
};
