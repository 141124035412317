import { getClientConfig } from '../helpers/portal/getClientConfig';
import { getClientStorage } from '../helpers/device/clientStorage';
import { actionTypes as types } from '../constants';

import {
  fetch_device_getData,
  fetch_device_getDevice,
  fetch_device_removeDevice,
} from '../fetchs';


export const device_getDevices = (param) => async(dispatch, getState) => {
  fetch_device_getDevice(dispatch, param);
};

export const device_getData = ({
  authorization = getClientStorage('authorization'),
  profilesId = getClientStorage('profilesId'),
} = {}) => async(dispatch, getState) => {
  if(!authorization || !profilesId) return;

  const param = {
    devicesIdentification: getState().portal.deviceInfo,
    devicesHash: getState().portal.deviceHash,
  };

  (getClientConfig().firebase)
    ? device_getDataWithFirebase(dispatch, param)
    : device_getDataBasic(dispatch, param);
};

const device_getDataWithFirebase = async(dispatch, param) => {
  const { firebaseMessaging } = await import('../firebase');
  firebaseMessaging.getToken({ vapidKey: getClientConfig().firebase.vapidKey }).then(async (googleToken) => {
    if (googleToken) {
      await fetch_device_getData(dispatch, {...param,
        googleToken,
      });
      dispatch({
        type: types.FIREBASE_SAVE_TOKEN,
        googleToken,
      });

    } else {
      console.error('No registration token available. Request permission to generate one.');
      device_getDataBasic(dispatch, param);
    }
  }).catch((err) => {
    console.error('An error occurred while retrieving token. ', {cause: err});
    device_getDataBasic(dispatch, param);
  });
}

const device_getDataBasic = (dispatch, param) => {
  fetch_device_getData(dispatch, param);
}

export const device_removeDevice = (param) => dispatch => {
  fetch_device_removeDevice(dispatch, param);
}
