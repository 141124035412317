import { actionTypes as types } from '../constants';
import { getClientConfig } from '../helpers/portal/getClientConfig';
import { getEpgItemPlayerUrl } from '../helpers/epg/getEpgItemPlayerUrl';
import { post } from '../helpers';

import {
  fetch_vendor_getData,
  fetch_channel_getStartupChannel,
} from './index';

import { device_getData } from '../actions';

export const fetch_customer_getData = (dispatch, param) => post({
  url: `${getClientConfig().mwUrl}/public/customer/getData`,
  body: { ...param },
  request: types.CUSTOMER_GET_DATA_REQUEST,
  success: types.CUSTOMER_GET_DATA_SUCCESS,
  failure: types.CUSTOMER_GET_DATA_FAILURE,
  dispatch,
  param,
});

export const fetch_customer_requestLoginQRCode = (dispatch, param) => post({
  url: `${getClientConfig().mwUrl}/public/customer/requestLoginQRCode`,
  body: { ...param },
  request: types.CUSTOMER_REQUEST_LOGIN_QR_CODE_REQUEST,
  success: types.CUSTOMER_REQUEST_LOGIN_QR_CODE_SUCCESS,
  failure: types.CUSTOMER_REQUEST_LOGIN_QR_CODE_FAILURE,
  dispatch,
  param,
});

export const fetch_customer_getQRCodeData = (dispatch, param) => post({
  url: `${getClientConfig().mwUrl}/public/customer/getQRCodeData`,
  body: { ...param },
  request: types.CUSTOMER_GET_QR_CODE_DATA_REQUEST,
  success: types.CUSTOMER_GET_QR_CODE_DATA_SUCCESS,
  failure: types.CUSTOMER_GET_QR_CODE_DATA_FAILURE,
  dispatch,
  param,
});

export const fetch_customer_profileSelected = async(dispatch, {
  profilesId,
  customerToken,
}) => {
  const specialHeaders = { // added authorization info
    profilesId: btoa(profilesId),
    Authorization : `Bearer ${customerToken}`,
  };

  const vendorData = await fetch_vendor_getData(dispatch,
    {  // body param
      vendorsId: getClientConfig().vendorId,
    }, specialHeaders
  );
  const startupChannelData = await fetch_channel_getStartupChannel(dispatch, {}, specialHeaders);

  await device_getData(specialHeaders);

  return {
    status: 1,
    response: {
      ...( // není nějaký preferovaný kanál na spuštění -> spustíme
        startupChannelData.status === 1
        && startupChannelData.response?.channels_id
      ) ? {
        preferedUrlAfterLogin: getEpgItemPlayerUrl(
          {...startupChannelData.response,
            isNowPlaying: true
          }, "tv"
        ),
      } : {},
    }
  };


};
