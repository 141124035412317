import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router";

const UniversalDetailDialogCategoryButton = ({detailUniversalDialog, handleCloseUniversalDetailDialog, styles}) => {
  const intl = useIntl()
  const {push} = useHistory()

  const handleClickGoToCategory = () => {
      push(`/category/${detailUniversalDialog.categories_id}`);
    handleCloseUniversalDetailDialog();
  }

  return (
    <Button className={styles.nonPlayButton} onClick={handleClickGoToCategory}>
      <img
        src="/images/icons/ic_open_category.svg"
        alt={intl.formatMessage({
          id: "label_go_to_category_detail",
          defaultMessage: "category detail",
        })}
      />
      {detailUniversalDialog.categories_name}
    </Button>
  );
};

UniversalDetailDialogCategoryButton.propTypes = {
  detailUniversalDialog: PropTypes.object,
  handleCloseUniversalDetailDialog: PropTypes.func.isRequired,
  styles: PropTypes.object
};

export default UniversalDetailDialogCategoryButton;
