/**
 * Get url param from url string
 * @param  {String} key
 * @param  {String} target
 * @return {value}
 * @deprecated use new useQueryParams hook not this
 */
export const getURLParam = (key, target = window.location.href) => {
    var values = [];
    if (!target) target = location.href;

    key = key.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");

    var pattern = key + '=([^&#]+)';
    var o_reg = new RegExp(pattern,'ig');
    while (true){
        var matches = o_reg.exec(target);
        if (matches && matches[1]){
            values.push(matches[1]);
        } else {
            break;
        }
    }

    if (!values.length){
        return null;
    } else {
        return values.length == 1 ? values[0] : values;
    }
}
