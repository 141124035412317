import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "@material-ui/core/Button";
import {
  recommendation_addToMyList,
  recommendation_removeFromMyList,
  universal_loadUniversalDetailDialog,
} from "../../../../actions";
import { useDispatch } from "react-redux";

const UniversalDetailDialogMyListButton = ({
  detailUniversalDialog,
  styles,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleClickAddToMyList = async () => {
    console.log("handleAddToLIst");
    await dispatch(
      recommendation_addToMyList({
        id: detailUniversalDialog.id,
        type: detailUniversalDialog.type,
      })
    );
    dispatch(
      universal_loadUniversalDetailDialog({
        ...detailUniversalDialog,
        isInMyList: true,
      })
    );
  };

  const handleClickRemoveFromMyList = async () => {
    console.log("handleRemoveFromMyList");
    await dispatch(
      recommendation_removeFromMyList({
        id: detailUniversalDialog.id,
        type: detailUniversalDialog.type,
      })
    );
    dispatch(
      universal_loadUniversalDetailDialog({
        ...detailUniversalDialog,
        isInMyList: false,
      })
    );
  };

  return (
    <>
      {detailUniversalDialog.isInMyList ? (
        <Button
          className={styles.nonPlayButton}
          onClick={handleClickRemoveFromMyList}
        >
          <img
            src="/images/icons/ic_remove_from_my_list.svg"
            alt={intl.formatMessage({
              id: "label_event_remove_from_my_list",
              defaultMessage: "Remove from my list",
            })}
          />
          <FormattedMessage
            id="label_event_remove_from_my_list"
            defaultMessage="Remove from my list"
          />
        </Button>
      ) : (
        <Button
          className={styles.nonPlayButton}
          onClick={handleClickAddToMyList}
        >
          <img
            src="/images/icons/ic_add_to_my_list.svg"
            alt={intl.formatMessage({
              id: "label_event_add_my_list",
              defaultMessage: "Add to my list",
            })}
          />
          <FormattedMessage
            id="label_event_add_my_list"
            defaultMessage="Add to my list"
          />
        </Button>
      )}
    </>
  );
};

UniversalDetailDialogMyListButton.propTypes = {
  detailUniversalDialog: PropTypes.object,
  styles: PropTypes.object,
};

export default UniversalDetailDialogMyListButton;
