import { actionTypes as types } from "../constants";
import { getExtendCategoryItem } from "../helpers/category/getExtendCategoryItem";
import { isSuccessResponse } from "../helpers/fetch/isSuccessResponse";
import { rowsDataInFlatView } from "../helpers/recommendation/rowsDataInFlatView";
import { transformRecommendationResponseForReduce } from "../helpers/recommendation/transformRecommendationResponseForReduce";

import {
  fetch_recommendation_getCategory,
  fetch_recommendation_getMyList,
  fetch_recording_getRecordingsByProfile,
} from "../fetchs";

export const category_getCategoryWithMyList =
  ({ categoriesId, saveToSubRecommendationRows = false }) =>
  async (dispatch, getState) => {
    dispatch({ type: types.CATEGORY_GET_CATEGORY_WITH_MY_LIST_REQUEST });

    const { categoriesResponse, myListResponse, recordingsResponse } =
      await Promise.all([
        dispatch(fetch_recommendation_getCategory({ categoriesId })),
        dispatch(fetch_recommendation_getMyList()),
        dispatch(fetch_recording_getRecordingsByProfile()),
      ]).then(
        ([categoriesResponse, myListResponse, recordingsResponse]) => ({
          categoriesResponse,
          myListResponse,
          recordingsResponse,
        }),
        (error) => {
          console.error({ error });
          dispatch({ type: types.CATEGORY_GET_CATEGORY_WITH_MY_LIST_FAILURE });
        }
      );

    if (
      !isSuccessResponse(categoriesResponse) ||
      !isSuccessResponse(myListResponse)
    ) {
      dispatch({ type: types.CATEGORY_GET_CATEGORY_WITH_MY_LIST_FAILURE });
      return;
    }
    const category = { ...categoriesResponse.response.category };

    const myListItems = isSuccessResponse(myListResponse)
      ? myListResponse.response
      : getState().recommendation.myList;

    const recordingItems = isSuccessResponse(recordingsResponse)
      ? rowsDataInFlatView(recordingsResponse.response)
      : getState().recording.items;

    const recommendationUpdate = [
      {
        ...transformRecommendationResponseForReduce({
          recommendationResponse: categoriesResponse.response.rows,
          prevRecommendationRows: getState().recommendation.rows,
          myListItems,
          includeData: true,
          recordingItems,
        }),
      },
    ].map((recommendationUpdateStateItem) => ({
      ...recommendationUpdateStateItem,
      rows: saveToSubRecommendationRows
        ? getState().recommendation.rows
        : recommendationUpdateStateItem.rows,
      rowsSubview: saveToSubRecommendationRows
        ? recommendationUpdateStateItem.rows
        : [],
    }))[0];

    dispatch({
      type: types.CATEGORY_GET_CATEGORY_WITH_MY_LIST_SUCCESS,
      actualCategory: getExtendCategoryItem(category, { myListItems }),
      recommendationUpdate,
    });
  };
