import { v4 as uuidv4 } from "uuid";

import { getUrlFromItemType } from "../helpers/recommendation/getUrlFromItemType";
// import { makeExtendEpgItem } from '../actions';
import { actionTypes as types } from "../constants";
import { filterOnlySafeBoxItemInArr } from "../helpers/recommendation/filterOnlySafeBoxItemInArr";
import { getEpgItemPlayerUrl } from "../helpers/epg/getEpgItemPlayerUrl";
import { getScreenSizeForBoxItem } from "../helpers/recommendation/getScreenSizeForBoxItem";

const defaultState = {
  allRowsIsLoaded: true,
  generation: 0,
  generationSubview: 0,
  loaded: true,
  myList: [],
  rows: [],
  rowsSubview: [],
  rowsLoaded: true,
  rowsWithKey: [],
  similarEvents: [], // nevytvořit pro to fiktivní row?
};

const recommendation = (state = defaultState, action) => {
  switch (action.type) {
    case types.RECOMMENDATION_LOAD_ROWS_WITH_KEY_REQUEST:
      // console.log({action});
      return { ...state, loaded: false };
    case types.RECOMMENDATION_LOAD_ROWS_WITH_KEY_SUCCESS:
      // console.log({action});
      return {
        ...state,
        loaded: true,
        /* ... */
      };

    case types.RECOMMENDATION_HOMEPAGE_REQUEST:
      return { ...state, loaded: false, rows: [] };
    case types.RECOMMENDATION_HOMEPAGE_SUCCESS:
      return {
        ...state,
        loaded: true,
        generation: state.generation + 1,
        ...action.recommendationUpdate,
        myList: action.myListItems,
      };
    case types.RECOMMENDATION_HOMEPAGE_FAILURE:
      return { ...state, loaded: true };

    case types.RECOMMENDATION_HOMEPAGE_ROW_REQUEST:
      return { ...state };
    case types.RECOMMENDATION_HOMEPAGE_ROW_SUCCESS:
      return { ...state, ...action.recommendationUpdate };
    case types.RECOMMENDATION_HOMEPAGE_ROW_FAILURE:
      return { ...state, ...action.recommendationUpdate };

    case types.VOD_GET_LIBRARY_HP_REQUEST:
      return { ...state, loaded: false, rows: [] };
    case types.VOD_GET_LIBRARY_HP_SUCCESS:
      return {
        ...state,
        ...action.recommendationUpdate,
        generation: state.generation + 1,
        loaded: true,
      };
    case types.VOD_GET_LIBRARY_HP_FAILURE:
      return { ...state, loaded: true, rows: [] };

    case types.VOD_GET_LIBRARY_HP_ROW_ITEM_REQUEST:
      return { ...state };
    case types.VOD_GET_LIBRARY_HP_ROW_ITEM_SUCCESS:
      return {
        ...state,
        ...action.recommendationUpdate,
      };
    case types.VOD_GET_LIBRARY_HP_ROW_ITEM_FAILURE:
      return { ...state, ...action.recommendationUpdate };

    case types.SEARCH_GET_HP_REQUEST:
      return { ...state };
    case types.SEARCH_GET_HP_SUCCESS:
      return {
        ...state,
        ...action.recommendationUpdate,
      };
    case types.SEARCH_GET_HP_FAILURE:
      return { ...state, ...action.recommendationUpdate };

    case types.RECOMMENDATION_REMOVE_FAILURE:
      return { ...state, loaded: true };

    case types.RECOMMENDATION_REMOVE_REQUEST:
      return { ...state, loaded: false };
    case types.RECOMMENDATION_REMOVE_SUCCESS:
      return {
        ...state,
        loaded: true,
        rows: state.rows.map((row) => ({
          ...row,
          items: row.items.filter(
            (item) =>
              !(item.type === action.param.type && action.param.id === item.id)
          ),
        })),
      };

    case types.CATEGORY_GET_CATEGORY_WITH_MY_LIST_REQUEST:
      return { ...state, loaded: false, rows: [] };
    case types.CATEGORY_GET_CATEGORY_WITH_MY_LIST_SUCCESS:
      return {
        ...state,
        ...action.recommendationUpdate,
        generation: state.generation + 1,
        loaded: true,
      };
    case types.CATEGORY_GET_CATEGORY_WITH_MY_LIST_FAILURE:
      return { ...state, loaded: true, rows: [] };

    case types.RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_REQUEST:
      return { ...state, loaded: false, rows: [] };
    case types.RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_SUCCESS:
      return {
        ...state,
        ...action.recommendationUpdate,
        generation: state.generation + 1,
        loaded: true,
      };
    case types.RECOMMENDATION_GET_SUBVIEW_EVENT_RECOMMENDATION_ROWS_FAILURE:
      return { ...state, loaded: true, rowsSubview: [] };

    case types.RECOMMENDATION_GET_SUBVIEW_EVENT_RECOMMENDATION_ROWS_REQUEST:
      return { ...state, loaded: false, rowsSubview: [] };
    case types.RECOMMENDATION_GET_SUBVIEW_EVENT_RECOMMENDATION_ROWS_SUCCESS:
      return {
        ...state,
        ...action.recommendationUpdate,
        generation: state.generation + 1,
        loaded: true,
      };
    case types.RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_FAILURE:
      return { ...state, loaded: true, rowsSubview: [] };

    case types.RECORDING_ADD_SUCCESS:
      return {
        ...state,
        rows: state.rows
          .reduce(
            (
              res,
              rowItem // refresh "my recordings"
            ) =>
              rowItem.type === "my recordings"
                ? [
                    ...res,
                    {
                      ...rowItem,
                      needReload: true,
                    },
                  ]
                : [...res, rowItem],
            []
          )
          .map((fullRow) => ({
            ...fullRow, // added isRecordOn in item
            items: fullRow.items.map((rowItem) => ({
              ...rowItem,
              isRecordOn:
                (rowItem.type === "TV" &&
                  rowItem.id === action.param.eventItem.id) ||
                rowItem.isRecordOn,
            })),
          })),
      };
    case types.RECORDING_REMOVE_SUCCESS:
      return {
        ...state,
        rows: state.rows
          .reduce(
            (res, rowItem) =>
              rowItem.type === "my recordings" || rowItem.type === "recording"
                ? [
                    ...res,
                    {
                      ...rowItem,
                      items: rowItem.items.filter(
                        (item) => item.id !== action.param.eventItem.id
                      ),
                    },
                  ]
                : [...res, rowItem],
            []
          )
          .map((fullRow) => ({
            ...fullRow, // remove isRecordOn in item
            items: fullRow.items.map((rowItem) => ({
              ...rowItem,
              isRecordOn:
                !(
                  rowItem.type === "TV" &&
                  rowItem.id === action.param.eventItem.id
                ) || rowItem.isRecordOn,
            })),
          }))
          .map((fullRow) => ({
            ...fullRow,
            items: fullRow.items.filter(
              (rowItem) =>
                !(
                  rowItem.type === "Recording" &&
                  rowItem.id === action.param.eventItem.id
                )
            ),
          })),
      };

    case types.RECOMMENDATION_ADD_TO_MY_LIST_SUCCESS:
      const itemForAdded = action.param;
      return {
        ...state,
        generation: state.generation + 1,
        rows: state.rows.map((rowItem) =>
          rowItem.type === "my list"
            ? { ...rowItem, generation: state.generation + 1, needReload: true }
            : {
                ...rowItem,
                generation: state.generation + 1,
                items: rowItem.items.map((boxItem) => ({
                  ...boxItem,
                  isInMyList:
                    itemForAdded.id === boxItem.id &&
                    itemForAdded.type === boxItem.type
                      ? true
                      : boxItem.isInMyList,
                })),
              }
        ),
      };
    case types.RECOMMENDATION_REMOVE_FROM_MY_LIST_SUCCESS:
      const removeItem = action.param;
      return {
        ...state,
        rows: state.rows.map((rowItem) => ({
          ...rowItem,
          items:
            rowItem.type === "my list"
              ? rowItem.items.filter(
                  (boxItem) =>
                    !(
                      removeItem.id === boxItem.id &&
                      removeItem.type === boxItem.type
                    )
                )
              : rowItem.items.map((boxItem) => ({
                  ...boxItem,
                  ...(removeItem.id === boxItem.id &&
                  removeItem.type === boxItem.type
                    ? { isInMyList: false }
                    : {}),
                })),
        })),
      };

    case types.RECORDING_GET_HP_REQUEST:
      return { ...state, loaded: false, rows: [] };
    case types.RECORDING_GET_HP_SUCCESS:
      return {
        ...state,
        ...action.recommendationUpdate,
        generation: state.generation + 1,
        loaded: true,
      };
    case types.RECORDING_GET_HP_FAILURE:
      return { ...state, loaded: true, rows: [] };

    case types.RECOMMENDATION_RESET:
      return defaultState;

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;

    case types.RESET_STATE:
      return defaultState;

    case types.RECOMMENDATION_UPDATE_MY_LIST_SUCCESS:
      return {
        ...state,
        myList: action.myListItems
      };

    default:
      return state;
  }
};

const getItemsWithLeftPosition = (items) =>
  items.reduce(
    (itemsAcc, item) => [
      ...itemsAcc,
      {
        ...item,
        leftPosition: itemsAcc.reduce(
          (leftPositionAcc, item) => leftPositionAcc + item.screenWidth,
          0
        ),
      },
    ],
    []
  );

const getDefaultRowLeftPosition = (row) =>
  row.items.reduce((acc, item, index) => {
    return row.focus > index ? acc - item.screenWidth : acc;
  }, 0);

const getRowScreenWidth = (rowItems) =>
  rowItems.reduce((acc, rowItem) => acc + rowItem.screenWidth + 2 * 8, 0);

export default recommendation;
