import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, useIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import {getPlayerUrl} from "../../../../helpers/url/getPlayerUrl";
import {useHistory} from "react-router";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  playButton: {
    backgroundColor: "white",
    color: "black",
    padding: "6px 16px 6px 10px ",
    margin: "0 0 0 10px",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.8)",
    },
    "& img": {
      filter: "invert(100%)",
    }
  },
}));

const UniversalDetailDialogPlayButton = ({detailUniversalDialog, handleCloseUniversalDetailDialog}) => {
  const intl = useIntl()
  const {push} = useHistory()
  const styles = useStyles()

  const handleClickPlay = (e) => {
    e.preventDefault();
    push(getPlayerUrl(detailUniversalDialog));
    handleCloseUniversalDetailDialog();
  };

  return (
    <Button className={styles.playButton} onClick={handleClickPlay}>
      <img
        src="/images/icons/ic_play.svg"
        alt={intl.formatMessage({
          id: "label_play",
          defaultMessage: "play",
        })}
      />
      <FormattedMessage id="label_play" defaultMessage="Play"/>
    </Button>
  );
};

UniversalDetailDialogPlayButton.propTypes = {
  detailUniversalDialog: PropTypes.object.isRequired,
  handleCloseUniversalDetailDialog: PropTypes.func.isRequired,
};

export default UniversalDetailDialogPlayButton;
