import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, IconButton
} from "@mui/material";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import CloseIcon from '@mui/icons-material/Close';
import UniversalItemDetails from "./UniversalItemDetails";
import {universal_unloadUniversalDetailDialog} from "../../../actions";
import {useDispatch} from "react-redux";
import UniversalDetailDialogActionButtons from "./UniversalDetailDialogActionButtons";

const useStyles = makeStyles((theme) => createStyles({
  dialogActions: {
    backgroundColor: theme.palette.background.paper,
  },
  dialogContent: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    "& div": {
      marginRight: 15,
    },
  },
  dialogTitle: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  }
}));

const UniversalDetailDialog = ({detailUniversalDialog, detailUniversalDialogIsOpen, theme}) => {
  const dispatch = useDispatch();
  const styles = useStyles(theme);

  const handleCloseUniversalDetailDialog = () => {
    dispatch(universal_unloadUniversalDetailDialog);
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={detailUniversalDialogIsOpen}
        onClose={handleCloseUniversalDetailDialog}
        aria-labelledby="universal-detail-dialog-title"
        aria-describedby="universal-detail-dialog-description"
      >
        <DialogTitle id="universal-detail-dialog-title" className={styles.dialogTitle}>
          {detailUniversalDialog.title && detailUniversalDialog.title}
          <IconButton
            aria-label="close"
            onClick={handleCloseUniversalDetailDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <Card sx={{ minWidth: 300 }}>
            <CardMedia
              component="img"
              image={detailUniversalDialog.image}
              alt={detailUniversalDialog.title}
            />
          </Card>
          <DialogContentText id="universal-detail-dialog-description">
            <UniversalItemDetails {...{detailUniversalDialog}} />
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <UniversalDetailDialogActionButtons {...{detailUniversalDialog, handleCloseUniversalDetailDialog}} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

UniversalDetailDialog.propTypes = {
  detailUniversalDialog: PropTypes.object,
  detailUniversalDialogIsOpen: PropTypes.bool,
};

export default UniversalDetailDialog;
