export const getValuesForSMSErrorCode = (statusCode, pathname) => (
    statusCode === 0 ? {defaultMessage: "It seems we are having some connectivity problems. Please try again.",
        title: "message_connectivity_error",}
  : statusCode === 14008 ? {
    defaultMessage: "Password is not strong enough, please choose another one.",
    title: "message_password_weak",
  }
  : statusCode === 14001 ? {
    defaultMessage: "Incorrect combination of login and password.",
    title: "message_incorrect_login",
  }
  : statusCode === 14006 ? {
    defaultMessage: "MOTV_VALIDATION_ERROR",
    title: "MOTV_VALIDATION_ERROR",
  }
  : statusCode === 14000 ? {
    defaultMessage: "Login is used",
    title: "Login is used",
  }
  : statusCode === 104 && pathname === "/customer/force-change-password" ? {
    defaultMessage: "Password cannot be same as login, please choose another one",
    title: "message_password_same_as_login",
  }
  : statusCode === 104 && pathname === "/settings/customer" ? {
    defaultMessage: "Password cannot be same as login, please choose another one",
    title: "message_password_same_as_login",
  }
  : statusCode === 104 && pathname === "/customer/create" ? {
    defaultMessage: "Password cannot be same as login, please choose another one",
    title: "message_password_same_as_login",
  }
  : statusCode === 104 ? { // action.data.response
    defaultMessage: "Validation error",
    title: "Validation error",
  }
  : {
    defaultMessage: "Ups, we have trouble.",
    title: "Ups, we have trouble.",
  }
);
