import { v4 as uuidv4 } from "uuid";

import { getExtendEpgItem } from "../epg/getExtendEpgItem";
import { getScreenSizeForBoxItem } from "./getScreenSizeForBoxItem";
import { getUrlFromItemType } from "./getUrlFromItemType";

export const getExtendedBoxItem = (
  boxItem,
  { recordingItems = [], myListItems = [], selectedEpgItemInState } = {},
  rowStyle,
) => {
  if (!boxItem) return undefined;

  return {
    ...boxItem,
    key: uuidv4(),
    url: getUrlFromItemType(boxItem),
    ...getScreenSizeForBoxItem(boxItem, rowStyle),
    ...{
      ...getExtendEpgItem(boxItem, {
        myListItems,
        recordingItems,
        selectedItemInState: selectedEpgItemInState,
      }),
    },
    // Jestli se do listopadu 2021 neobjeví problém, smazat zakomentovaný kus kódu - Původní kus kódu - vůbec nevím, proč to měl Petr původně omezené na TV TYPE, podle mě to nedává smysl
    // ...(boxItem.type === boxItemTypes.BOX_ITEM_TV_TYPE) ? {
    //   ...getExtendEpgItem(boxItem, {
    //     myListItems,
    //     recordingItems,
    //     selectedItemInState: selectedEpgItemInState,
    //   }),
    // } : {},
  };
};
