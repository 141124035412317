import { setClientStorage } from '../helpers/device/clientStorage'; // nejsem si moc jist tímto použitím, zkusit přepsat do https://github.com/rt2zz/redux-persist
import { actionTypes as types } from '../constants'

export const fullPlayerDefaultState = {
  advertIsRunning: false, // běží nám reklamy?
  currentTime: undefined, // absolutní čas, v live je jeho začátek někde v minulosti
  duration: undefined, // délka videa v sekundách
  durationLimitForLive: undefined, // v live určíme max hodnotu délky v sekundách
  follow: 0,
  makeHardReload: false,  // používá se pro vynucený restart playeru
  nowLoadedForParams: {},
  percentageProgress: undefined, // aktuální pozice ve videu v procentech
  playerIsInit: false,
  progressBarSize: 100, // velikost progressbaru v procentech
  readAbleVideoActualPosition: undefined, // aktuální čas videa v čitelné podobě
  readAbleVideoDuration: undefined, // celkový čas v čitelné podobě
  remoteChannelsUnicastId: null, // Kubova hodnota pro nějakou vychytávku, posílám to pro widevine
  startTime: undefined, // absolutní čas start videa
  takeRealDurationFromVideoElement: false, // ignoruj nastavený duration a vem si ho z videa
  timeInLive: undefined,
  url: undefined,
  videoActualPosition: undefined, // aktuální pozice ve videu v sekundách
};

const defaultState = {
  confirm: {
    items: [],
    zIndex: 1,
    nextIdIndex: 0,
  },

  currentLastSyncTime: undefined, // nevím
  ...fullPlayerDefaultState,

  audio: [],
  controllersHeight: 80,
  dateOfResponseStream: undefined,
  destroyMe: false,
  drms: undefined,
  edgesId: undefined,
  errors: [],
  isFullScreen: false,
  isLive: undefined,
  isPlaying: false,
  isSeeking: false,
  isTvView: undefined,
  loaded: true,
  needEnterPin: false,
  offset: undefined,
  poster: null,
  preferedVideoVariantItem: undefined,
  reloadingIsInProgress: false,
  selectedAudio: undefined,
  selectedSubtitle: undefined,
  subtitle: [],
  thumbnails: undefined,
  timestampUsedInUrl: undefined,
  urlVersion: 0, // url se občas nemusí lišit a pak nedojde k refreshi, raději budu zvyšovat verzi
  videoControllerHeightOffset: 0,
  videoHeightOffset: 0,
  videoProfiles: [],
  viewWidth: 320,
  volume: 0.8,
};


const noneSubtitleItem = {
  "default": 0,
  "label": "None",
  "order": 0,
  "language": "None",
};

const player = (state = defaultState, action) => {
  switch (action.type) {

    case types.SET_SELECTED_VOD:
      return {
        ...state,
        duration: action.selectedVod.vods_duration,
        currentTime: 0,
      };


    // Full player init
    case types.PLAYER_FULLPLAYER_INIT_REQUEST:
      return {...state,
        ...fullPlayerDefaultState,
        reloadingIsInProgress: true,
      };
    case types.PLAYER_FULLPLAYER_INIT_SUCCESS:
      return {...state,
        ...action.streamData,
        currentTime: action.currentTime,
        dateOfResponseStream: action.dateOfResponseStream,
        duration: action.duration,
        durationLimitForLive: action.durationLimitForLive,
        isTvView: true,
        loaded: true,
        needEnterPin: action.needEnterPin,
        nowLoadedForParams: action.nowLoadedForParams,
        playerIsAbort: action.playerIsAbort,
        progressBarSize: action.progressBarSize,
        readAbleVideoActualPosition: action.readAbleVideoActualPosition,
        readAbleVideoDuration: action.readAbleVideoDuration,
        reloadingIsInProgress: false,
        urlVersion: state.urlVersion + 1,
        videoActualPosition: action.videoActualPosition,
      };
    case types.PLAYER_FULLPLAYER_INIT_FAILURE:
      return {...state,
        loaded: true,
        reloadingIsInProgress: false,
        errors: [...state.errors, { title: `error ${action.error}` }],
      };

    case types.PLAYER_FULLPLAYER_GET_STREAM_URL_SUCCESS:
      return {...state,
        ...action.data.response,
        ...(action.data.response.follow) ? {videoActualPosition: action.data.response.follow} : {},
        urlVersion: state.urlVersion + 1,
        // isLive: action.data.response.live,
        isLive: true,
        playerIsAbort: false,
      };
    case types.PLAYER_FULLPLAYER_GET_STREAM_URL_FAILURE:
      return {...state,
        errors: [...state.errors, { title: `error ${action.data.status}` }],
      };

    case types.PLAYER_FULLPLAYER_GET_EVENT_FAILURE:
      return {...state,
        errors: [...state.errors, { title: `error ${action.data.status}` }],
      };



    case types.PLAYER_ADD_CONFIRM:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          items: state.confirm.items.reduce((res, dataItem) => (
            res.some(resItem => ( // deduplicate item in array
              resItem.description === dataItem.description
              && resItem.title === dataItem.title
            )) ? res : [...res, dataItem]
          ), [action.item]),
          nextIdIndex: state.confirm.nextIdIndex + 1,
        },
      };
    case types.PLAYER_UPDATE_CONFIRM:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          items: state.confirm.items.map(item =>
            item.id === action.id ? { ...item, ...action.param } : item,
          ),
        },
      };
    case types.PLAYER_REMOVE_CONFIRM:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          items: state.confirm.items.filter(item => item.id !== action.id),
        },
      };
    case types.PLAYER_REMOVE_ALL_CONFIRM:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          items: [],
        },
      };
    case types.PLAYER_SET_STEP:
      return { ...state, step: action.step };

    case types.PLAYER_MAKE_HARD_RELOAD:
      return { ...state, makeHardReload: true };

    case types.PLAYER_SET_PARAM:
      return { ...state, ...action.param };

    case types.PLAYER_WAS_LOADED:
      return {...state,
        currentTime: action.currentTime,
        isLive: action.isLive,
        videoActualPosition: action.videoActualPosition,
        progressBarSize: action.progressBarSize,
        durationLimitForLive: action.durationLimitForLive,
      };

    case types.PLAYER_TIME_UPDATE:
      return {...state,
        currentTime: action.currentTime,
        durationLimitForLive: action.durationLimitForLive,
        percentageProgress: action.percentageProgress,
        progressBarSize: action.progressBarSize,
        readAbleVideoActualPosition: action.readAbleVideoActualPosition,
        videoActualPosition: action.videoActualPosition,
        ...(action.startTime !== undefined) ? {startTime: action.startTime} : {},
        ...(action.timeInLive !== undefined) ? {timeInLive: action.timeInLive} : {},
      };

    case types.PLAYER_UPDATE_PROGRESS_BAR_FOR_LIVE:
      return {...state,
        progressBarSize: action.progressBarSize,
        durationLimitForLive: action.durationLimitForLive,
      };

    case types.DRMS_REQUEST:
      return {...state,
        drmsLoaded: false,
      };
    case types.DRMS_SUCCESS:
      setClientStorage('drms', `${JSON.stringify(action.data.response)}`);
      return { ...state, drms: action.data.response, drmsLoaded: true, };
    case types.DRMS_FAILURE:
      return {...state,
        drmsLoaded: true,
      };

    case types.RECORDING_LOAD_PLAYER_REQUEST:
      return {...state,
        ...fullPlayerDefaultState,
        loaded: false,
      }
    case types.RECORDING_LOAD_PLAYER_SUCCESS:
      return [{...state,
        ...action.dataForReduce,
        audio: action?.dataForReduce?.audio || [],
        subtitle: action?.dataForReduce?.subtitle || [],
        loaded: true,
      }]
      .map(stateItem => ({...stateItem,
        subtitle: [noneSubtitleItem, ...stateItem.subtitle]
      }))
      .map(stateItem => ({...stateItem, // musíme to sjednotit kvůli nejednotě s vod X channel
        // audio: cleanFromChannelsPrefix(stateItem.audio),
        // subtitle: cleanFromChannelsPrefix(stateItem.subtitle),
      }))
      .map(stateItem => ({...stateItem,
        selectedAudio: stateItem.audio.find(audioItem => audioItem.default === 1),
        selectedSubtitle: stateItem.subtitle.find(subtitleItem => subtitleItem.default === 1) || stateItem.subtitle[0],
      }))[0]
    case types.RECORDING_LOAD_PLAYER_FAILURE:
      return {...state,
        loaded: true,
      }

    case types.VOD_LOAD_PLAYER_REQUEST:
      return {...state,
        ...fullPlayerDefaultState,
        loaded: false,
        reloadingIsInProgress: true,
      }
    case types.VOD_LOAD_PLAYER_SUCCESS:
      // console.log(action.dataForReduce)
      return [{...state,
        ...action.dataForReduce,
        audio: action.dataForReduce.audio || [],
        subtitle: action.dataForReduce.subtitle || [],
        loaded: true,
        reloadingIsInProgress: false,
        videoProfiles: action.dataForReduce.videoProfiles,
        urlVersion: state.urlVersion + 1,
      }]
      .map(stateItem => ({...stateItem,
        subtitle: [noneSubtitleItem, ...stateItem.subtitle]
      }))
      .map(stateItem => ({...stateItem, // musíme to sjednotit kvůli nejednotě s vod X channel
        audio: stateItem.audio.map(audioItem => (
          Object.keys(audioItem).reduce((res, dataItem) => (
            {...res, [dataItem.replace("vods_", "")]: audioItem[dataItem]}
          ), {})
        )),
        subtitle: stateItem.subtitle.map(subtitleItem => (
          Object.keys(subtitleItem).reduce((res, dataItem) => (
            {...res, [dataItem.replace("vods_", "")]: subtitleItem[dataItem]}
          ), {})
        )),
      }))
      .map(stateItem => ({...stateItem,
        selectedAudio: stateItem.audio.find(audioItem => audioItem.default === 1),
        selectedSubtitle: stateItem.subtitle.find(subtitleItem => subtitleItem.default === 1) || stateItem.subtitle[0],
      }))[0]
    case types.VOD_LOAD_PLAYER_FAILURE:
      return {...state,
        loaded: true,
        reloadingIsInProgress: false,
      }

    case types.CHANNELS_STREAM_URL_FAILURE:
      return { ...state,
        errors: [...state.errors, { title: `error ${action.data.status}` }],
      }

    case types.PLAYER_LOAD_ONLY_SELECTED_EVENT_REQUEST:
      return { ...state,
        ...fullPlayerDefaultState,
        destroyMe: true,
        reloadingIsInProgress: true,
        loaded: false, }
    case types.PLAYER_LOAD_ONLY_SELECTED_EVENT_SUCCESS:
      return { ...state,
        urlVersion: state.urlVersion + 1,
        ...action.streamData,
        currentTime: action.currentTime,
        duration: action.duration,
        durationLimitForLive: action.durationLimitForLive,
        isLive: false,
        isTvView: true,
        loaded: true,
        needEnterPin: action.needEnterPin,
        nowLoadedForParams: action.nowLoadedForParams,
        playerIsAbort: action.playerIsAbort,
        progressBarSize: action.progressBarSize,
        readAbleVideoActualPosition: action.readAbleVideoActualPosition,
        readAbleVideoDuration: action.readAbleVideoDuration,
        reloadingIsInProgress: false,
        timestampUsedInUrl: action.timestampUsedInUrl,
        videoActualPosition: action.videoActualPosition,
      }
    case types.PLAYER_LOAD_ONLY_SELECTED_EVENT_FAILURE:
      return { ...state, loaded: true, reloadingIsInProgress: false, }

    case types.CHANNELS_SET_PARAM:
      return { ...state,
        ...(action.param.selectedItem)
          ? {
            selectedChannelItem: action.param.selectedItem,
            needEnterPin: (
              action.param.selectedItem.channels_forced_pin === 1 // kanál vyžaduje pin
              && (
                !state.selectedChannelItem // a je to první nastavení
                || action.param.selectedItem.channels_id !== state.selectedChannelItem.channels_id // a nebo se změnil kanál
              )
            )
          }
          : {},
      }
    case types.CHANNELS_UNPIN_ACTUAL_SELECTED_CHANNEL:
      return {...state,
        needEnterPin: false,
      }

    case types.PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_REQUEST:
      return {...state,
        ...fullPlayerDefaultState,
        destroyMe: true,
        reloadingIsInProgress: true,
      }
    case types.PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_SUCCESS:
      return {...state,
        ...action.streamData,
        currentTime: action.currentTime,
        dateOfResponseStream: action.dateOfResponseStream,
        duration: action.duration,
        durationLimitForLive: action.durationLimitForLive,
        isTvView: true,
        needEnterPin: action.needEnterPin,
        nowLoadedForParams: action.nowLoadedForParams,
        playerIsAbort: action.playerIsAbort,
        progressBarSize: action.progressBarSize,
        readAbleVideoActualPosition: action.readAbleVideoActualPosition,
        readAbleVideoDuration: action.readAbleVideoDuration,
        reloadingIsInProgress: false,
        urlVersion: state.urlVersion + 1,
        videoActualPosition: action.videoActualPosition,
      }
    case types.PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_FAILURE:
      return {...state, reloadingIsInProgress: false,}

    case types.PROFILE_EVENT_AUDIO_CHANGE_REQUEST:
      return {...state,
        loaded: false,
      }
    case types.PROFILE_EVENT_AUDIO_CHANGE_SUCCESS:
      return {...state,
        loaded: true,
        selectedAudio: action.param.variantItem,
      }
    case types.PROFILE_EVENT_AUDIO_CHANGE_FAILURE:
      return {...state,
        loaded: true,
      }

    case types.PROFILE_EVENT_SUBTITLE_CHANGE_REQUEST:
      return {...state,
        loaded: false,
      }
    case types.PROFILE_EVENT_SUBTITLE_CHANGE_SUCCESS:
      return {...state,
        loaded: true,
        selectedSubtitle: action.param.variantItem,
      }
    case types.PROFILE_EVENT_SUBTITLE_CHANGE_FAILURE:
      return {...state,
        loaded: true,
      }

    case types.PROFILE_EVENT_VIDEO_QUALITY_WAS_CHANGE:
      return {...state,
        preferedVideoVariantItem: action.variantItem,
      }

    case types.PLAYER_LOAD_ACTUAL_EVENT_IN_LIVE_STREAM_REQUEST:
      return {...state, loaded: false,
        ...fullPlayerDefaultState,
        reloadingIsInProgress: true,
      }
    case types.PLAYER_LOAD_ACTUAL_EVENT_IN_LIVE_STREAM_SUCCESS:
      return {...state, loaded: true,
        currentTime: action.currentTime,
        dateOfResponseStream: action.dateOfResponseStream,
        duration: action.duration,
        durationLimitForLive: action.durationLimitForLive,
        progressBarSize: action.progressBarSize,
        readAbleVideoActualPosition: action.readAbleVideoActualPosition,
        readAbleVideoDuration: action.readAbleVideoDuration,
        reloadingIsInProgress: false,
        videoActualPosition: action.videoActualPosition,
      }
    case types.PLAYER_LOAD_ACTUAL_EVENT_IN_LIVE_STREAM_FAILURE:
      return {...state, loaded: true, reloadingIsInProgress: false,}


    case types.PLAYER_RESET:
      return {
        ...defaultState,
        drms: state.drms,
        isFullScreen: state.isFullScreen,
        volume: state.volume,
        preferedVideoVariantItem: state.preferedVideoVariantItem,
        ...action.param,
      }

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;

    case types.RESET_STATE:
      return defaultState
    default:
      return state
  }
}


export default player
