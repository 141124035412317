import { actionTypes as types } from '../constants';
import { concatAndDeDuplicateObjects } from '../helpers/array/concatAndDeDuplicateObjects';

const defaultState = {
  isActive: false,
  text: '',
  wasSearching: null,
  loaded: true,
};

const search = (
  state = defaultState,
  action,
) => {
  switch (action.type) {
    case types.SEARCH_RESET:
      return defaultState;
    case types.SEARCH_TEXT:
      return {...state,
        text: action.text
      };
    case types.RECOMMENDATION_SEARCH_REQUEST:
      return {...state,
        isActive: true,
        loaded: false,
        wasSearching: action.param.search,
        text: action.param.search,
      };
    case types.RECOMMENDATION_SEARCH_SUCCESS:
      return {...state,
        isActive: true,
        loaded: true,
      };
    case types.RECOMMENDATION_SEARCH_FAILURE:
      return {...state,
        isActive: true,
        loaded: true,
      };

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;

    case types.SEARCH_RESET:
      return defaultState;
    default:
      return state;
  }
}

export default search;
