import { endOfDay, formatISO, startOfDay } from 'date-fns';

import { concatAndDeDuplicateObjects } from '../helpers/array/concatAndDeDuplicateObjects';
import { actionTypes as types } from '../constants';

const defaultState = {
  dateCursor: null,
  dateCursorMax: formatISO(endOfDay(new Date())),
  dateCursorMin: formatISO(startOfDay(new Date())),
  dialogEpg: undefined,
  dialogEpgId: undefined,
  dialogEpgIsLoaded: true,
  dialogEpgIsOpen: false,
  hasBasicEpgInfo: false,
  items: [],
  loaded: true,
  // selectedItemId: null, // deprecated
  selectedItem: null,
  searchText: "",
  actualUsedItems: [],
  actualSelectedDate: formatISO(new Date()),
};

const defaultSelectedItem = {
  loaded: true,
}

const epg = (
  state = defaultState,
  action,
) => {
  switch (action.type) {

    /**
     * nizká úroveň, jen si rozšíříme cache "items"
     */
    // case types.EPG_UPDATED_EVENTS_REQUEST:
    //   return {...state,
    //     loaded: false,
    //     actualSelectedDate: action.actualSelectedDate,
    //   };
    case types.EPG_UPDATED_EVENTS_SUCCESS:

      return {...state,
        items: concatAndDeDuplicateObjects('id', state.items, action.data.response),
      }
    // case types.EPG_UPDATED_EVENTS_FAILURE:
    //   return {...state, loaded: true}

    case types.EPG_SET_PARAM:
      return {...state,
        ...action.param,
      }

    case types.EPG_LOAD_DIALOG_REQUEST:
      return {...state,
        dialogEpg: {...action.param.epgItem, loaded: false},
        dialogEpgIsOpen: true,
        loaded: false,
      }
    case types.EPG_LOAD_DIALOG_SUCCESS:
      return {...state,
        dialogEpg: {...action.param.epgItem, loaded: true},
        loaded: true,
      }
    case types.EPG_LOAD_DIALOG_FAILURE:
      return {...state,
        dialogEpg: undefined,
        loaded: true,
      }

    case types.EPG_LOAD_SELECTED_EPG_REQUEST:
      return {...state, loaded: false}
    case types.EPG_LOAD_SELECTED_EPG_SUCCESS:
      return {...state,
        selectedItem: action.param.epgItem,
        loaded: true,
      }
    case types.EPG_LOAD_SELECTED_EPG_FAILURE:
      return {...state,
        selectedItem: undefined,
        loaded: true,
      }

    case types.EPG_LOAD_EVENTS_FOR_DAY_AND_CHANNEL_REQUEST:
      return {...state,
        loaded: false,
      }
    case types.EPG_LOAD_EVENTS_FOR_DAY_AND_CHANNEL_SUCCESS:
      return {...state,
        loaded: true,
        actualUsedItems: action.actualUsedItems,
        actualSelectedDate: action.actualSelectedDate,
      }

    case types.EPG_UPDATE_SEARCH:
      return {...state,
        loaded: true,
        actualUsedItems: action.actualUsedItems,
      }


    case types.PLAYER_LOAD_ONLY_SELECTED_EVENT_REQUEST:
      return {...state,
        actualUsedItems: action.actualUsedEpgItems || state.actualUsedItems,
      }
    case types.PLAYER_LOAD_ONLY_SELECTED_EVENT_SUCCESS:
      return {...state,
        selectedItem: action.selectedEvent,
        actualUsedItems: action.actualUsedEpgItems,
      }

    case types.PLAYER_LOAD_ACTUAL_EVENT_IN_LIVE_STREAM_SUCCESS:
      return {...state,
        actualUsedItems: action.actualUsedEpgItems || state.actualUsedItems,
        selectedItem: action.selectedEvent,
      }

    case types.PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_REQUEST:
      return {...state,
        ...(action.actualUsedEpgItems) ? {actualUsedItems: action.actualUsedEpgItems} : {},
      }
    case types.PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_SUCCESS:
      return {...state,
        selectedItem: action.selectedEvent,
      }
    case types.PLAYER_RESET:
      return {...state,
        selectedItem: undefined,
      }

    case types.PLAYER_FULLPLAYER_INIT_SUCCESS:
      return {...state,
        selectedItem: action.selectedEvent,
      }


    // case types.RECOMMENDATION_GET_MY_LIST_REQUEST:
    //   return {...state,
    //     selectedItem: state.selectedItem && {...state.selectedItem, loaded: false},
    //     dialogEpg: state.dialogEpg && {...state.dialogEpg, loaded: false}
    //   }
    // case types.RECOMMENDATION_GET_MY_LIST_SUCCESS:
    //   return {...state,
    //     selectedItem: state.selectedItem
    //       && {...state.selectedItem,
    //         loaded: true,
    //         isInMyList: action.data.response.some(myListItem => (
    //           myListItem.id === state.selectedItem.id
    //           && (myListItem.type === "TV" || myListItem.type === "Recording")
    //         )),
    //     },
    //     dialogEpg: state.dialogEpg
    //       && {...state.dialogEpg,
    //         loaded: true,
    //         isInMyList: action.data.response.some(myListItem => (
    //           myListItem.id === state.dialogEpg.id
    //           && (myListItem.type === "TV" || myListItem.type === "Recording")
    //         )),
    //     }
    //   }
    // case types.RECOMMENDATION_GET_MY_LIST_FAILURE:
    //   return {...state,
    //     selectedItem: state.selectedItem && {...state.selectedItem, loaded: true},
    //     dialogEpg: state.dialogEpg && {...state.dialogEpg, loaded: true},
    //   }
    case types.RECOMMENDATION_ADD_TO_MY_LIST_REQUEST:
      return {...state,
        selectedItem: (state?.selectedItem?.id === action.param.id)
          ? {...state.selectedItem, loaded: false} : state.selectedItem,
        dialogEpg: (state?.dialogEpg?.id === action.param.id)
          ? {...state.dialogEpg, loaded: false} : state.dialogEpg,
      }
    case types.RECOMMENDATION_ADD_TO_MY_LIST_SUCCESS:
      return {...state,
        selectedItem: (state?.selectedItem?.id === action.param.id)
          ? {...state.selectedItem, loaded: true, isInMyList: true} : state.selectedItem,
        dialogEpg: (state?.dialogEpg?.id === action.param.id)
          ? {...state.dialogEpg, loaded: true, isInMyList: true} : state.dialogEpg,
        actualUsedItems: state.actualUsedItems.map(epgItem => ({...epgItem,
          ...(
            epgItem.id === action.param.id
          )
            ? {
              isInMyList: true,
              hasReminderMark: action.param.reminderTime !== null,
            }
            : {}
        })),
      }
    case types.RECOMMENDATION_ADD_TO_MY_LIST_FAILURE:
      return {...state,
        selectedItem: (state?.selectedItem?.id === action.param.id)
          ? {...state.selectedItem, loaded: true} : state.selectedItem,
        dialogEpg: (state?.dialogEpg?.id === action.param.id)
          ? {...state.dialogEpg, loaded: true} : state.dialogEpg,
      }
    case types.RECOMMENDATION_REMOVE_FROM_MY_LIST_REQUEST:
      return {...state,
        selectedItem: (state?.selectedItem?.id === action.param.id)
          ? {...state.selectedItem, loaded: false} : state.selectedItem,
        dialogEpg: (state?.dialogEpg?.id === action.param.id)
          ? {...state.dialogEpg, loaded: false} : state.dialogEpg,
      }
    case types.RECOMMENDATION_REMOVE_FROM_MY_LIST_SUCCESS:
      return {...state,
        selectedItem: (state?.selectedItem?.id === action.param.id)
          ? {...state.selectedItem, loaded: true, isInMyList: false} : state.selectedItem,
        dialogEpg: (state?.dialogEpg?.id === action.param.id)
          ? {...state.dialogEpg, loaded: true, isInMyList: false} : state.dialogEpg,
        actualUsedItems: state.actualUsedItems.map(epgItem => ({...epgItem,
          ...(
            epgItem.id === action.param.id
          )
            ? {
              isInMyList: false,
              hasReminderMark: false,
            }
            : {}
        })),
      }
    case types.RECOMMENDATION_REMOVE_FROM_MY_LIST_FAILURE:
      return {...state,
        selectedItem: (state?.selectedItem?.id === action.param.id)
          ? {...state.selectedItem, loaded: true} : state.selectedItem,
        dialogEpg: (state?.dialogEpg?.id === action.param.id)
          ? {...state.dialogEpg, loaded: true} : state.dialogEpg,
      }

    case types.RECORDING_ADD_REQUEST:
      return {...state,
        selectedItem: (state?.selectedItem?.id === action.param.eventItem.id)
         ? {...state.selectedItem, loaded: false } : state.selectedItem,
        dialogEpg: (state?.dialogEpg?.id === action.param.eventItem.id)
         ? {...state.dialogEpg, loaded: false } : state.dialogEpg,
      }
    case types.RECORDING_ADD_SUCCESS:
      return {...state,
        selectedItem: (state?.selectedItem?.id === action.param.eventItem.id)
          ? {...state.selectedItem, loaded: true, isRecordOn: true } : state.selectedItem,
        dialogEpg: (state?.dialogEpg?.id === action.param.eventItem.id)
          ? {...state.dialogEpg, isRecordOn: true, loaded: true } : state.dialogEpg,
      }
    case types.RECORDING_ADD_FAILURE:
      return {...state,
        selectedItem: (state?.selectedItem?.id === action.param.eventItem.id)
          ? {...state.selectedItem, loaded: true } : state.selectedItem,
        dialogEpg: (state?.dialogEpg?.id === action.param.eventItem.id)
          ? {...state.dialogEpg, loaded: true } : state.dialogEpg,
      }

    case types.RECORDING_REMOVE_REQUEST:
      return {...state,
        selectedItem: (state?.selectedItem?.id === action.param.eventItem.id)
          ? {...state.selectedItem, loaded: false } : state.selectedItem,
        dialogEpg: (state?.dialogEpg?.id === action.param.eventItem.id)
          ? {...state.dialogEpg, loaded: false } : state.dialogEpg,
      }
    case types.RECORDING_REMOVE_SUCCESS:
      return {...state,
        selectedItem: (state?.selectedItem?.id === action.param.eventItem.id)
          ? {...state.selectedItem, loaded: true, isRecordOn: false } : state.selectedItem,
        dialogEpg: (state?.dialogEpg?.id === action.param.eventItem.id)
          ? {...state.dialogEpg, loaded: true, isRecordOn: false } : state.dialogEpg,
      }
    case types.RECORDING_REMOVE_FAILURE:
      return {...state,
        selectedItem: (state?.selectedItem?.id === action.param.eventItem.id)
          ? {...state.selectedItem, loaded: true } : state.selectedItem,
        dialogEpg: (state?.dialogEpg?.id === action.param.eventItem.id)
          ? {...state.dialogEpg, loaded: true } : state.dialogEpg,
      }


    case types.EPG_GET_EXTENDED_ITEM_REQUEST:
      return {...state,
        selectedItem: {...state.selectedItem, loaded: false}
      }
    case types.EPG_GET_EXTENDED_ITEM_SUCCESS:
      return {...state,
        selectedItem: {...action.param.extendedEpgItem, loaded: true}
      }
    case types.EPG_GET_EXTENDED_ITEM_FAILURE:
      return {...state,
        selectedItem: {...state.selectedItem, loaded: true}
      }

    case types.EPG_RECHECK_IF_ALL_EVENT_HAS_GOOD_STATUS:
      return {...state,
        actualUsedItems: action.actualUsedItems,
      }

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;


    case types.EPG_RESET_STATE:
      return defaultState;
    case types.RESET_STATE:
      return defaultState;
    default:
      return state;
  }
}

export default epg;
