import { isSafeBoxItemType } from './isSafeBoxItemType';

export const filterOnlySafeBoxItemInArr = (arrWithBoxItems) => {
  if(!arrWithBoxItems || !Array.isArray(arrWithBoxItems)) {
    return undefined;
  }


  return arrWithBoxItems.reduce(
    (res, boxItem) => isSafeBoxItemType(boxItem.type)
      ? [...res, boxItem]
      : [...res], []);
};
