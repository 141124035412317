import { transformRecommendationRowResponseForReduce } from './transformRecommendationRowResponseForReduce';

export const transformRecommendationResponseForReduce = ({
  includeData = false,
  myListItems = [],
  prevRecommendationRows = [],
  recommendationResponse = [],
  recordingItems = [],
  selectedEpgItemInState,
} = {}) => {
  return [{
    rows: recommendationResponse.map(rowItem => ({ // obnovíme si kus z předchozí generace
      ...transformRecommendationRowResponseForReduce({
        includeData,
        myListItems,
        prevRecommendationRowGen: prevRecommendationRows.find(cacheRowItem => cacheRowItem.id === rowItem.id),
        recommendationRowResponse: rowItem,
        recordingItems,
        selectedEpgItemInState,
      }),
    })),
  }]
  .map(recommendationStateItem => ({...recommendationStateItem,
    rowsLoaded: recommendationStateItem.rows.every(rowItem => rowItem.loaded),
  }))[0];
};
