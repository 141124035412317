import { actionTypes as types } from '../constants';
import { post } from '../helpers';
import { getClientConfig } from '../helpers/portal/getClientConfig';

import {
  fetch_profile_addFavoriteChannel,
  fetch_profile_eventAudioChange,
  fetch_profile_eventSubtitleChange,
  fetch_profile_removeFavoriteChannel,
  fetch_profile_update,
} from '../fetchs';

export const profile_updateChannelOrder = (param) => dispatch => {
  dispatch({
    type: types.PROFILE_UPDATE_CHANNEL_ORDER_REQUEST,
    param: param,
  });
  post({
    url: `${getClientConfig().mwUrl}/public/profile/updateChannelOrder`,
    body: { ...param },
    success: types.PROFILE_UPDATE_CHANNEL_ORDER_SUCCESS,
    failure: types.PROFILE_UPDATE_CHANNEL_ORDER_FAILURE,
    dispatch,
  });
}

export const profile_resetChannelOrder = (param) => dispatch => {
  dispatch({
    type: types.PROFILE_RESET_CHANNEL_ORDER_REQUEST,
    param: param,
  });
  post({
    url: `${getClientConfig().mwUrl}/public/profile/resetChannelOrder`,
    body: { ...param },
    success: types.PROFILE_RESET_CHANNEL_ORDER_SUCCESS,
    failure: types.PROFILE_RESET_CHANNEL_ORDER_FAILURE,
    dispatch,
  }).then(() => {
    dispatch({
      type: types.CHANNELS_SUBSCRIBED_REQUEST,
      param: param,
    });
    post({
      url: `${getClientConfig().mwUrl}/public/channel/getSubscribedChannels`,
      body: { ...param },
      success: types.CHANNELS_SUBSCRIBED_SUCCESS,
      failure: types.CHANNELS_SUBSCRIBED_FAILURE,
      dispatch,
    });
  });
}

export const profile_update = (param) => dispatch => {
  fetch_profile_update(dispatch, param);
}

export const profile_deleteProfile = (param) => dispatch => {
  dispatch({
    type: types.PROFILE_DELETE_PROFILE_REQUEST,
    param: param,
  });
  post({
    url: `${getClientConfig().mwUrl}/public/profile/deleteProfile`,
    body: { ...param },
    success: types.PROFILE_DELETE_PROFILE_SUCCESS,
    failure: types.PROFILE_DELETE_PROFILE_FAILURE,
    dispatch,
  }).then(() => {
    // TODO: přepsat na jen zavolání profile_getProfiles();
    dispatch({
      type: types.PROFILE_GET_PROFILES_REQUEST,
      param: param,
    });
    post({
      url: `${getClientConfig().mwUrl}/public/profile/getProfiles`,
      body: { ...param },
      success: types.PROFILE_GET_PROFILES_SUCCESS,
      failure: types.PROFILE_GET_PROFILES_FAILURE,
      dispatch,
    });
  });
}

export const profile_getProfiles = (param) => dispatch => {
  dispatch({
    type: types.PROFILE_GET_PROFILES_REQUEST,
    param: param,
  });
  post({
    url: `${getClientConfig().mwUrl}/public/profile/getProfiles`,
    body: { ...param },
    success: types.PROFILE_GET_PROFILES_SUCCESS,
    failure: types.PROFILE_GET_PROFILES_FAILURE,
    dispatch,
  });
}

export const profile_getChannelWhitelisting = (param) => dispatch => {
  // TODO: naučit se jak dělat sériové volání bez copy&paste
  dispatch({
    type: types.CHANNELS_SUBSCRIBED_REQUEST,
    param: param,
  });
  post({
    url: `${getClientConfig().mwUrl}/public/channel/getSubscribedChannels`,
    body: {
      whitelisting: false,
      allDevices: true,
      mcastOnly: true,
      bcastOnlye: true,
    },
    success: types.CHANNELS_SUBSCRIBED_SUCCESS,
    failure: types.CHANNELS_SUBSCRIBED_FAILURE,
    dispatch,
  }).then(() => {
    dispatch({
      type: types.PROFILE_GET_CHANNEL_WHITELISTING_REQUEST,
      param: param,
    });
    post({
      url: `${getClientConfig().mwUrl}/public/profile/getChannelWhitelisting`,
      body: { ...param },
      success: types.PROFILE_GET_CHANNEL_WHITELISTING_SUCCESS,
      failure: types.PROFILE_GET_CHANNEL_WHITELISTING_FAILURE,
      dispatch,
    });
  });
}

export const profile_updateChannelWhitelisting = (param) => dispatch => {
  dispatch({
    type: types.PROFILE_UPDATE_CHANNEL_WHITELISTING_REQUEST,
    param: param,
  });
  post({
    url: `${getClientConfig().mwUrl}/public/profile/updateChannelWhitelisting`,
    body: { ...param },
    success: types.PROFILE_UPDATE_CHANNEL_WHITELISTING_SUCCESS,
    failure: types.PROFILE_UPDATE_CHANNEL_WHITELISTING_FAILURE,
    dispatch,
  });
}

export const profile_eventAudioChange = (variantItem) => (dispatch, getState) => {
  const { channel: {selectedItem: selectedChannelItem}} = getState();
  const { epg: {selectedItem: selectedEpgItem}} = getState();

  fetch_profile_eventAudioChange(dispatch, {
    selectedEpgItem,
    selectedChannelItem,
    variantItem,
  });
}

export const profile_eventSubtitleChange = (variantItem) => (dispatch, getState) => {
  const { channel: {selectedItem: selectedChannelItem}} = getState();
  const { epg: {selectedItem: selectedEpgItem}} = getState();
  fetch_profile_eventSubtitleChange(dispatch, {
    selectedEpgItem,
    selectedChannelItem,
    variantItem,
  });
}

export const profile_eventVideoQualityWasChange = (variantItem) => (dispatch) => {
  dispatch({
    type: types.PROFILE_EVENT_VIDEO_QUALITY_WAS_CHANGE,
    variantItem,
  });
}

export const profile_addFavoriteChannel = (param) => (dispatch) => {
  fetch_profile_addFavoriteChannel(dispatch, param);
}

export const profile_removeFavoriteChannel = (param) => (dispatch) => {
  fetch_profile_removeFavoriteChannel(dispatch, param);
}
