import { add, formatISO } from 'date-fns';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { render } from 'react-dom';

import { getClientConfig } from './helpers/portal/getClientConfig';
import { setClientStorage, getClientStorage } from './helpers/device/clientStorage';
import { store } from './store';
import App from './components/App';
import cicoLocaleData from "./client-extensions/cico/translate.json";
import generatedPortalSettings from '../portalSettings.json';
import localeData from "../translate.json";


export const history = createBrowserHistory();

if (location.hostname !== "localhost" && location.hostname !== "127.0.0.1") {
  (async () => {
    const Sentry = await import('@sentry/browser');
    const { Integrations }  = await import('@sentry/tracing');
    Sentry.init({
      dsn: "https://0b9754647b8a4b398b49c90889a02ca1@o221143.ingest.sentry.io/5396506",
      release: `portalMoTV@${generatedPortalSettings && generatedPortalSettings.appVersion}`,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.1, // Be sure to lower than 1 of this in production
    });

  })();
}

if(generatedPortalSettings?.gtmId) {
  (async () => {
    const TagManager = await import('react-gtm-module');

    TagManager.initialize({
      gtmId: generatedPortalSettings.gtmId,
      dataLayerName: 'PageDataLayer',
    });
  })();
}

// const dynamicLangs = getClientConfig().languages.reduce((res, langCode) => ([...res,
//     // ...require(`react-intl/locale-data/${langCode}`)
//   ]), []);
// addLocaleData(dynamicLangs);

const language =
  (getClientStorage("language"))
  || (getClientConfig().languages.length === 1 && getClientConfig().languages[0]) // when we has allow only one language we set it
  || (navigator.languages && navigator.languages[0])
  || navigator.language
  || navigator.userLanguage;


// Split locales with a region code
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

const allowLanguage =
  (getClientConfig().languages.find(langCode => langCode === languageWithoutRegionCode) !== undefined)
    ? languageWithoutRegionCode
    : getClientConfig().languages[0];

setClientStorage('language', allowLanguage, formatISO(add(new Date(), {years: 1})));

// Try full locale, try locale without region code, fallback to 'en'
const fallBackLang = allowLanguage || "en";
const messages = {

  // dočasné rozšíření o texty, než je dostanu z backendu, aby se mi to nepletlo v logu
  "player.control_fullscreen_on": "player.control_fullscreen_on",
  "player.control_stop": "player.control_stop",
  "player.control_fullscreen_off": "player.control_fullscreen_off",
  "message_unsupported_browser": "message_unsupported_browser",

  ...(localeData[allowLanguage]) ? localeData[allowLanguage] : localeData[fallBackLang],
  ...(getClientConfig().clientName === "cico") ? cicoLocaleData[allowLanguage] : {},
}

export const Root = () => ( // mongolština není oficiální jazyk a tak neexistuje : (
  <IntlProvider locale={language !== "mn" ? language : "en"} messages={messages}>
    <Provider store={store}>
      <BrowserRouter {...{history}}>
        <App />
      </BrowserRouter>
    </Provider>
  </IntlProvider>
)

render(<Root />, document.querySelector('react'));
