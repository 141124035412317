import { actionTypes as types } from '../constants';

const defaultState = {
  loaded: true,
};

const vendor = (
  state = defaultState,
  action,
) => {
  switch (action.type) {

    case types.VENDOR_GET_DATA_REQUEST:
      return {...state, loaded: false };
    case types.VENDOR_GET_DATA_SUCCESS:
      return {...state,
        ...action.data.response,
        loaded: true,
      };
    case types.VENDOR_GET_DATA_FAILURE:
      return {...state, loaded: true};

    case types.VENDOR_GET_LOCKED_CHANNEL_TEXT_REQUEST:
      return {...state, loaded: false };
    case types.VENDOR_GET_LOCKED_CHANNEL_TEXT_SUCCESS:
      return {...state,
        ...action.data.response,
        loaded: true,
      };
    case types.VENDOR_GET_LOCKED_CHANNEL_TEXT_FAILURE:
      return {...state, loaded: true};

    case types.VENDOR_GET_LOCKED_VOD_TEXT_REQUEST:
      return {...state, loaded: false };
    case types.VENDOR_GET_LOCKED_VOD_TEXT_SUCCESS:
      return {...state,
        ...action.data.response,
        loaded: true,
      };
    case types.VENDOR_GET_LOCKED_VOD_TEXT_FAILURE:
      return {...state, loaded: true};

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;

    case types.RESET_STATE:
      return defaultState;
    default:
      return state;
  }
};

export default vendor;
