/**
 * Merge two array with json object
 * thx: https://gist.github.com/yesvods/51af798dd1e7058625f4#gistcomment-2365119
 * @param  {[type]} p    [description]
 * @param  {[type]} arrs [description]
 * @return {Array}      new merge array
 * @use concatAndDeDuplicateObjects('id', arr1, arr2);
 */
export const concatAndDeDuplicateObjects = (p, ...arrs) => [].concat(...arrs)
  .filter(item => item && item[p])
  .reduce(
    (a, b) => !a.filter(c => b[p] === c[p]).length ? [...a, b] : a, []
  );
