import { isEqualArray } from '../helpers/array/isEqualArray';
import { useEffect, useRef, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';

import {
  customer_logout,
  customer_loginWithUrlParams,
} from '../actions';

export const useUrlRedirectFilter = (callback, ...watchedVariables) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const savedCallback = useRef();
  const lastWatchedVariables = useRef([]);

  // small piece for login with token throw url params
  const originalParams = queryString.parse(location.search); // nyní se již nepoužívá?
  const { appToken, profileId, profilePin } = originalParams;

  const customers_password_changed = useSelector(state => state.user.customers_password_changed);
  const customers_token = useSelector(state => state.user.customers_token);
  const motv_portals_custom_lost_password_open = useSelector(state => state.portal.motv_portals_custom_lost_password_open);
  const motv_portals_custom_registration_open = useSelector(state => state.portal.motv_portals_custom_registration_open);
  const needRelogin = useSelector(state => state.user.needRelogin);
  const reloginLogin = useSelector(state => state.user.reloginLogin);
  const reloginPassword = useSelector(state => state.user.reloginPassword);
  const selectedProfile = useSelector(state => state.user.selectedProfile);
  const userIsLoaded = useSelector(state => state.user.loaded);
  const motv_portals_sections = useSelector(state => state.portal.motv_portals_sections);
  const motv_portals_account_update_enabled = useSelector(state => state.portal.motv_portals_account_update_enabled);
  const vendors_login_force_change_password = useSelector(state => state.vendor.vendors_login_force_change_password);

  const extendedWatchedVariables = [...watchedVariables,
    motv_portals_custom_lost_password_open,
    motv_portals_custom_registration_open
  ];

  const loggedUserMustChangePassword = useMemo(() => !!(
    customers_token // jsme zalogováni
    && vendors_login_force_change_password // je vyžadována prvotní změna hesla
    && customers_password_changed === 0 // ale user si ji ještě nikdy nezměnil
  ), [vendors_login_force_change_password, customers_token, customers_password_changed]);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if(isEqualArray(lastWatchedVariables.current, extendedWatchedVariables)) return; // menší ochrana proti zacyklení. Proč vznikalo, netuším, něco s aktualizací history.push

    lastWatchedVariables.current = extendedWatchedVariables;
    if (appToken) goToLoginWithToken();
    else if ( // nemá klient vlastní registrace?
      motv_portals_custom_registration_open
      && (
        location.pathname === "/customer/create"
        || location.pathname.startsWith("/login/change-password-with-pin")
        || location.pathname.startsWith("/login/change-password")
        || location.pathname === "/login/lost-password"
      )
    ) { window.location = motv_portals_custom_registration_open; }
    else if ( // nemá klient vlastní registrace?
      motv_portals_custom_lost_password_open
      && (location.pathname === "/login/lost-password")
    ) { window.location = motv_portals_custom_lost_password_open; }
    else if (!customers_token && !location.pathname.startsWith("/customer") && !location.pathname.startsWith("/login/") && !location.pathname.startsWith("/info/")) history.push("/login");
    else if (customers_token && !selectedProfile && !location.pathname.startsWith("/select-profile")) history.push("/select-profile");
    else if (customers_token && selectedProfile && location.pathname.startsWith("/select-profile")) history.push("/");
    else if (loggedUserMustChangePassword) history.push("/customer/force-change-password");
    else {/* */}

    savedCallback.current();
  }, [...extendedWatchedVariables]);


  useEffect(() => {
    if (needRelogin || reloginLogin || reloginPassword) dispatch(customer_logout());
  }, [needRelogin, reloginLogin, reloginPassword]);

  useEffect(() => {
    if(location.pathname !== "/" || !motv_portals_account_update_enabled || !motv_portals_sections) return;
    const hpUrl = getHpUrl(motv_portals_sections, motv_portals_account_update_enabled);
    if(hpUrl !== "/") history.push(hpUrl);
  }, [location.pathname, motv_portals_sections, motv_portals_account_update_enabled]);

  const goToLoginWithToken = () => {
    if(!userIsLoaded) return;
    dispatch(customer_loginWithUrlParams({
      appToken,
      profileId: parseInt(profileId, 10),
      profilePin,
      urlAfterLogin: location.pathname,
    }));
  };

};

export const getHpUrl = (motv_portals_sections, motv_portals_account_update_enabled) =>
    (motv_portals_sections && motv_portals_sections.homepage) ? "/"
  : (motv_portals_sections && motv_portals_sections.live) ? "/tv/player"
  : (motv_portals_sections && motv_portals_sections.radio) ? "/radio/player"
  : (motv_portals_sections && motv_portals_sections.vod) ? "/vod"
  : (motv_portals_sections && motv_portals_sections.recordings) ? "/recordings"
  : (motv_portals_account_update_enabled) ? "/settings"
  : undefined;
  // : "/no-have-rights-to-content" // user has no right to nothing
