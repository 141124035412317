import { actionTypes as types } from '../constants';
import { concatAndDeDuplicateObjects } from '../helpers/array/concatAndDeDuplicateObjects';

const defaultState = {
  fletDesingItems: [],
  rollingDesignItems: [],
  fletDesingItemsWithFilters: [],
  hourColumns: [],
  loaded: true,
  wasFirstInit: false,
};

const tvGuide = (
  state = defaultState,
  action,
) => {
  switch (action.type) {


    case types.TV_GUIDE_FLET_REQUEST:
      return {...state, loaded: false};
    case types.TV_GUIDE_FLET_SUCCESS:
      return {...state,
        dateCursor: action.dateCursor,
        fletDesingItems: action.fletDesingItems,
        fletDesingItemsWithFilters: action.fletDesingItemsWithFilters,
        hourColumns: action.hourColumns,
        loaded: true,
        wasFirstInit: true,
    };
    case types.TV_GUIDE_FLET_FAILURE:
      return defaultState;

    case types.TV_GUIDE_ROLLING_REQUEST:
      return {...state, loaded: false};
    case types.TV_GUIDE_ROLLING_SUCCESS:
      return {...state,
        rollingDesignItems: action.rollingDesignItems,
        channelsCount: action.channelsCount,
        loaded: true,
        wasFirstInit: true,
    };
    case types.TV_GUIDE_ROLLING_FAILURE:
      return defaultState;


    case types.CHANNEL_CATEGORY__SET_CHANNEL_CATEGORY:
      return {...state,
        fletDesingItemsWithFilters: action.fletDesingItemsWithFilters,
      };

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;

    default:
      return state;
  }
};

export default tvGuide;
