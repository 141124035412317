import { actionTypes as types } from '../constants';
import { addMinutes, formatISO, isAfter } from 'date-fns';
import { isEqualJSON } from '../helpers/json/isEqualJSON';
import { v4 as uuidv4 } from "uuid";


const defaultState = {
  items: [],
  newtworkBlockerError: false,
  liveItemsStream: [],
  liveItemsStreamIsAllLive: true,
  // newtworkBlockerError: true,
};

const network = (state = defaultState, action) => {
  const actionTypeName = action.type;

  if (actionTypeName.endsWith("_REQUEST")) {
    return {...state,
      ...action.url && isAllowedUrl(action.url) ? {
        liveItemsStream: [...state.liveItemsStream,
          {
            url: action.url,
            param: action?.param,
            id: uuidv4(),
            startDate: formatISO(new Date()),
            status: "request",
            options: action?.options,
          }]
      } : {},
    };
  } else if (actionTypeName.endsWith("_FAILURE")) {
    return [{...getStateForFinishRequest(actionTypeName.replace("_FAILURE", ""), (action.param) ? action.param : {}, state),
      liveItemsStream: setFailureMark({liveItemsStream: state.liveItemsStream, action}),
    }].map(stateItem => ({...stateItem,
      liveItemsStreamIsAllLive: isAllLiveItemsAlive(stateItem.liveItemsStream),
    }))[0];
  } else if (actionTypeName.endsWith("_SUCCESS")) {
    return [{...getStateForFinishRequest(actionTypeName.replace("_SUCCESS", ""), (action.param) ? action.param : {}, state),
      liveItemsStream: setSuccessMark({liveItemsStream: state.liveItemsStream, action})
        .filter(streamItem => streamItem.status !== "success"),
    }].map(stateItem => ({...stateItem,
      liveItemsStreamIsAllLive: isAllLiveItemsAlive(stateItem.liveItemsStream),
    }))[0];
  }

  switch (action.type) {

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;

    case types.RESET_STATE:
      return defaultState;

    default:
      return state;
  }
};

const isAllowedUrl = (url) => (!url.endsWith("motvSpeedCheck")) ? true : false;


const isAllLiveItemsAlive = (liveItemsStream) =>
  !liveItemsStream.some(networkItem => networkItem.status === "failure");

const setSuccessMark = ({liveItemsStream, action}) => setResponseMark({liveItemsStream, action, statusType: "success"});
const setFailureMark = ({liveItemsStream, action}) => setResponseMark({liveItemsStream, action, statusType: "failure"});

const setResponseMark = ({liveItemsStream, action, statusType}) => {
  const requestItem = liveItemsStream.find(networkItem =>
    networkItem.status === "request"
    && networkItem.url === action.url
  );

  if(!(!!requestItem) && action.url && isAllowedUrl(action.url)) {return [...liveItemsStream, { // nenašli jsme request --> něco je zle, nastavíme chybu
    url: action.url,
    param: action?.param,
    id: uuidv4(),
    startDate: formatISO(new Date()),
    endDate: formatISO(new Date()),
    status: "success",
  }]} else if (!(!!requestItem)) {
    return liveItemsStream;
  }

  return liveItemsStream.map(networkItem => ({...networkItem,
    ...requestItem.id === networkItem.id
      ? {status: statusType} : {},
  }));
};

/**
 * Zkontrolujeme zda nebylo za poslední dobu zavoláno mockrát
 */
const getStateForFinishRequest = (actionTypeName, param, state) => {
  return {...state,
    newtworkBlockerError: (state.newtworkBlockerError)
      ? true // již je error, necháme ho nastavený
      : (state.items.filter(requestItem => (
        requestItem.type === actionTypeName
        && isEqualJSON(requestItem.param, param)
      )).length > 100)
  };
}

const removeAllOldRequest = (items) => {
  const now = new Date();
  return items.filter(requestItem => isAfter(addMinutes(new Date(requestItem.date), 1), now));
}

export default network;
