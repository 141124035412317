import { useDispatch, useSelector } from "react-redux";
import { useParams} from "react-router";
import { useState, useEffect } from 'react';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { mergeDeepJson } from '../helpers/json/mergeDeepJson';


/**
 * Variable for Material-ui
 * https://material-ui.com/customization/default-theme/
 */
const baseThemeConf = {
  palette: {
    primary: { main: '#adf4bb' },
    secondary: { main: '#fff' }, // odkazy, loader
    text: {
      primary: '#fff', // default text color
      secondary: '#fff', // default text color
    },
    type: 'dark', //typ tématu
    common: { black: '#ffffff' },
    // background: { paper: 'blue' },
  },
  typography: {
    fontSize: 14,
    body1: { color: "#fff" },
    h1: { fontSize: 22, fontWeight: 'bold', color: "#fff" },
    h2: { fontSize: 20, fontWeight: 'bold', color: "#fff" },
    h3: { fontSize: 18, fontWeight: 'bold', color: "#fff" },
    h4: { fontSize: 16, color: "#fff" },
    h5: { fontSize: 14, color: "#fff" },
    small: { fontSize: 12, color: "#fff" },
  },
}


function PortalThemeProvider({children}) {
  const { portal: { motv_portals_portal_json_theme } } = useSelector(state => state);

  const getTheme = () => createTheme(mergeDeepJson(baseThemeConf,
    (motv_portals_portal_json_theme && motv_portals_portal_json_theme.material)
      ? motv_portals_portal_json_theme.material : {}
  ));

  const [theme, setTheme] = useState(getTheme());


  useEffect(() => {
    setTheme(getTheme());
  }, [motv_portals_portal_json_theme]); // FIXME: tohle se zavolá jen jednou



  return (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  )
}

export default PortalThemeProvider;
