import { getClientConfig } from '../helpers/portal/getClientConfig';
import { get, post } from '../helpers';
import { actionTypes as types, urls } from '../constants';



export const fetch_edges_getList = (dispatch, param) => post({
    url: `${getClientConfig().mwUrl}/public/edge/getEdges`,
    success: types.EDGE_GET_LIST_SUCCESS,
    failure: types.EDGE_GET_LIST_FAILURE,
    request: types.EDGE_GET_LIST_REQUEST,
    body: param,
    noAuth: true,
    dispatch,
});

export const fetch_edges_itemSpeedTest = (dispatch, param) => get({
  url: param.edgeItem.edges_speed_check_url,
  success: types.EDGE_ITEM_SPEED_TEST_SUCCESS,
  failure: types.EDGE_ITEM_SPEED_TEST_FAILURE,
  request: types.EDGE_ITEM_SPEED_TEST_REQUEST,
  dispatch,
  param,
  timeout: 5000, // 5 sekund for response
});
