import { getClientConfig } from '../helpers/portal/getClientConfig';
import { post } from '../helpers';
import { actionTypes as types } from '../constants';

export const fetch_epg_loadEventForPlayerV2 = (dispatch, param, timestampOFUserVideoPosition) => post({
  url: `${getClientConfig().mwUrl}/public/epg/getUpdatedEventsV2`,
  body: { ...param },
  request: types.PLAYER_FULLPLAYER_GET_EVENT_REQUEST,
  success: types.PLAYER_FULLPLAYER_GET_EVENT_SUCCESS,
  failure: types.PLAYER_FULLPLAYER_GET_EVENT_FAILURE,
  dispatch,
  param: {...param,
    timestamp: timestampOFUserVideoPosition,
  },
});

export const fetch_epg_getUpdatedEventsV2 = (param) => (dispatch) => post({
    url: `${getClientConfig().mwUrl}/public/epg/getUpdatedEventsV2`,
    body: { ...param },
    request: types.EPG_UPDATED_EVENTS_REQUEST,
    success: types.EPG_UPDATED_EVENTS_SUCCESS,
    failure: types.EPG_UPDATED_EVENTS_FAILURE,
    dispatch,
});

export const fetch_epg_getUpdatedEventsV2WithoutRedux = (param) => (dispatch) => post({
  url: `${getClientConfig().mwUrl}/public/epg/getUpdatedEventsV2`,
  body: { ...param },
  request: types.EPG_UPDATED_EVENTS_REQUEST_WO_UPDATING_REDUX,
  success: types.EPG_UPDATED_EVENTS_SUCCESS_WO_UPDATING_REDUX,
  failure: types.EPG_UPDATED_EVENTS_FAILURE_WO_UPDATING_REDUX,
  dispatch,
});

