import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import UniversalDetailDialogPlayButton from "./UniversalDetailDialogPlayButton";
import {makeStyles} from "@material-ui/core/styles";
import UniversalDetailDialogMyListButton from "./UniversalDetailDialogMyListButton";
import {BOX_ITEM_CATEGORY_TYPE, BOX_ITEM_VOD_TYPE} from "../../../../constants/boxItemTypes";
import UniversalDetailDialogCategoryButton from "./UniversalDetailDialogCategoryButton";
import UniversalDetailDialogRecordButton from "./UniversalDetailDialogRecordButton";

const useStyles = makeStyles(() => ({
  nonPlayButton: {
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    color: "black",
    padding: "6px 16px 6px 10px ",
    margin: "0 0 0 10px",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.8)",
    },
    "& img": {
      filter: "invert(100%)",
    }
  },
}));

const UniversalDetailDialogActionButtons = ({detailUniversalDialog, handleCloseUniversalDetailDialog}) => {
  const [showPlayButton, setShowPlayButton] = useState(false)
  const styles = useStyles()

  useEffect(() => {
    if (detailUniversalDialog?.playable !== false && detailUniversalDialog.type !== BOX_ITEM_CATEGORY_TYPE && !detailUniversalDialog.isStartingInFuture) setShowPlayButton(true)
  }, [detailUniversalDialog])

  return (
    <>
      {detailUniversalDialog.categories_name &&
        detailUniversalDialog.categories_id && (
          <UniversalDetailDialogCategoryButton
            {...{
              detailUniversalDialog,
              handleCloseUniversalDetailDialog,
              styles,
            }}
          />
        )}
      {detailUniversalDialog.type !== BOX_ITEM_VOD_TYPE && detailUniversalDialog.type !== BOX_ITEM_CATEGORY_TYPE &&
        <UniversalDetailDialogRecordButton
          {...{ detailUniversalDialog, styles }}
        />
      }
      <UniversalDetailDialogMyListButton
        {...{ detailUniversalDialog, styles }}
      />
      {showPlayButton &&
        detailUniversalDialog.type !== BOX_ITEM_CATEGORY_TYPE && (
          <UniversalDetailDialogPlayButton
            {...{ detailUniversalDialog, handleCloseUniversalDetailDialog }}
          />
        )}
    </>
  );
};

UniversalDetailDialogActionButtons.propTypes = {
  detailUniversalDialog: PropTypes.object.isRequired,
  handleCloseUniversalDetailDialog: PropTypes.func.isRequired,
};

export default UniversalDetailDialogActionButtons;
