import { isAfter } from "date-fns";

import { actionTypes as types } from "../constants";
import { concatAndDeDuplicateObjects } from "../helpers/array/concatAndDeDuplicateObjects";
import { getChannelLiveUrl } from "../helpers/channel/getChannelLiveUrl";
import { getChannelItemsWithApplyAllFilter } from "../actions";
import { isSameOrBefore } from "../helpers/datetime";

const defaultState = {
  channelsCount: 0,
  dateCursor: new Date(),
  dateCursorMax: new Date(),
  dateCursorMin: new Date(),
  items: [],
  rollingDesignItems: [],
  loaded: true,
  selectedItemId: undefined, // nepoužívat, bude odstraněno
  selectedItem: undefined,
  timestampForGettingStream: null, // in seconds
  hasStartupChannel: true,
  categories: [],
  selectedCategory: null,
  remapChannels: false,
  actualUsedItems: [],
  searchText: "",
  channelItemsInSettingsView: [],
};

const channel = (
  state = defaultState,
  action,
) => {
  switch (action.type) {
    case types.CHANNELS_STREAM_URL_REQUEST:
      return {...state,
        loaded: false,
      };
    case types.CHANNELS_STREAM_URL_SUCCESS:
      return {...state,
        loaded: true,
      };
    case types.CHANNELS_STREAM_URL_FAILURE:
      return {...state,
        loaded: true,
      };

    case types.CHANNELS_SUBSCRIBED_REQUEST:
      return {...state,
        loaded: false,
        selectedItemId:
          (action.param && action.param.selectedItemId)
          ? action.param.selectedItemId
          : state.selectedItemId,
      };
    case types.CHANNELS_SUBSCRIBED_SUCCESS:
      return [{...state,
        items: Array.isArray(action.data.response) ? action.data.response : state.items,
        loaded: true,
        selectedItemId: (state?.selectedItemId && action?.data?.response[0])
          ? state.selectedItemId : action?.data?.response[0]?.channels_id,
        remapChannels: true,
      }]
      .map(stateItem => ({...stateItem, // obohatíme si to epg informace
        items: (action?.state?.epg)
          ? stateItem.items.map(channelItem => ({...channelItem,
              playerUrl: getChannelLiveUrl(channelItem, action.param.type),
              actualEpg: action.state.epg.items.find(epgItem =>
                epgItem.channels_id === channelItem.channels_id
                && isSameOrBefore(new Date(epgItem.start), new Date())
                && isAfter(new Date(epgItem.end), new Date())
              ),
              actualEpgIsLoaded: true,
            }))
          : stateItem.items,
      }))
      .map(stateItem => ({...stateItem, // dáme si informace k těm co si mají donačíst informace
        items: stateItem.items.map(channelItem => ({...channelItem,
          // actualEpgIsLoaded: (channelItem.actualEpg === undefined) ? false : true,
        }))
      }))
      .map(stateItem => ({...stateItem,
        actualUsedItems: stateItem.items || state.actualUsedItems, // TODO: dodělat filtry o vyhledávání a kategorie
      }))
      [0];
    case types.CHANNELS_SUBSCRIBED_FAILURE:
      return {...state,
        loaded: true,
      }

    case types.CHANNELS_GET_STARTUP_CHANNEL_REQUEST:
      return {...state,
        loaded: false,
        selectedItemId: null,
      }
    case types.CHANNELS_GET_STARTUP_CHANNEL_SUCCESS:
      return {...state,
        loaded: true,
        selectedItemId: (typeof action.data.response === "object")
          ? action.data.response.channels_id
          : null,
        hasStartupChannel: (typeof action.data.response === "object"),
      }
    case types.CHANNELS_DEFAULT_CHANNEL_REQUEST:
      return {...state,
        loaded: false,
      }
    case types.CHANNELS_DEFAULT_CHANNEL_SUCCESS:
      return [{...state,
        loaded: true,
        items: Array.isArray(action.data.response) ? action.data.response : state.items,
        selectedItemId: (Array.isArray(action.data.response) && action.data.response[0])
          ? action.data.response[0].channels_id
          : null,
      }].map(stateItem => ({...stateItem,
        actualUsedItems: stateItem.items || state.actualUsedItems, // TODO: dodělat filtry a obohacení o epg
      }))[0]

    case types.CHANNELS_SET_PARAM:
      return {...state, ...action.param}

    case types.PROFILE_UPDATE_CHANNEL_ORDER_REQUEST:
      return {...state,
        loaded: false,
        items: state.items.map(channelItem => {
          return {...channelItem,
            channels_order: action.param.channels[channelItem.channels_id]
          }
        })
      }
    case types.PROFILE_UPDATE_CHANNEL_ORDER_SUCCESS:
      return {...state,
        loaded: true,
        remapChannels: true,
      }

    case types.CHANNEL_DATE_CURSORS:
      return {
        ...state,
        ...action.params,
      }

    case types.CHANNEL_CATEGORY__GET_CHANNEL_CATEGORIES_SUCCESS:
      return {...state,
        categories: action.data.response,
      }

    case types.CHANNEL_CATEGORY__SET_CHANNEL_CATEGORY:
      return {...state,
        selectedCategory: action.selectedCategory,
        actualUsedItems: action.actualUsedItems || state.actualUsedItems,
      }



    case types.CHANNEL_INIT_CHANNEL_LIST_WITH_ACTUAL_EPG_SUCCESS:
      return {...state,
        items: action.channelListPlusEpg,
        actualUsedItems: getChannelItemsWithApplyAllFilter({
          channelItems: action.channelListPlusEpg,
          searchText: state.searchText,
          selectedCategory: state.selectedCategory,
        }) || state.actualUsedItems,
        ...(action.selectedItem) ? {selectedItem: action.selectedItem} : {},
      };


    case types.CHANNEL_UPDATE_EPG_IN_ACTUAL_USED_CHANNEL_LIST:
      return {...state,
        items: concatAndDeDuplicateObjects("channels_id", action.channelItems, state.items),
        actualUsedItems: getChannelItemsWithApplyAllFilter({
          channelItems: action.channelItems,
          searchText: state.searchText,
          selectedCategory: state.selectedCategory,
        }) || state.actualUsedItems,
      };

    case types.EPG_LOAD_EPG_INFO_FOR_CHANNEL_ITEM_REQUEST:
      return {...state,
        actualUsedItems: state.actualUsedItems.map(channelItem => ({...channelItem,
          ...(action.param.channels[0] === channelItem) ? {actualEpgIsLoaded: false} : {},
        })) || state.actualUsedItems
      }
    case types.EPG_LOAD_EPG_INFO_FOR_CHANNEL_ITEM_SUCCESS:
      return {...state,
        // actualUsedItems: state.actualUsedItems.map(channelItem => ({...channelItem,
        //   ...(action.param.channels[0] === channelItem) ? {actualEpgIsLoaded: false} : {},
        // }))
      }
    case types.EPG_LOAD_EPG_INFO_FOR_CHANNEL_ITEM_FAILURE:
      return {...state,
        // actualUsedItems: state.actualUsedItems.map(channelItem => ({...channelItem,
        //   ...(action.param.channels[0] === channelItem) ? {actualEpgIsLoaded: false} : {},
        // }))
      }

    case types.PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_REQUEST:
    return {...state,
      actualUsedItems: action.actualUsedChannelItems || state.actualUsedItems,
    }
    case types.PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_SUCCESS:
      return {...state,
        selectedItem: action.selectedChannelItem,
      }

    case types.PLAYER_FULLPLAYER_INIT_SUCCESS:
      return {...state,
        selectedItem: action.selectedChannelItem,
      };

    case types.PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_FAILURE:
      return {...state, loaded: true}

    case types.CHANNEL_UPDATE_SEARCH:
      return {...state,
        actualUsedItems: action.actualUsedItems || state.actualUsedItems,
        searchText: action.searchText,
      }

    case types.CHANNEL_SETTINGS_GET_VIEW_REQUEST:
      return {...state, loaded: false}
    case types.CHANNEL_SETTINGS_GET_VIEW_SUCCESS:
      return {...state, loaded: true,
        channelItemsInSettingsView: action.channelItems,
      }
    case types.CHANNEL_SETTINGS_GET_VIEW_FAILURE:
      return {...state, loaded: true}

    case types.CHANNEL_SETTINGS_ADD_CHANNEL_TO_FAVORITE_REQUEST:
      return {...state, loaded: false}
    case types.CHANNEL_SETTINGS_ADD_CHANNEL_TO_FAVORITE_SUCCESS:
      return {...state, loaded: true,
        channelItemsInSettingsView: state.channelItemsInSettingsView.map(channelItem => ({...channelItem,
          isFavorite: action.idsOfFavoriteChannelItems.some(id => id === channelItem.channels_id),
        })),
      }
    case types.CHANNEL_SETTINGS_REMOVE_CHANNEL_FROM_FAVORITE_FAILURE:
      return {...state, loaded: true}

    case types.CHANNEL_SETTINGS_REMOVE_CHANNEL_FROM_FAVORITE_REQUEST:
      return {...state, loaded: false}
    case types.CHANNEL_SETTINGS_REMOVE_CHANNEL_FROM_FAVORITE_SUCCESS:
      return {...state, loaded: true,
        channelItemsInSettingsView: state.channelItemsInSettingsView.map(channelItem => ({...channelItem,
          isFavorite: action.idsOfFavoriteChannelItems.some(id => id === channelItem.channels_id),
        })),
      }
    case types.CHANNEL_SETTINGS_ADD_CHANNEL_TO_FAVORITE_FAILURE:
      return {...state, loaded: true}

    case types.CHANNEL_PLAYER_ADD_CHANNEL_TO_FAVORITE_REQUEST:
      return {...state, loaded: false}
    case types.CHANNEL_PLAYER_ADD_CHANNEL_TO_FAVORITE_SUCCESS:
      return {...state, loaded: true,
        actualUsedItems: state.actualUsedItems.map(channelItem => ({...channelItem,
          isFavorite: action.idsOfFavoriteChannelItems.some(id => id === channelItem.channels_id),
        })),
        items: state.items.map(channelItem => ({...channelItem,
          isFavorite: action.idsOfFavoriteChannelItems.some(id => id === channelItem.channels_id),
        })),
      }
    case types.CHANNEL_PLAYER_ADD_CHANNEL_TO_FAVORITE_FAILURE:
      return {...state, loaded: true}

    case types.CHANNEL_PLAYER_REMOVE_CHANNEL_FROM_FAVORITE_REQUEST:
      return {...state, loaded: false}
    case types.CHANNEL_PLAYER_REMOVE_CHANNEL_FROM_FAVORITE_SUCCESS:
      return {...state, loaded: true,
        actualUsedItems: state.actualUsedItems.map(channelItem => ({...channelItem,
          isFavorite: action.idsOfFavoriteChannelItems.some(id => id === channelItem.channels_id),
        })),
        items: state.items.map(channelItem => ({...channelItem,
          isFavorite: action.idsOfFavoriteChannelItems.some(id => id === channelItem.channels_id),
        })),
      }
    case types.CHANNEL_PLAYER_REMOVE_CHANNEL_FROM_FAVORITE_FAILURE:
      return {...state, loaded: true}


    case types.CHANNEL_RESET:
      return defaultState;

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;

    case types.RESET_STATE:
      return defaultState;
    default:
      return state;

    case types.CHANNEL_ROLLING_REQUEST:
      return { ...state, loaded: false };
    case types.CHANNEL_ROLLING_SUCCESS:
      return {
        ...state,
        rollingDesignItems: action.rollingDesignItems,
        channelsCount: action.channelsCount,
        loaded: true,
      };
    case types.CHANNEL_ROLLING_FAILURE:
      return defaultState;
  }
};

export default channel;
