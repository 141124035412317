
export const RECORDING_GET_HP_REQUEST = 'RECORDING_GET_HP_REQUEST';
export const RECORDING_GET_HP_SUCCESS = 'RECORDING_GET_HP_SUCCESS';
export const RECORDING_GET_HP_FAILURE = 'RECORDING_GET_HP_FAILURE';

export const RECORDING_GET_STREAM_URL_REQUEST = 'RECORDING_GET_STREAM_URL_REQUEST';
export const RECORDING_GET_STREAM_URL_SUCCESS = 'RECORDING_GET_STREAM_URL_SUCCESS';
export const RECORDING_GET_STREAM_URL_FAILURE = 'RECORDING_GET_STREAM_URL_FAILURE';

export const RECORDING_ADD_REQUEST = 'RECORDING_ADD_REQUEST';
export const RECORDING_ADD_SUCCESS = 'RECORDING_ADD_SUCCESS';
export const RECORDING_ADD_FAILURE = 'RECORDING_ADD_FAILURE';

export const RECORDING_REMOVE_REQUEST = 'RECORDING_REMOVE_REQUEST';
export const RECORDING_REMOVE_SUCCESS = 'RECORDING_REMOVE_SUCCESS';
export const RECORDING_REMOVE_FAILURE = 'RECORDING_REMOVE_FAILURE';

export const RECORDING_GET_DATA_REQUEST = 'RECORDING_GET_DATA_REQUEST';
export const RECORDING_GET_DATA_SUCCESS = 'RECORDING_GET_DATA_SUCCESS';
export const RECORDING_GET_DATA_FAILURE = 'RECORDING_GET_DATA_FAILURE';

export const RECORDING_LOAD_PLAYER_REQUEST = 'RECORDING_LOAD_PLAYER_REQUEST';
export const RECORDING_LOAD_PLAYER_SUCCESS = 'RECORDING_LOAD_PLAYER_SUCCESS';
export const RECORDING_LOAD_PLAYER_FAILURE = 'RECORDING_LOAD_PLAYER_FAILURE';

export const RECORDING_DIALOG_WITH_BOX_ITEM_REQUEST = 'RECORDING_DIALOG_WITH_BOX_ITEM_REQUEST';
export const RECORDING_DIALOG_WITH_BOX_ITEM_SUCCESS = 'RECORDING_DIALOG_WITH_BOX_ITEM_SUCCESS';
export const RECORDING_DIALOG_WITH_BOX_ITEM_FAILURE = 'RECORDING_DIALOG_WITH_BOX_ITEM_FAILURE';

export const RECORDING_SET_PARAM = 'RECORDING_SET_PARAM';
