import { actionTypes as types } from "../constants";
import { fetchTypes } from "../constants";
import { getClientConfig } from "../helpers/portal/getClientConfig";
import { post } from "../helpers";

export const fetch_recording_getStreamUrlV3 = (dispatch, param) =>
  post({
    url: `${getClientConfig().mwUrl}/public/recording/getStreamUrlV3`,
    body: param,
    param,
    request: types.RECORDING_GET_STREAM_URL_REQUEST,
    success: types.RECORDING_GET_STREAM_URL_SUCCESS,
    failure: types.RECORDING_GET_STREAM_URL_FAILURE,
    // param: param,
    dispatch,
  });

export const fetch_recording_getDataV2 = (param) => (dispatch) =>
  post({
    url: `${getClientConfig().mwUrl}/public/recording/getDataV2`,
    body: param,
    param,
    request: types.RECORDING_GET_DATA_REQUEST,
    success: types.RECORDING_GET_DATA_SUCCESS,
    failure: types.RECORDING_GET_DATA_FAILURE,
    // param: param,
    dispatch,
  });

export const fetch_recording_getRecordingsByProfile = () => (dispatch) =>
  post({
    url: `${getClientConfig().mwUrl}/public/recording/getRecordingsByProfileV2`,
    request: fetchTypes.FETCH_RECORDING_BY_PROFILE_REQUEST,
    success: fetchTypes.FETCH_RECORDING_BY_PROFILE_SUCCESS,
    failure: fetchTypes.FETCH_RECORDING_BY_PROFILE_FAILURE,
    dispatch,
  });

export const fetch_recording_addRecording = (dispatch, eventItem) =>
  post({
    url: `${getClientConfig().mwUrl}/public/recording/addRecordingV2`,
    body: { epgEventsId: eventItem.id },
    request: types.RECORDING_ADD_REQUEST,
    success: types.RECORDING_ADD_SUCCESS,
    failure: types.RECORDING_ADD_FAILURE,
    dispatch,
    param: { eventItem },
  });

export const fetch_recording_removeRecording = (dispatch, eventItem) =>
  post({
    url: `${getClientConfig().mwUrl}/public/recording/removeRecording`,
    body: { epgEventsId: eventItem.id },
    request: types.RECORDING_REMOVE_REQUEST,
    success: types.RECORDING_REMOVE_SUCCESS,
    failure: types.RECORDING_REMOVE_FAILURE,
    dispatch,
    param: { eventItem },
  });

export const fetch_recording_getEventDetailV2 = (id) => (dispatch) =>
    post({
      url: `${getClientConfig().mwUrl}/public/epg/getUpdatedEventsV2`,
      body: {
        ids: [id],
        timestamp: 0,
      },
      request: fetchTypes.FETCH_RECORDING_GET_EVENT_REQUEST,
      success: fetchTypes.FETCH_RECORDING_GET_EVENT_SUCCESS,
      failure: fetchTypes.FETCH_RECORDING_GET_EVENT_FAILURE,
      dispatch,
    });

