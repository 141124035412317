import { getActualLang } from '../device/getActualLang';
import { getClientStorage } from '../device/clientStorage';
import { getUnixTime, isBefore, isAfter } from 'date-fns';
import { translateLangCodeToModuleName } from '../device/translateLangCodeToModuleName';

/**
 * Convert seconds to read varian
 * @param  {int} seconds
 * @return {string}
 */
export const convertToTimeCode = (value) => {
  value = Math.max(value, 0);
  const h = Math.floor(value / 3600);
  const m = Math.floor((value % 3600) / 60);
  const s = Math.floor((value % 3600) % 60);
  return (
    (h === 0)
    ? ""
    : (h < 10
      ? `0${h.toString()}:`
      : `${h.toString()}:`
      )
    )
    + (m < 10 ? `0${m.toString()}:` : `${m.toString()}:`)
    + (s < 10 ? `0${s.toString()}` : s.toString());
}


/**
 * Merge if date is same od start before
 * @param  {Date}  date          [description]
 * @param  {Date}  dateToCompare [description]
 * @return {Boolean}               [description]
 */
export const isSameOrBefore = (date, dateToCompare) =>
  isBefore(date, dateToCompare)
  || getUnixTime(date) === getUnixTime(new Date(dateToCompare));


/**
 * Merge if date is same od start after
 * @param  {Date}  date          [description]
 * @param  {Date}  dateToCompare [description]
 * @return {Boolean}               [description]
 */
export const isSameOrAfter = (date, dateToCompare) =>
  isAfter(date, dateToCompare)
  || getUnixTime(date) === getUnixTime(dateToCompare);


/**
 * Merge if date is is between of other dates
* @param  {Date}  dateToCompare [description]
 * @param  {Date}  startDate     [description]
 * @param  {Date}  endDate        [description]
 * @return {Boolean}               [description]
 */
export const isBetween = (dateToCompare, startDate, endDate) =>
  isSameOrAfter(dateToCompare, startDate)
  && isSameOrBefore(dateToCompare, endDate);
