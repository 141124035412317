import { actionTypes as types, urls } from '../constants';
import { post } from '../helpers';
import { getClientConfig } from '../helpers/portal/getClientConfig';

export const person_getData = (param) => dispatch => {
  dispatch({ type: types.PERSON_REQUEST });
  post({
    url: `${getClientConfig().mwUrl}/public/person/getData`,
    body: { ...param },
    success: types.PERSON_SUCCESS,
    failure: types.PERSON_FAILURE,
    dispatch,
  });
}

export const person_getVods = (param) => dispatch => {
  dispatch({ type: types.PERSON_VODS_REQUEST });
  post({
    url: `${getClientConfig().mwUrl}/public/person/person_getVods`,
    body: { ...param },
    success: types.PERSON_VODS_SUCCESS,
    failure: types.PERSON_VODS_FAILURE,
    dispatch,
  });
}

export const searchPersons = (param) => dispatch => {
  dispatch({ type: types.PERSON_SEARCH_REQUEST });
  post({
    url: `${getClientConfig().mwUrl}/public/person/searchPersons`,
    body: { ...param },
    success: types.PERSON_SEARCH_SUCCESS,
    failure: types.PERSON_SEARCH_FAILURE,
    dispatch,
  });
}
