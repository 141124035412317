import { actionTypes as types } from '../constants';

export const error_deleteItem = (id) => dispatch => {
  dispatch({
    type: types.ERROR_DELETE_ITEM,
    id,
  });
};

export const error_addItem = (item) => dispatch => {
  dispatch({
    type: types.ERROR_ADD_ITEM,
    item,
  });
};

export const error_deleteAll = () => dispatch => {
  dispatch({
    type: types.ERROR_DELETE_ALL,
  });
};

export const error_setParam = (param) => dispatch => {
  dispatch({
    type: types.ERROR_SET_PARAM,
    param: param,
  });
};


export const error_deleteFlashItem = (id) => dispatch => {
  dispatch({
    type: types.ERROR_DELETE_FLASH_ITEM,
    id,
  });
};
