/**
 * Give true if in string is similar string
 * @param  {String} strWhere [description]
 * @param  {String} strWhat  [description]
 * @return {Boolean}         [description]
 */
 export const includesStrLike = (strWhere, strWhat) => (
   strToSaveSearch(strWhere).includes(strToSaveSearch(strWhat))
 );

const strToSaveSearch = (str) => {
  if (typeof str === 'string' || str instanceof String) {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
  } else { return ""}
};
