import 'regenerator-runtime/runtime'

import {
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Suspense, lazy, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { getClientConfig } from '../helpers/portal/getClientConfig';
import { useQueryParams } from '../hooks/useQueryParams';
import { useRouters } from '../hooks/useRouters';
import { useUrlRedirectFilter } from '../hooks/useUrlRedirectFilter';
import PortalThemeProvider from './PortalThemeProvider';


const EpgModal = lazy(() => import('./Epg/EpgModal'));
const Helmet = lazy(() => import('./Helmet'));
const PlayerPin = lazy(() => import('./Share/ShakaMaterial/PlayerPin'));
const CicoPaymentPannel = lazy(() => import('../client-extensions/cico/components/Header/PaymentPannel.js'));

import './App.scss';
import {
  portal_setParam,
  firebase_pushMsgReceived,
} from '../actions';
import UniversalDetailDialog from "./Share/UniversalDetailDialog";
import YoutubeDialog from "./YoutubeDialog";

const useStyles = makeStyles({
  pageWrapper: props => ({
    backgroundColor: '#111213',
    minHeight: "calc(100vh - 1px)",
    paddingBottom: 1, // I haven't idea why is necessary, but if it this not here, app made white row in end of page
    ...props.pageWrapper
  }),
});


const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const routers = useRouters();

  const dialogEpg = useSelector(state => state.epg.dialogEpg);
  const dialogEpgIsOpen = useSelector(state => state.epg.dialogEpgIsOpen);
  const isFullScreen = useSelector(state => state.player.isFullScreen);
  const isPlaying = useSelector(state => state.player.isPlaying);
  const isMobileView = useSelector(state => state.portal.isMobileView);
  const googleToken = useSelector(state => state.firebase.googleToken);
  const isDefaultProfile = useSelector(state => state.user.selectedProfile?.isDefaultProfile);
  const detailUniversalDialog = useSelector(state => state.universal.detailUniversalDialog);
  const detailUniversalDialogIsOpen = useSelector(state => state.universal.detailUniversalDialogIsOpen);
  const youtubeDialog = useSelector(state => state.vod.youtubeDialog);
  const youtubeDialogIsOpen = useSelector(state => state.vod.youtubeDialogIsOpen)

  const motv_portals_portal_json_theme = useSelector(state => state.portal.motv_portals_portal_json_theme);
  const [versionWasWrite, setVersionWasWrite] = useState(false);

  const classes = useStyles({
    ...(motv_portals_portal_json_theme?.pageWrapper)
      ? { pageWrapper: motv_portals_portal_json_theme.pageWrapper }
      : {},
  });

  useEffect(() => {
    (
      isMobileView === undefined
      && dispatch(portal_setParam({
        isMobileView: !!queryParams.get('mobileView'),
      }))
    );

    if(!versionWasWrite) {
      setVersionWasWrite(true);
      console.info("App version: DEV-snapshot");
      console.info("App hash: DEV-hash");
    }
  }, []);


  const messageListener = (message) => {
    if(
      !message?.data["firebase-messaging-msg-data"] // linux
      && !message?.data?.data // macOs
    ) return;
    dispatch(firebase_pushMsgReceived({
      msgData: message.data["firebase-messaging-msg-data"] || message.data,
      history,
    }));
  };


  useEffect(() => {
    if(
      !navigator
      || !navigator.serviceWorker
      || !navigator.serviceWorker.addEventListener
    ) return;

    navigator?.serviceWorker?.addEventListener("message", messageListener);
    return () => {
      navigator?.serviceWorker?.removeEventListener("message", messageListener);
    }
  }, [googleToken]);

  useUrlRedirectFilter(() => {
    // console.log("urlRedirectFiltr byl zavolán");
  }, [location.pathname, location.search]);

  if(routers.length === 0) return null;

  return (
    <PortalThemeProvider>
      <div className={classes.pageWrapper} style={isFullScreen ? {backgroundColor: "black", backgroundImage: "none"} : isPlaying ? {backgroundImage: "none"} : {}}>
        <Suspense fallback={null}><Helmet /></Suspense>

        {getClientConfig().clientName === "cico" && isDefaultProfile &&
          <Suspense fallback={null}><CicoPaymentPannel /></Suspense>
        }
        <InRoute />

        <Suspense fallback={null}><PlayerPin /></Suspense>


        {dialogEpgIsOpen && dialogEpg && (
          <Suspense fallback={null}><EpgModal event={dialogEpg} /></Suspense>
        )}

        {detailUniversalDialogIsOpen && detailUniversalDialog && (
          <Suspense fallback={null}><UniversalDetailDialog {...{detailUniversalDialog, detailUniversalDialogIsOpen}} /></Suspense>
        )}

        {youtubeDialogIsOpen && youtubeDialog && (
          <Suspense fallback={null}><YoutubeDialog {...{youtubeDialog, youtubeDialogIsOpen}} /></Suspense>
        )}

      </div>
    </PortalThemeProvider>
  )
}

const InRoute = () => {
  const routers = useRouters();

  return (
    <Switch>
      {routers.map(routeItem => (
        <Route
          {...routeItem.path ? {path: routeItem.path} : {}}
          {...routeItem.exact ? {exact: true} : {}}
          key={routeItem.id}
          render={props => <RouteItem {...{props, routeItem}} />}
        />
      ))}
    </Switch>
  );
}


const RouteItem = ({
  props,
  routeItem,
}) => {
  return <Suspense fallback={null}>
    <routeItem.component {...props} />
  </Suspense>
}

RouteItem.propTypes = {
  props: PropTypes.object.isRequired,
  routeItem: PropTypes.object.isRequired,
}

export default App;
