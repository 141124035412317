import {
  BOX_ITEM_CATEGORY_TYPE, BOX_ITEM_CHANNEL_TYPE,
  BOX_ITEM_IMAGE_TYPE, BOX_ITEM_RECORDING_TYPE,
  BOX_ITEM_TV_TYPE,
  BOX_ITEM_VOD_TYPE,
} from "../../constants/boxItemTypes";

export const getEpgItemUrl = (epgItem) => {
  if (!epgItem || typeof epgItem !== "object" || epgItem.id === undefined)
    return undefined;

  switch (epgItem.type) {
    case BOX_ITEM_TV_TYPE:
      return `/event/detail/${epgItem.id}`;
    case BOX_ITEM_CHANNEL_TYPE:
      return `/tv/player/${epgItem.id}`;
    case BOX_ITEM_VOD_TYPE:
      return `/vod/${epgItem.id}`;
    case BOX_ITEM_CATEGORY_TYPE:
      return `/category/${epgItem.id}`;
    case BOX_ITEM_IMAGE_TYPE:
      return epgItem.action;
    case BOX_ITEM_RECORDING_TYPE:
      return `/recordings/${epgItem.id}`;
    default:
      return `/event/detail/${epgItem.id}`;
  }
};
