import { transformRecommendationRowResponseForReduce } from "./transformRecommendationRowResponseForReduce";

export const insertNewBoxItemsToRecommendation = ({
  boxItems,
  insertToRowWithId,
  recommendationPrevState,
  myListItems = [],
  recordingItems = [],
  rowStyle,
} = {}) => {
  return {
    generation: recommendationPrevState.generation + 1,
    rows: recommendationPrevState.rows.reduce(
      (acc, rowItem) =>
        insertToRowWithId === rowItem.id
          ? [
              ...acc,
              {
                ...rowItem,
                ...transformRecommendationRowResponseForReduce({
                  includeData: true,
                  prevRecommendationRowGen: rowItem,
                  recommendationRowResponse: { data: boxItems },
                  myListItems,
                  recordingItems,
                  rowStyle,
                }),
              },
            ]
          : [...acc, rowItem],
      []
    ),
  };
};
