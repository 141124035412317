import { formatDistance } from "date-fns";
import { FormattedMessage } from "react-intl";

export const expirationInfo = (exp) => {
    const today = new Date();
    const epgExpiration = new Date(exp);

    const localizedString = formatDistance(epgExpiration, today, {
      addSuffix: true,
    })

    return (
      <FormattedMessage
        id="label_expires_without_in"
        defaultMessage="Expires {s}"
        values={{
          s: localizedString,
        }}
      />
    );
  };