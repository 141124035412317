export const CHANNEL_SETTINGS_GET_VIEW_REQUEST = 'CHANNEL_SETTINGS_GET_VIEW_REQUEST';
export const CHANNEL_SETTINGS_GET_VIEW_SUCCESS = 'CHANNEL_SETTINGS_GET_VIEW_SUCCESS';
export const CHANNEL_SETTINGS_GET_VIEW_FAILURE = 'CHANNEL_SETTINGS_GET_VIEW_FAILURE';

export const CHANNEL_SETTINGS_ADD_CHANNEL_TO_FAVORITE_REQUEST = 'CHANNEL_SETTINGS_ADD_CHANNEL_TO_FAVORITE_REQUEST';
export const CHANNEL_SETTINGS_ADD_CHANNEL_TO_FAVORITE_SUCCESS = 'CHANNEL_SETTINGS_ADD_CHANNEL_TO_FAVORITE_SUCCESS';
export const CHANNEL_SETTINGS_ADD_CHANNEL_TO_FAVORITE_FAILURE = 'CHANNEL_SETTINGS_ADD_CHANNEL_TO_FAVORITE_FAILURE';

export const CHANNEL_SETTINGS_REMOVE_CHANNEL_FROM_FAVORITE_REQUEST = 'CHANNEL_SETTINGS_REMOVE_CHANNEL_FROM_FAVORITE_REQUEST';
export const CHANNEL_SETTINGS_REMOVE_CHANNEL_FROM_FAVORITE_SUCCESS = 'CHANNEL_SETTINGS_REMOVE_CHANNEL_FROM_FAVORITE_SUCCESS';
export const CHANNEL_SETTINGS_REMOVE_CHANNEL_FROM_FAVORITE_FAILURE = 'CHANNEL_SETTINGS_REMOVE_CHANNEL_FROM_FAVORITE_FAILURE';

export const CHANNEL_PLAYER_ADD_CHANNEL_TO_FAVORITE_REQUEST = 'CHANNEL_PLAYER_ADD_CHANNEL_TO_FAVORITE_REQUEST';
export const CHANNEL_PLAYER_ADD_CHANNEL_TO_FAVORITE_SUCCESS = 'CHANNEL_PLAYER_ADD_CHANNEL_TO_FAVORITE_SUCCESS';
export const CHANNEL_PLAYER_ADD_CHANNEL_TO_FAVORITE_FAILURE = 'CHANNEL_PLAYER_ADD_CHANNEL_TO_FAVORITE_FAILURE';

export const CHANNEL_PLAYER_REMOVE_CHANNEL_FROM_FAVORITE_REQUEST = 'CHANNEL_PLAYER_REMOVE_CHANNEL_FROM_FAVORITE_REQUEST';
export const CHANNEL_PLAYER_REMOVE_CHANNEL_FROM_FAVORITE_SUCCESS = 'CHANNEL_PLAYER_REMOVE_CHANNEL_FROM_FAVORITE_SUCCESS';
export const CHANNEL_PLAYER_REMOVE_CHANNEL_FROM_FAVORITE_FAILURE = 'CHANNEL_PLAYER_REMOVE_CHANNEL_FROM_FAVORITE_FAILURE';
