import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { convertToTimeCode } from "../../../helpers/datetime";
import { Link as RouterLink } from "react-router-dom";
import { expirationInfo } from "../../../helpers/localeDate/expirationInfo";
import { Typography } from "@mui/material";

const UniversalItemDetails = ({ detailUniversalDialog }) => {
  console.log("itemDetail", detailUniversalDialog)
  return (
    <>
      {detailUniversalDialog.description && (
        <Typography gutterBottom>
          {detailUniversalDialog.description}
        </Typography>
      )}

      {detailUniversalDialog.duration && (
        <Typography gutterBottom>
          <strong>
            <FormattedMessage
              id="label_recording_duration"
              defaultMessage="Duration"
            />
          </strong>
          : {convertToTimeCode(detailUniversalDialog.duration)}
        </Typography>
      )}

      {detailUniversalDialog.actors && (
        <Typography gutterBottom>
          <strong>
            <FormattedMessage id="label_actors" defaultMessage="Actors" />
          </strong>
          : {detailUniversalDialog.actors}
        </Typography>
      )}

      {detailUniversalDialog.genres && (
        <Typography gutterBottom>
          <strong>
            <FormattedMessage id="label_epg_genres" defaultMessage="Genres" />
          </strong>
          : {detailUniversalDialog.genres}
        </Typography>
      )}

      {detailUniversalDialog.director && (
        <Typography gutterBottom>
          <strong>
            <FormattedMessage id="label_directors" defaultMessage="Directors" />
          </strong>
          : {detailUniversalDialog.director}
        </Typography>
      )}

      {detailUniversalDialog.origin && (
        <Typography gutterBottom>
          <strong>
            <FormattedMessage id="label_epg_origin" defaultMessage="Origin" />
          </strong>
          : {detailUniversalDialog.origin}
        </Typography>
      )}

      {detailUniversalDialog.released && (
        <Typography gutterBottom>
          <strong>
            <FormattedMessage
              id="label_epg_released"
              defaultMessage="Released"
            />
          </strong>
          : {detailUniversalDialog.released}
        </Typography>
      )}

      {detailUniversalDialog.categories && (
        <Typography gutterBottom>
          <strong>
            <FormattedMessage
              id="label_epg_category"
              defaultMessage="Category"
            />
          </strong>
          : {detailUniversalDialog.categories}
        </Typography>
      )}

      {detailUniversalDialog.categories_id && detailUniversalDialog.serie_name && (
        <Typography gutterBottom>
          <strong>
            <FormattedMessage
              id="label_epg_category"
              defaultMessage="Category"
            />
          </strong>
          :{" "}
          <Link
            component={RouterLink}
            to={`/category/${detailUniversalDialog.categories_id}`}
          >
            {detailUniversalDialog.serie_name}
          </Link>
        </Typography>
      )}
      {detailUniversalDialog.expiration && (
        <Typography gutterBottom>
          <strong>{expirationInfo(detailUniversalDialog.expiration)}</strong>
        </Typography>
      )}
    </>
  );
};

UniversalItemDetails.propTypes = {
  detailUniversalDialog: PropTypes.object,
};

export default UniversalItemDetails;
