const fetch = require("node-fetch");

import { actionTypes as types } from '../../constants';
import { deleteAllEmptyAndUndefinedItemInJson } from '../json/deleteAllEmptyAndUndefinedItemInJson';
import { getClientConfig } from '../portal/getClientConfig';
import { getClientStorage } from '../device/clientStorage';
import { isSuccessResponse } from './isSuccessResponse';
import { loadFromCache, saveToCache } from './fetchCache';

const defaultTimeout = 20_000;



export const post = async ({
  body,
  dispatch,
  failure,
  headers,
  noAuth = false,
  param,
  request,
  smsPost = false,
  state,
  success,
  timeout = defaultTimeout,
  url,
}) => {


  try {
    const requestHeaders = {
      ...(smsPost)
        ? {}
        : {
          devicesType: btoa(getClientConfig().devicesType),
          ...(getAuthorization(noAuth)) ? {Authorization: getAuthorization(noAuth)} : {},
          ...(getProfilesId(noAuth)) ? {profilesId: getProfilesId(noAuth)} : {},
          ...(getLanguage()) ? {language: getLanguage()} : {},
        },
      ...(headers) ? headers : {}, // custom headers
    };



    const clearBody = deleteAllEmptyAndUndefinedItemInJson({...body,
      ...(smsPost) ? {
        ...(getAuthorization(noAuth)) ? {"customers_token": getClientStorage('authorization')} : {},
        ...(getProfilesId(noAuth)) ? {"profiles_id": parseInt(atob(getProfilesId(noAuth)), 10)} : {},
      } : {},
    });

    const options = {
      method: "POST",
      headers: requestHeaders,
      ...(clearBody !== undefined)
        ? {body: `\{"data": ${JSON.stringify(clearBody)}\}`}
        : {},
    };

    (request && dispatch && dispatch({ type: request,
      param: param || body,
      options,
    url}));

    const headerForCache = {
      profilesId: getClientStorage('profilesId'),
      Authorization: `Bearer ${getClientStorage('authorization')}`,
    };

    // const data =
    //   loadFromCache({url, body: clearBody})
    //   || await (await fetchWithTimeout(url, options, dispatch, timeout)).json;

    const dataFromCache =
      getClientStorage("disabledFetchCache") === "true"
        ? undefined
        : await loadFromCache({url, body: clearBody, header: headerForCache});

    let data = undefined;
    if (dataFromCache) {
      data = dataFromCache;
    } else {
      const res = await fetchWithTimeout(url, options, dispatch, timeout);
      data = await res.json();
      (
        data?.status === 1
        && getClientStorage("disabledFetchCache") !== "true"
        && saveToCache({url, response: data, body: clearBody, header: headerForCache})
      );
    }


    (
      !isSuccessResponse(data)
      && console.warn("Bad status", {data, param})
    );


    (
      data.status === 1 ? dispatch({ type: success, data, param, state, url }) // u POST zkontroluji, že je správný
      : dispatch({ type: failure, data, param, state, url }) // vše ostatní je zle
    );

    return data;

  } catch (e) {
    console.error('Fetch POST error', {cause: e, url, body, success, failure, dispatch, param});

    dispatch({ type: failure, param, state, url });

    (
      e.message === 'timeout'
      && dispatch({
        type: types.ERROR_ADD_ITEM,
        item: {
          title: 'error.connection_error',
          canBeDelete: true,
        },
        state,
      })
    );

  }
};

export const get = async ({
  url,
  success,
  failure,
  dispatch,
  param,
  timeout = defaultTimeout,
}) => {
  try {
    const res = await getFetchWithTimeout(url, timeout);

    dispatch({ type: success, param, url });
  } catch (e) {
    console.error('Fetch GET error', {cause: e, url, success, failure, dispatch, param});

    dispatch({ type: failure, param, url });

    (
      e.message === 'timeout'
      && dispatch({
        type: types.ERROR_ADD_ITEM,
        item: {
          title: 'error.connection_error',
          canBeDelete: true,
        },
        url,
      })
    );

  }
};



const fetchWithTimeout = async (url, options, dispatch, timeout) => {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) => setTimeout(() => reject(() => new Error('timeout')), timeout)),
    // TODO: sem by se hodil i nějaký warning na špatný net a ne rovnou to celé shazovat
    // skrze dispatch fce
  ]);
};

const getFetchWithTimeout = async (url, timeout) => {
  return Promise.race([
    fetch(url),
    new Promise((_, reject) => setTimeout(() => reject(() => new Error('timeout')), timeout)),
    // TODO: sem by se hodil i nějaký warning na špatný net a ne rovnou to celé shazovat
    // skrze dispatch fce
  ]);
};

const getAuthorization = (noAuth = false) => (getClientStorage('authorization') && !noAuth)
  ? `Bearer ${getClientStorage('authorization')}`
  : undefined;

const getProfilesId = (noAuth = false) => (getClientStorage('authorization') && getClientStorage('profilesId') && !noAuth)
  ? getClientStorage('profilesId')
  : undefined;

const getLanguage = () => getClientStorage('language')
  ? btoa(getClientStorage('language'))
  : undefined;
