import PropTypes from "prop-types";

const getExtendedUniversalItem = (
  universalItem,
  { myListItems = [], recordingItems = [] } = {}
) => {
  return [
    {
      ...universalItem,
      isInMyList: myListItems?.some(
        (listItem) => listItem.id === universalItem.id
      ),
      isRecordOn: recordingItems
        .reduce((acc, recordingRow) => [...acc, ...recordingRow.data], [])
        .some((recItem) => recItem.id === universalItem.id),
    },
  ][0];
};

getExtendedUniversalItem.propTypes = {
  universalItem: PropTypes.object.isRequired,
  myListItems: PropTypes.array,
  recordingItems: PropTypes.array,
};

export default getExtendedUniversalItem;
