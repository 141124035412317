import { convertToTimeCode, isBetween } from '../helpers/datetime';
import {
  fetch_channel_getStreamUrl,
  fetch_channel_getSubscribedAndLockedChannels,
  fetch_epg_getUpdatedEventsV2,
} from '../fetchs';
import { formatISO, getUnixTime, isAfter } from 'date-fns';

import { actionTypes as types } from '../constants';
import { clearCache as clearLocalStorageCache } from '../helpers/fetch/fetchCache';
import { getDataFromChannelStreamResponse } from '../helpers/channel/getDataFromChannelStreamResponse';
import { getEdgesForStreamUrl } from '../helpers/player/getEdgesForStreamUrl';
import { getNextOrFirstElement } from '../helpers/array/getNextOrFirstElement';
import { getPrevOrLastElement } from '../helpers/array/getPrevOrLastElement';
import { isSuccessResponse } from '../helpers/fetch/isSuccessResponse';
import { removeEpgEventsPrefix } from '../helpers/epg/removeEpgEventsPrefix';
import { vendor_getLockedChannelTextImage } from './';

export const player_addConfirm = (item) => dispatch => {
  dispatch({
    type: types.PLAYER_ADD_CONFIRM,
    item: item,
  });
};
export const player_removeConfirm = (id) => dispatch => {
  dispatch({
    type: types.PLAYER_REMOVE_CONFIRM,
    id: id,
  });
};
export const removeAllPlayerConfirm = () => dispatch => {
  dispatch({
    type: types.PLAYER_REMOVE_ALL_CONFIRM
  });
};
export const updatePlayerConfirm = (id, param) => dispatch => {
  dispatch({
    type: types.PLAYER_UPDATE_CONFIRM,
    id: id,
    param: param,
  });
};

export const playerSetStep = (step) => dispatch => {
  dispatch({
    type: types.PLAYER_SET_STEP,
    step: step,
  });
};

export const player_setParam = (param) => dispatch => {
  dispatch({
    type: types.PLAYER_SET_PARAM,
    param: param,
  });
};

// const player_test = () => async(dispatch, getState) => {
//   let response = await fetch('/article/promise-chaining/user.json');
// }

// Full player part
export const player_loadMinimumForFullPlayer = (param) => async(dispatch, getState) => {
  dispatch({
    type: types.PLAYER_FULLPLAYER_INIT_REQUEST,
    param,
  });
  const timestampOFUserVideoPosition = (param && param.timestamp) || getUnixTime(new Date());
  const {
    epg: {
      actualUsedItems: actualUsedEpgItems,
    },
    edge: {items: edgeItems},
  } = getState();

  const subscribedAndLockedChannelsResponse = await fetch_channel_getSubscribedAndLockedChannels(dispatch, {
    ...(param.showOnlyChannelsType) ? {type: param.showOnlyChannelsType} : {},
  });

  if(!isSuccessResponse(subscribedAndLockedChannelsResponse)) { // nevrátili se nám žádné kanály, vyhlásíme paniku
    dispatch({
      type: types.PLAYER_FULLPLAYER_INIT_FAILURE,
      error: (param?.playerFor === "tv") ? "label_no_channels_purchased" : "label_no_radios_purchased",
    });
    return;
  }

  const selectedChannelItem =
    ( // buď máme preferovaný kanál a pak ho zkusíme najít ve výsledku
      param.channels_id
      && subscribedAndLockedChannelsResponse.response.find(channelItem => channelItem.channels_id === param.channels_id))
    || (subscribedAndLockedChannelsResponse.response && subscribedAndLockedChannelsResponse.response[0] && subscribedAndLockedChannelsResponse.response[0]); // nebo vezmeme první výsledek

  if (!selectedChannelItem) { // není channels_id vyhlásíme paniku
    dispatch({
      type: types.PLAYER_FULLPLAYER_INIT_FAILURE,
      error: (param?.playerFor === "tv") ? "label_no_channels_purchased" : "label_no_radios_purchased",
    });
    return;
  }

  Promise.all([
    fetch_channel_getStreamUrl(dispatch, { // získáme si url pořadu
      channelsId: selectedChannelItem.channels_id,
      ...(param && param.timestamp) ? {timestamp: param.timestamp} : {},
      type: 'dash',
      ...getEdgesForStreamUrl(edgeItems),
    }),
    getEpgItemWithTimestampAndChannelId(timestampOFUserVideoPosition, selectedChannelItem.channels_id, actualUsedEpgItems, dispatch),
  ]).then(([streamResponse, eventForPlayerData]) => {
    const streamData = getDataFromChannelStreamResponse(streamResponse);

    if(!streamData || !eventForPlayerData) {
      dispatch({type: types.PLAYER_FULLPLAYER_INIT_FAILURE, data: {status: streamResponse.status || 903} });
      if (streamResponse.status === 903) {
        dispatch(vendor_getLockedChannelTextImage({channelsId: selectedChannelItem.channels_id}));
      }
      return;
    }

    const {epg: { selectedItem: actualSelectedEpg }} = getState();

    const dataForReduce = [{
      duration: getUnixTime(new Date(eventForPlayerData.end)) - getUnixTime(new Date(eventForPlayerData.start)),
      videoActualPosition: timestampOFUserVideoPosition - getUnixTime(new Date(eventForPlayerData.start)),
      currentTime: undefined,
      playerIsAbort: false,
      dateOfResponseStream: formatISO(new Date()),
      needEnterPin: !!(selectedChannelItem.channels_forced_pin),
      selectedEvent: {
        ...(actualSelectedEpg?.id === eventForPlayerData.id)
          ? actualSelectedEpg : {},
        ...removeEpgEventsPrefix(eventForPlayerData)
      },
      selectedChannelItem,
      nowLoadedForParams: param,
    }]
    .map(dataForReduceItem => ({...dataForReduceItem,
      ...getValuesForProgressBarForEpgItem(dataForReduceItem.selectedEvent, dataForReduceItem.duration),
      readAbleVideoActualPosition: convertToTimeCode(dataForReduceItem.videoActualPosition),
      readAbleVideoDuration: convertToTimeCode(dataForReduceItem.duration),
    }))[0];

    dispatch({type: types.PLAYER_FULLPLAYER_INIT_SUCCESS,
      streamData,
      // eventForPlayerResponse,
      ...dataForReduce,
    });
  })
  .catch(error => {
    dispatch({type: types.PLAYER_FULLPLAYER_INIT_FAILURE, error});
  });

}

export const player_loadNextChannel = () => async(dispatch, getState) => {
  const simpleSelectedChannel = {channels_id: getState().channel.selectedItem.channels_id};
  const allChannels =
    (getState().channel.items?.length > 0)
    ? getState().channel.items
    : await fetch_channel_getSubscribedAndLockedChannels(dispatch).then(response => {
      return (response.status === 1) ? response.response : [];
    });

  if(!allChannels || allChannels.length === 0) return;
  const simpleAllChannels = allChannels.map(channelItem => ({channels_id: channelItem.channels_id}));

  const nextSimpleChannelItem = getNextOrFirstElement(simpleSelectedChannel, simpleAllChannels);
  if(!(!!nextSimpleChannelItem)) return;
  dispatch(player_loadLiveChannelInPlayer(nextSimpleChannelItem));
};

export const player_loadPrevChannel = () => async(dispatch, getState) => {
  const simpleSelectedChannel = {channels_id: getState().channel.selectedItem.channels_id};
  const allChannels =
    (getState().channel.items?.length > 0)
    ? getState().channel.items
    : await fetch_channel_getSubscribedAndLockedChannels(dispatch).then(response => {
      return (response.status === 1) ? response.response : [];
    });

  if(!allChannels || allChannels.length === 0) return;
  const simpleAllChannels = allChannels.map(channelItem => ({channels_id: channelItem.channels_id}));

  const prevSimpleChannelItem = getPrevOrLastElement(simpleSelectedChannel, simpleAllChannels);
  if(!(!!prevSimpleChannelItem)) return;
  dispatch(player_loadLiveChannelInPlayer(prevSimpleChannelItem));
};


export const player_loadLiveChannelInPlayer = (newChannelItem) => async(dispatch, getState) => {
  const {channel: {
    actualUsedItems: actualUsedChannelItems,
  },
  epg: {
    items: epgItems,
    actualUsedItems: epgActualUsedItems,
  },
  edge: {items: edgeItems},
} = getState();

  const subscribedAndLockedChannelsResponse = await fetch_channel_getSubscribedAndLockedChannels(dispatch);

  const selectedChannelItem =
      (newChannelItem.channels_id
      && subscribedAndLockedChannelsResponse.response.find(channelItem => channelItem.channels_id === newChannelItem.channels_id))
    || (subscribedAndLockedChannelsResponse.response && subscribedAndLockedChannelsResponse.response[0] && subscribedAndLockedChannelsResponse.response[0]);

  const timestampOFUserVideoPosition = getUnixTime(new Date());

  // přepínáme v aktuálním channel jen na aktuální event
  const actualUsedItemsPreCache =
    newChannelItem?.channels_id === selectedChannelItem?.channels_id
    && epgActualUsedItems.map(epgItem => ({...epgItem,
      isSelected: isBetween(new Date(), new Date(epgItem.start), new Date(epgItem.end))
    }));

  dispatch({
    type: types.PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_REQUEST,
    actualUsedChannelItems: actualUsedChannelItems.map(channelItem => ({...channelItem,
      isSelected: (channelItem?.channels_id === newChannelItem?.channels_id)
    })),
    ...(actualUsedItemsPreCache) ? {actualUsedEpgItems: actualUsedItemsPreCache} : {},
  });


  Promise.all([
    fetch_channel_getStreamUrl(dispatch, { // získáme si url pořadu
      channelsId: newChannelItem.channels_id,
      type: 'dash',
      ...getEdgesForStreamUrl(edgeItems),
    }),
    getEpgItemWithTimestampAndChannelId(
      timestampOFUserVideoPosition,
      newChannelItem.channels_id,
      epgItems,
      dispatch
    ),
  ]).then(([streamResponse, eventForPlayerData]) => {
    const streamData = getDataFromChannelStreamResponse(streamResponse);

    if(!streamData || !eventForPlayerData) {
      if (streamResponse.status === 903) {
        dispatch(vendor_getLockedChannelTextImage({channelsId: newChannelItem.channels_id}));
      }

      dispatch({
        type: types.PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_FAILURE,
        data: {status: streamResponse.status || 903},
      });
      return;
    }

    const dataForReduce = [{
      duration: getUnixTime(new Date(eventForPlayerData.end)) - getUnixTime(new Date(eventForPlayerData.start)),
      videoActualPosition: timestampOFUserVideoPosition - getUnixTime(new Date(eventForPlayerData.start)),
      currentTime: undefined,
      dateOfResponseStream: formatISO(new Date()),
      needEnterPin: !!(
        selectedChannelItem.channels_forced_pin  // a je nutno zadat PIN
      ),
      playerIsAbort: false,
      selectedEvent: removeEpgEventsPrefix(eventForPlayerData),
      ...getValuesForProgressBarForEpgItem(
        removeEpgEventsPrefix(eventForPlayerData),
        getUnixTime(new Date(eventForPlayerData.end)) - getUnixTime(new Date(eventForPlayerData.start))
      ),
      nowLoadedForParams: { channels_id: newChannelItem.channels_id },
    }]
    .map(dataForReduceItem => ({...dataForReduceItem,
      readAbleVideoActualPosition: convertToTimeCode(dataForReduceItem.videoActualPosition),
      readAbleVideoDuration: convertToTimeCode(dataForReduceItem.duration),
    }))[0];

    dispatch({type: types.PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_SUCCESS,
      streamData,
      ...dataForReduce,
      selectedChannelItem,
    });
  },
    error => {dispatch({type: types.PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_FAILURE, error});}
  );
}

export const player_wasLoaded = ({isLiveView}) => (dispatch, getState) => {
  const {epg: {selectedItem: actualEpg}} = getState();
  const {player: {duration}} = getState();
  const isLiveEpg = actualEpg && isAfter(new Date(actualEpg.end), new Date());

  const videoActualPosition = // aktuální pozice ve videu v sekundách
      (isLiveView && isLiveEpg) ? getUnixTime(new Date()) - getUnixTime(new Date(actualEpg.start)) // je to právě běžící program
    : (isLiveView) ? 0 // je to historický ale původně live
    : 0; // je to VOD / Recording

  const currentTime = // absolutní čas, v live je jeho začátek někde v minulosti
    (isLiveView) ? null : 0; // pro live view dáme null, protože to co jde z videa při prvním snímku neplatí

  dispatch({
    type: types.PLAYER_WAS_LOADED,
    videoActualPosition,
    currentTime,
    isLive: isLiveEpg,
    ...getValuesForProgressBarForEpgItem(actualEpg, duration),
  });
};

export const player_loadNextEventInLiveStream = (param) => async(dispatch, getState) => {
  const {
    channel: { selectedItem: selectedChannelItem},
    epg: {
      selectedItem: selectedEpgItemInReduce,
      actualUsedItems: actualUsedEpgItems,
    },
    player: {currentTime}
  } = getState();

  // timestamp dalšího pořadu, tj aktuální plus jedna milisekunda
  const timestampOfNextEvent = getUnixTime(new Date(selectedEpgItemInReduce.end)) + 1;

  // dispatch({type: types.PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_REQUEST, param});

  const selectedEpgItem = await getEpgItemWithTimestampAndChannelId(
    timestampOfNextEvent,
    selectedChannelItem.channels_id,
    actualUsedEpgItems,
    dispatch
  );

  if (!selectedEpgItem) {
    dispatch({type: types.PLAYER_LOAD_ACTUAL_EVENT_IN_LIVE_STREAM_FAILURE, param: {
      timestampOfNextEvent,
      selectedChannelItem,
    }});
  }

  const dataForReduce = [{
    dateOfResponseStream: formatISO(new Date()),
    actualUsedEpgItems: actualUsedEpgItems.map(epgItem => ({...epgItem,
      isSelected: epgItem.start === selectedEpgItem.start,
    })),
    duration: getUnixTime(new Date(selectedEpgItem.end)) - getUnixTime(new Date(selectedEpgItem.start)),
    videoActualPosition: 0,
    currentTime,
    readAbleVideoActualPosition: convertToTimeCode(0),
    selectedEvent: removeEpgEventsPrefix(selectedEpgItem),
    ...getValuesForProgressBarForEpgItem(
      removeEpgEventsPrefix(selectedEpgItem),
      getUnixTime(new Date(selectedEpgItem.end)) - getUnixTime(new Date(selectedEpgItem.start))
    ),
  }]
  .map(dataForReduceItem => ({...dataForReduceItem,
    readAbleVideoDuration: convertToTimeCode(dataForReduceItem.duration),
  }))[0]

  dispatch({type: types.PLAYER_LOAD_ACTUAL_EVENT_IN_LIVE_STREAM_SUCCESS,
    ...dataForReduce,
  });

};

export const player_loadPlayerInTimestampAndInActualChannel = ({timestamp}) => async(dispatch, getState) => {
  const {
    channel: { selectedItem: selectedItem},
    epg: { actualUsedItems: actualUsedEpgItems},
    edge: {items: edgeItems},
  } = getState();

  if(!selectedItem) return;

  const subscribedAndLockedChannelsResponse = await fetch_channel_getSubscribedAndLockedChannels(dispatch);

  const selectedChannelItem =
      (selectedItem.channels_id
      && subscribedAndLockedChannelsResponse.response.find(channelItem => channelItem.channels_id === selectedItem.channels_id))
    || (subscribedAndLockedChannelsResponse.response && subscribedAndLockedChannelsResponse.response[0] && subscribedAndLockedChannelsResponse.response[0]);


  const actualUsedItemsPreCache = actualUsedEpgItems.map(epgItem => ({...epgItem,
    isSelected:
      selectedItem.channels_id === epgItem.channels_id
      && getUnixTime(new Date(epgItem.start)) === timestamp
    })
  );

  dispatch({
    type: types.PLAYER_LOAD_ONLY_SELECTED_EVENT_REQUEST,
    actualUsedEpgItems: actualUsedItemsPreCache,
  });

  Promise.all([
    getEpgItemWithTimestampAndChannelId(timestamp, selectedItem.channels_id, actualUsedEpgItems, dispatch),
    fetch_channel_getStreamUrl(dispatch, { // získáme si url pořadu
      channelsId: selectedItem.channels_id,
      type: 'dash',
      timestamp,
      ...getEdgesForStreamUrl(edgeItems),
    }),
  ]).then(
    ([eventForPlayerData, streamResponse]) => {
      const streamData = getDataFromChannelStreamResponse(streamResponse);

      if(!streamData || !eventForPlayerData) {
        dispatch({type: types.PLAYER_LOAD_ONLY_SELECTED_EVENT_FAILURE});
        return;
      }

      const dataForReduce = [{
        actualUsedEpgItems: actualUsedEpgItems.map(epgItem => ({...epgItem,
          isSelected: getUnixTime(new Date(epgItem.start)) === timestamp,
        })),
        duration: getUnixTime(new Date(eventForPlayerData.end)) - getUnixTime(new Date(eventForPlayerData.start)),
        videoActualPosition: 0,
        currentTime: undefined,
        needEnterPin: !!selectedChannelItem.channels_forced_pin,
        playerIsAbort: false,
        timestampUsedInUrl: timestamp,
        readAbleVideoActualPosition: convertToTimeCode(0),
        selectedEvent: removeEpgEventsPrefix(eventForPlayerData),
        ...getValuesForProgressBarForEpgItem(
          removeEpgEventsPrefix(eventForPlayerData),
          getUnixTime(new Date(eventForPlayerData.end)) - getUnixTime(new Date(eventForPlayerData.start))
        ),
        nowLoadedForParams: {timestamp, channels_id: selectedChannelItem.channels_id},
      }]
      .map(dataForReduceItem => ({...dataForReduceItem,
        readAbleVideoDuration: convertToTimeCode(dataForReduceItem.duration),
      }))[0];

      dispatch({type: types.PLAYER_LOAD_ONLY_SELECTED_EVENT_SUCCESS,
        streamData,
        ...dataForReduce,
      });
    },
    error => dispatch({type: types.PLAYER_LOAD_ONLY_SELECTED_EVENT_FAILURE, error})
  );
}

// TODO: přesunout do nové vrtvy na získávání dat
const getEpgItemWithTimestampAndChannelId = async(timestamp, channelId, cacheOfEpgItems, dispatch) => {
  const returnItemFromCache =
    [{ // máme timestamp, zkusíme vylovit v paměti
      selectedItem:
        timestamp
        && cacheOfEpgItems.find(epgItem =>
          channelId === epgItem.channels_id
          && getUnixTime(new Date(epgItem.start)) === timestamp
        )
    }].map(cache => ({ // není timestamp, zkusíme najít co běží nyní
      selectedItem: cache.selectedItem
      || (!timestamp && cacheOfEpgItems.find(epgItem =>
        channelId === epgItem.channels_id
        && isBetween(new Date(), new Date(epgItem.start), new Date(epgItem.end))
      ))
    }))[0].selectedItem;

  // if(returnItemFromCache) {return returnItemFromCache;}

  const epgFromBck = await dispatch(fetch_epg_getUpdatedEventsV2({ // a jeho délku a informace o něm
    timestamp: 0, // first init
    channels: [channelId],
    from: formatISO(new Date(timestamp * 1000)),
    to: formatISO(new Date(timestamp * 1000)),
  }));

  return epgFromBck.status === 1 && epgFromBck?.response[0];
}

export const player_timeUpdate = (param) => (dispatch, getState) => {
  const {
    currentTime,
    videoActualPosition: forceVideoActualPosition,
    // isLive,
  } = param;

  if(currentTime === 0) {
    // console.log("NIC nedělej, nula nemusí být nula");
    return;
  }

  const {player: {currentTime: currentTimePrevProps}} = getState();
  const {player: {reloadingIsInProgress}} = getState();
  const {player: {videoActualPosition: videoActualPositionPrevProps}} = getState();
  const {player: {isLive, duration, loaded: playerIsLoaded}} = getState();
  const {epg: {selectedItem: selectedEpgItemInPrevProps}} = getState();
  const {player: {startTime}} = getState();

  if(!playerIsLoaded || reloadingIsInProgress) {
    // console.log("něco se načítá, raději nic dělat nebudu", {playerIsLoaded, reloadingIsInProgress});
    return;
  }

  const videoActualPosition = [
    (forceVideoActualPosition) ? forceVideoActualPosition
    : (currentTimePrevProps) ? videoActualPositionPrevProps + (currentTime - currentTimePrevProps) // přičteme posun
    : videoActualPositionPrevProps // nic neuděláme
  ]
  .map(videoActualPositionItem => (
    videoActualPositionItem < 0
    ? 0 : videoActualPositionItem
  ))[0];
  if(
    currentTimePrevProps
    && (
      videoActualPosition === 0
      || parseInt(videoActualPosition, 10) === parseInt(videoActualPositionPrevProps, 10)
    )
  ) {
    // console.log("změnilo se toho málo, nic nedělám", {videoActualPosition, videoActualPositionPrevProps, currentTimePrevProps, currentTime});
    return;
  }



  const percentageProgress = parseInt((videoActualPosition / duration) * 100, 10);
  (videoActualPosition <= duration) // jen aktualizujeme čas
    ? dispatch({
      type: types.PLAYER_TIME_UPDATE,
      currentTime,
      videoActualPosition,
      percentageProgress,
      readAbleVideoActualPosition: videoActualPosition !== undefined
        ? convertToTimeCode(videoActualPosition)
        : undefined,
      ...getValuesForProgressBarForEpgItem(selectedEpgItemInPrevProps, duration),
      ...(startTime !== undefined) ? {} : {
        startTime: selectedEpgItemInPrevProps
          ? currentTime - (getUnixTime(new Date()) - getUnixTime(new Date(selectedEpgItemInPrevProps.start)))
          : 0, // vod, recording, catchup
      },
      ...(!selectedEpgItemInPrevProps || !isLive || !startTime) ? {} : {
        timeInLive: startTime + getUnixTime(new Date())
      },
    })
  : (selectedEpgItemInPrevProps && isLive) // nebo jsme v live?
    ? dispatch(player_loadNextEventInLiveStream())
  : (selectedEpgItemInPrevProps) // jsme někde v historii, jen si pustíme další pořad na stávajícím kanálu
    ? dispatch(player_loadPlayerInTimestampAndInActualChannel({timestamp: getUnixTime(new Date(selectedEpgItemInPrevProps.end))}))
  : () => {
    // TODO: dodělat mapování dalších pořadů
  };
}

const getValuesForProgressBarForEpgItem = (epgItem, duration) => {
  if (
    epgItem
    && isAfter(new Date(epgItem.end), new Date())
  ) {
    const durationLimitForLive = getUnixTime(new Date()) - getUnixTime(new Date(epgItem.start));
    const progressBarSize = (durationLimitForLive / duration) * 100;
    return {durationLimitForLive, progressBarSize};
  }

  return {progressBarSize: 100, durationLimitForLive: undefined}; // default value
}

export const player_updateProgressBarForLive = () => (dispatch, getState) => {
  const {
    player: { duration },
    epg: { selectedItem: selectedEpgItem },
  } = getState();

  if (!duration && !selectedEpgItem) return;

  dispatch({
    type: types.PLAYER_UPDATE_PROGRESS_BAR_FOR_LIVE,
    ...getValuesForProgressBarForEpgItem(selectedEpgItem, duration),
  });
}

export const player_makeHardReload = () => dispatch => {
  clearLocalStorageCache();
  dispatch({
    type: types.PLAYER_MAKE_HARD_RELOAD
  });
}

export const player_reset = (param = {}) => dispatch => {
  dispatch({
    type: types.PLAYER_RESET,
    param: param,
  });
}
