import { getClientConfig } from '../helpers/portal/getClientConfig';
import { post } from '../helpers';
import { actionTypes as types, urls } from '../constants';


export const fetch_vendor_getData = (dispatch, param, headers = {}) => post({
  url: `${getClientConfig().mwUrl}/public/vendor/getData`,
  noAuth: false,
  body: param,
  headers,
  request: types.VENDOR_GET_DATA_REQUEST,
  success: types.VENDOR_GET_DATA_SUCCESS,
  failure: types.VENDOR_GET_DATA_FAILURE,
  dispatch,
  networkMonitoring: true,
});

export const fetch_vendor_getLockedChannelTextImage = (dispatch, param, headers = {}) => post({
  url: `${getClientConfig().mwUrl}/public/vendor/getLockedChannelTextImage`,
  noAuth: false,
  body: param,
  headers,
  request: types.VENDOR_GET_LOCKED_CHANNEL_TEXT_REQUEST,
  success: types.VENDOR_GET_LOCKED_CHANNEL_TEXT_SUCCESS,
  failure: types.VENDOR_GET_LOCKED_CHANNEL_TEXT_FAILURE,
  dispatch,
});


export const fetch_vendor_getLockedVodTextImage = (dispatch, param, headers = {}) => post({
  url: `${getClientConfig().mwUrl}/public/vendor/getLockedVodTextImage`,
  noAuth: false,
  body: param,
  headers,
  request: types.VENDOR_GET_LOCKED_VOD_TEXT_REQUEST,
  success: types.VENDOR_GET_LOCKED_VOD_TEXT_SUCCESS,
  failure: types.VENDOR_GET_LOCKED_VOD_TEXT_FAILURE,
  dispatch,
});
