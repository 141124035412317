import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton, Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useDispatch} from "react-redux";
import {vod_unloadYoutubeDialog} from "../../actions";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import YoutubeEmbed from "./YoutubeEmbed";
import {Dialog} from "@material-ui/core";

const useStyles = makeStyles( (theme) => createStyles({
  dialogMain: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary
  },
  dialogActions: {
    backgroundColor: theme.palette.background.paper,
  },
  dialogContent: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    "& div": {
      marginRight: 15,
    },
  },
  dialogTitle: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  }
}));

const YoutubeDialog = ({youtubeDialog, youtubeDialogIsOpen, theme}) => {
  const dispatch = useDispatch();
  const styles = useStyles(theme);

  const handleCloseYoutubeDialog = () => {
    dispatch(vod_unloadYoutubeDialog);
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={youtubeDialogIsOpen}
        onClose={handleCloseYoutubeDialog}
        aria-labelledby="youtube-dialog-title"
        aria-describedby="youtube-dialog-description"
        className={styles.dialogMain}
      >
        <DialogTitle id="youtube-dialog-title" className={styles.dialogTitle}>
          {youtubeDialog.title && youtubeDialog.title}
          <IconButton
            aria-label="close"
            onClick={handleCloseYoutubeDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <YoutubeEmbed embedId={youtubeDialog.youtube_id} />
          <DialogContentText id="youtube-dialog-description">
            <Typography>
              {youtubeDialog.description && youtubeDialog.description}
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

YoutubeDialog.propTypes = {
  youtubeDialog: PropTypes.object.isRequired,
  youtubeDialogIsOpen: PropTypes.bool.isRequired,
};

export default YoutubeDialog;
