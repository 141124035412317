import { getClientConfig } from '../helpers/portal/getClientConfig';
import { post } from '../helpers';
import { actionTypes as types } from '../constants';
import { fetchTypes } from '../constants';


export const fetch_recommendation_getHomepage = (body, param) => dispatch => post({
  url: `${getClientConfig().mwUrl}/public/recommendationEngine/getHomepageV2`,
  request: fetchTypes.FETCH_RECOMMENDATION_HOMEPAGE_REQUEST,
  success: fetchTypes.FETCH_RECOMMENDATION_HOMEPAGE_SUCCESS,
  failure: fetchTypes.FETCH_RECOMMENDATION_HOMEPAGE_FAILURE,
  dispatch,
  body,
  param,
});

export const fetch_recommendation_getHomepageRow = (body, param) => dispatch => post({
  url: `${getClientConfig().mwUrl}/public/recommendationEngine/getHomepageRowV2`,
  request: fetchTypes.FETCH_RECOMMENDATION_HOMEPAGE_ROW_REQUEST,
  success: fetchTypes.FETCH_RECOMMENDATION_HOMEPAGE_ROW_SUCCESS,
  failure: fetchTypes.FETCH_RECOMMENDATION_HOMEPAGE_ROW_FAILURE,
  dispatch,
  body,
  param,
});

export const fetch_recommendation_getMyList = () => dispatch => post({
  url: `${getClientConfig().mwUrl}/public/recommendationEngine/getMyList`,
  request: fetchTypes.FETCH_RECOMMENDATION_GET_MY_LIST_REQUEST,
  success: fetchTypes.FETCH_RECOMMENDATION_GET_MY_LIST_SUCCESS,
  failure: fetchTypes.FETCH_RECOMMENDATION_GET_MY_LIST_FAILURE,
  dispatch,
});

export const fetch_recommendation_search = (body) => dispatch => post({
  body,
  url: `${getClientConfig().mwUrl}/public/recommendationEngine/search`,
  request: fetchTypes.FETCH_RECOMMENDATION_SEARCH_REQUEST,
  success: fetchTypes.FETCH_RECOMMENDATION_SEARCH_SUCCESS,
  failure: fetchTypes.FETCH_RECOMMENDATION_SEARCH_FAILURE,
  dispatch,
});

export const fetch_recommendation_getCategory = (param) => dispatch => post({
  url: `${getClientConfig().mwUrl}/public/recommendationEngine/getCategory`,
  body: param,
  request: fetchTypes.FETCH_RECOMMENDATION_GET_CATEGORY_REQUEST,
  success: fetchTypes.FETCH_RECOMMENDATION_GET_CATEGORY_SUCCESS,
  failure: fetchTypes.FETCH_RECOMMENDATION_GET_CATEGORY_FAILURE,
  dispatch,
  param,
});

export const fetch_recommendation_addToMyList = (dispatch, param) => post({
  url: `${getClientConfig().mwUrl}/public/recommendationEngine/addToMyList`,
  body: param,
  request: types.RECOMMENDATION_ADD_TO_MY_LIST_REQUEST,
  success: types.RECOMMENDATION_ADD_TO_MY_LIST_SUCCESS,
  failure: types.RECOMMENDATION_ADD_TO_MY_LIST_FAILURE,
  dispatch,
  param,
});

export const fetch_recommendation_getEventRecommendationRows = param => dispatch => post({
  url: `${getClientConfig().mwUrl}/public/recommendationEngine/getEventRecommendationRows`,
  body: param,
  request: fetchTypes.FETCH_RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_REQUEST,
  success: fetchTypes.FETCH_RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_SUCCESS,
  failure: fetchTypes.FETCH_RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_FAILURE,
  dispatch,
  param,
});

export const fetch_recommendation_removeFromMyList = (dispatch, param) => post({
  url: `${getClientConfig().mwUrl}/public/recommendationEngine/removeFromMyList`,
  body: param,
  request: types.RECOMMENDATION_REMOVE_FROM_MY_LIST_REQUEST,
  success: types.RECOMMENDATION_REMOVE_FROM_MY_LIST_SUCCESS,
  failure: types.RECOMMENDATION_REMOVE_FROM_MY_LIST_FAILURE,
  dispatch,
  param,
});
