import { post } from '../../helpers';
import { actionTypes as types } from './constants';
import { getClientConfig } from '../../helpers/portal/getClientConfig';

export const fetch_cico_subscriptionSummaryCall = (dispatch, param) => post({
  url: `${getClientConfig().smsUrl}/api/custom/cico/cancelSubscription`,
  smsPost: true,
  body: param,
  request: types.CICO__CANCEL_SUBSCRIPTION__REQUEST,
  success: types.CICO__CANCEL_SUBSCRIPTION__SUCCESS,
  failure: types.CICO__CANCEL_SUBSCRIPTION__FAILURE,
  dispatch,
});

export const fetch_cico_confirmOrder = (dispatch, param) => post({
  url: `${getClientConfig().smsUrl}/api/custom/cico/confirmOrder`,
  smsPost: true,
  body: param,
  request: types.CICO__CONFIRM_ORDER__REQUEST,
  success: types.CICO__CONFIRM_ORDER__SUCCESS,
  failure: types.CICO__CONFIRM_ORDER__FAILURE,
  dispatch,
});

export const fetch_cico_getAvailableProducts = (dispatch, param) => post({
  url: `${getClientConfig().smsUrl}/api/custom/cico/getAvailableProducts`,
  smsPost: true,
  body: param,
  request: types.CICO__GET_AVAILABLE_PRODUCTS__REQUEST,
  success: types.CICO__GET_AVAILABLE_PRODUCTS__SUCCESS,
  failure: types.CICO__GET_AVAILABLE_PRODUCTS__FAILURE,
  dispatch,
});

export const fetch_cico_getOrderUrl = (dispatch, param) => post({
  url: `${getClientConfig().smsUrl}/api/custom/cico/getOrderUrl`,
  smsPost: true,
  body: param,
  request: types.CICO__GET_ORDER_URL__REQUEST,
  success: types.CICO__GET_ORDER_URL__SUCCESS,
  failure: types.CICO__GET_ORDER_URL__FAILURE,
  dispatch,
  param,
});


export const fetch_cico_getSubscriptionStatus = (dispatch, param) => post({
  url: `${getClientConfig().smsUrl}/api/custom/cico/getSubscriptionStatus`,
  body: param,
  smsPost: true,
  request: types.CICO__GET_SUBSCRIPTION_STATUS__REQUEST,
  success: types.CICO__GET_SUBSCRIPTION_STATUS__SUCCESS,
  failure: types.CICO__GET_SUBSCRIPTION_STATUS__FAILURE,
  dispatch,
});
