import { actionTypes as types, fetchTypes } from '../constants';
import { EXTENDED_CONFIG_NAME } from '../constants/storage';
import { isSuccessResponse } from '../helpers/fetch/isSuccessResponse';
import { post } from '../helpers';
import { removeClientStorage, setClientStorage } from '../helpers/device/clientStorage';
import { URL_CACHE_NAME } from '../constants/smallPieces';

export const provider_loadProviderWithName = ({providerName}) => async(dispatch, getState) => {
  dispatch({type: types.PROVIDER_LOAD_PROVIDER_WITH_NAME_REQUEST});
  const providerResult = await post({
    url: `https://provider.motv.eu/app/config/${providerName}`,
    request: fetchTypes.FETCH_PROVIDER_GET_CONFIG_REQUEST,
    success: fetchTypes.FETCH_PROVIDER_GET_CONFIG_SUCCESS,
    failure: fetchTypes.FETCH_PROVIDER_GET_CONFIG_FAILURE,
    dispatch,
  });

  if(!isSuccessResponse(providerResult)) {
    dispatch({type: types.PROVIDER_LOAD_PROVIDER_WITH_NAME_FAILURE, data: providerResult});
    return;
  }

  const extendedConfig = {
    mwUrl: providerResult.response.mw.slice(0, -1), // remove last char "/"
    portalId: providerResult.response.portal,
    smsUrl: providerResult.response.api.replace("/api/", ""), // remove "/api/" sufix
    vendorId: providerResult.response.vendor,
  };

  setClientStorage(EXTENDED_CONFIG_NAME, JSON.stringify(extendedConfig));
  dispatch(reInitProviderSettings());
};


export const provider_loadDefaultProvider = () => async(dispatch, getState) => {
  removeClientStorage(EXTENDED_CONFIG_NAME);
  dispatch({type: types.PROVIDER_LOAD_DEFAULT_PROVIDER});
  dispatch(reInitProviderSettings());
};

const reInitProviderSettings = () => async(dispatch) => {
  setClientStorage(URL_CACHE_NAME, JSON.stringify([{}]));
  dispatch({type: types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS});
};
