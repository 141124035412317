import {
  BOX_ITEM_CHANNEL_TYPE,
  BOX_ITEM_IMAGE_TYPE,
} from "../../constants/boxItemTypes";
import {
  ROW_STYLE_LARGE,
  ROW_STYLE_LARGE_WIDE,
  ROW_STYLE_NORMAL_WIDE,
  ROW_STYLE_SMALL_WIDE,
} from "../../constants/rowStyles";

const FIX_HEIGHT_SMALL_WIDE = 138;
const FIX_WIDTH_SMALL_WIDE = 245;
const FIX_HEIGHT_NORMAL = 276; //normal
const FIX_WIDTH_NORMAL = 184; // normal
const FIX_HEIGHT_NORMAL_WIDE = 193;
const FIX_WIDTH_NORMAL_WIDE = 343;
const FIX_HEIGHT_LARGE = 441;
const FIX_WIDTH_LARGE = 330;
const FIX_HEIGHT_LARGE_WIDE = 359;
const FIX_WIDTH_LARGE_WIDE = 638;
const FIX_CHANNEL_HEIGHT = 140;

export const getScreenSizeForBoxItem = (boxItem = {}, rowStyle) => {
  return {
    screenHeight:
      rowStyle === ROW_STYLE_LARGE
        ? FIX_HEIGHT_LARGE
        : rowStyle === ROW_STYLE_LARGE_WIDE
        ? FIX_HEIGHT_LARGE_WIDE
        : rowStyle === ROW_STYLE_NORMAL_WIDE
        ? FIX_HEIGHT_NORMAL_WIDE
        : rowStyle === ROW_STYLE_SMALL_WIDE
        ? FIX_HEIGHT_SMALL_WIDE
        : boxItem.type === BOX_ITEM_CHANNEL_TYPE
        ? FIX_CHANNEL_HEIGHT
        : FIX_HEIGHT_NORMAL,
    screenWidth:
      boxItem.type === BOX_ITEM_IMAGE_TYPE
        ? parseInt(
            (boxItem.image_width / boxItem.image_height) *
              (rowStyle === ROW_STYLE_LARGE
                ? FIX_HEIGHT_LARGE
                : rowStyle === ROW_STYLE_LARGE_WIDE
                ? FIX_HEIGHT_LARGE_WIDE
                : rowStyle === ROW_STYLE_NORMAL_WIDE
                ? FIX_HEIGHT_NORMAL_WIDE
                : rowStyle === ROW_STYLE_SMALL_WIDE
                ? FIX_HEIGHT_SMALL_WIDE
                : boxItem.type === BOX_ITEM_CHANNEL_TYPE
                ? FIX_CHANNEL_HEIGHT
                : FIX_HEIGHT_NORMAL),
            10
          )
        : rowStyle === ROW_STYLE_LARGE
        ? FIX_WIDTH_LARGE
        : rowStyle === ROW_STYLE_LARGE_WIDE
        ? FIX_WIDTH_LARGE_WIDE
        : rowStyle === ROW_STYLE_NORMAL_WIDE
        ? FIX_WIDTH_NORMAL_WIDE
        : rowStyle === ROW_STYLE_SMALL_WIDE
        ? FIX_WIDTH_SMALL_WIDE
        : FIX_WIDTH_NORMAL,
  };
};
