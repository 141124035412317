import { actionTypes as types } from '../constants'

const defaultState = {
  loaded: true,
  person: undefined,
  items: [],
}

const person = (
  state = defaultState,
  action,
) => {
  switch (action.type) {
    case types.PERSON_REQUEST:
      return { ...state, loaded: false }
    case types.PERSON_SUCCESS:
      return { ...state,
        person: action.data.response,
        loaded: true,
      };
    case types.PERSON_FAILURE:
      return { ...state, person: undefined };
    case types.PERSON_VODS_REQUEST:
      return { ...state, loaded: false };
    case types.PERSON_VODS_SUCCESS:
      return { ...state,
        items: action.data.response,
        loaded: true,
      };
    case types.PERSON_VODS_FAILURE:
      return { ...state, items: [] };

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;

    case types.RESET_STATE:
      return defaultState;
    default:
      return {...state, loaded: true};
  }
};

export default person;
