import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import {FormattedMessage, useIntl} from "react-intl";
import {
  recording_addRecording,
  recording_removeRecording,
  universal_loadUniversalDetailDialog
} from "../../../../actions";
import {useDispatch} from "react-redux";

const UniversalDetailDialogRecordButton = ({ detailUniversalDialog, styles }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const handleClickRecordOn = async () => {
    await dispatch(recording_addRecording(detailUniversalDialog));
    dispatch(
      universal_loadUniversalDetailDialog({
        ...detailUniversalDialog,
        idRecordOn: true,
      })
    );
  }

  const handleClickRecordOff = async () => {
    await dispatch(recording_removeRecording(detailUniversalDialog));
    dispatch(
      universal_loadUniversalDetailDialog({
        ...detailUniversalDialog,
        idRecordOn: false,
      })
    );
  }

  return (
    <>
      {detailUniversalDialog.isRecordOn ? (
        <Button
          className={styles.nonPlayButton}
          onClick={handleClickRecordOff}
        >
          <img
            src="/images/icons/ic_remove_from_recordings.svg"
            alt={intl.formatMessage({
              id: "label_event_remove_recording",
              defaultMessage: "Remove from recordings",
            })}
          />
          <FormattedMessage
            id="label_event_remove_recording"
            defaultMessage="Remove from recordings"
          />
        </Button>
      ) : (
        <Button
          className={styles.nonPlayButton}
          onClick={handleClickRecordOn}
        >
          <img
            src="/images/icons/ic_record.svg"
            alt={intl.formatMessage({
              id: "label_record",
              defaultMessage: "Record",
            })}
          />
          <FormattedMessage
            id="label_record"
            defaultMessage="Record"
          />
        </Button>
      )}
    </>
  );
};

UniversalDetailDialogRecordButton.propTypes = {
  detailUniversalDialog: PropTypes.object,
  styles: PropTypes.object,
};

export default UniversalDetailDialogRecordButton;
