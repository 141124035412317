import { config as defaltClientConfig } from '../../../config-universal/client';
import { getClientStorage } from '../device/clientStorage';
import { EXTENDED_CONFIG_NAME } from '../../constants/storage';

export const getClientConfig = () => {
  return {
    ...defaltClientConfig,
    ...(getClientStorage(EXTENDED_CONFIG_NAME) && getClientStorage(EXTENDED_CONFIG_NAME) !== "undefined") ? JSON.parse(getClientStorage(EXTENDED_CONFIG_NAME)) : {},
  };
};
