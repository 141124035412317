export * from './customer';
export * from './epg';
export * from './error';
export * from './channel';
export * from './portal';
export * from './provider';
export * from './recommendation';
export * from './recording';
export * from './search';
export * from './sms';
export * from './universal';
export * from './vod';

export const EDGE_INIT_REQUEST = 'EDGE_INIT_REQUEST';
export const EDGE_INIT_SUCCESS = 'EDGE_INIT_SUCCESS';
export const EDGE_INIT_FAILURE = 'EDGE_INIT_FAILURE';

export const EDGE_GET_LIST_REQUEST = 'EDGE_GET_LIST_REQUEST';
export const EDGE_GET_LIST_SUCCESS = 'EDGE_GET_LIST_SUCCESS';
export const EDGE_GET_LIST_FAILURE = 'EDGE_GET_LIST_FAILURE';

export const EDGE_ITEM_SPEED_TEST_REQUEST = 'EDGE_ITEM_SPEED_TEST_REQUEST';
export const EDGE_ITEM_SPEED_TEST_SUCCESS = 'EDGE_ITEM_SPEED_TEST_SUCCESS';
export const EDGE_ITEM_SPEED_TEST_FAILURE = 'EDGE_ITEM_SPEED_TEST_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const CATEGORIES_REQUEST = 'CATEGORIES_REQUEST';
export const CATEGORIES_SUCCESS = 'CATEGORIES_SUCCESS';
export const CATEGORIES_FAILURE = 'CATEGORIES_FAILURE';

export const PERSON_REQUEST = 'PERSON_REQUEST';
export const PERSON_SUCCESS = 'PERSON_SUCCESS';
export const PERSON_FAILURE = 'PERSON_FAILURE';

export const PERSON_VODS_REQUEST = 'PERSON_VODS_REQUEST';
export const PERSON_VODS_SUCCESS = 'PERSON_VODS_SUCCESS';
export const PERSON_VODS_FAILURE = 'PERSON_VODS_FAILURE';

export const PERSON_SEARCH_REQUEST = 'PERSON_SEARCH_REQUEST';
export const PERSON_SEARCH_SUCCESS = 'PERSON_SEARCH_SUCCESS';
export const PERSON_SEARCH_FAILURE = 'PERSON_SEARCH_FAILURE';

export const TEST_REQUEST = 'TEST_REQUEST';
export const TEST_SUCCESS = 'TEST_SUCCESS';
export const TEST_FAILURE = 'TEST_FAILURE';

export const VOD_SEARCH_MAKE_ACTIVE = 'VOD_SEARCH_MAKE_ACTIVE';
export const VOD_LEAVE_PLAYER = 'VOD_LEAVE_PLAYER';

export const RESET_STATE = 'RESET_STATE';

export const SET_SELECTED_VOD = 'SET_SELECTED_VOD';

export const SET_DIALOG_VOD = 'SET_DIALOG_VOD';

export const GET_VOD_DATA_FOR_DIALOG_REQUEST = 'GET_VOD_DATA_FOR_DIALOG_REQUEST';
export const GET_VOD_DATA_FOR_DIALOG_SUCCESS = 'GET_VOD_DATA_FOR_DIALOG_SUCCESS';
export const GET_VOD_DATA_FOR_DIALOG_FAILURE = 'GET_VOD_DATA_FOR_DIALOG_FAILURE';

export const PLAYER_ADD_CONFIRM = 'PLAYER_ADD_CONFIRM';
export const PLAYER_REMOVE_CONFIRM = 'PLAYER_REMOVE_CONFIRM';
export const PLAYER_FULLSCREEN_ON = 'PLAYER_FULLSCREEN_ON';
export const PLAYER_FULLSCREEN_OFF = 'PLAYER_FULLSCREEN_OFF';
export const PLAYER_REMOVE_ALL_CONFIRM = 'PLAYER_REMOVE_ALL_CONFIRM';
export const PLAYER_UPDATE_CONFIRM = 'PLAYER_UPDATE_CONFIRM';
export const PLAYER_SET_STEP = 'PLAYER_SET_STEP';
export const PLAYER_SET_PARAM = 'PLAYER_SET_PARAM';
export const PLAYER_RESET = 'PLAYER_RESET';
export const PLAYER_TIME_UPDATE = 'PLAYER_TIME_UPDATE';
export const PLAYER_WAS_LOADED = 'PLAYER_WAS_LOADED';
export const PLAYER_MAKE_HARD_RELOAD = 'PLAYER_MAKE_HARD_RELOAD';
export const PLAYER_UPDATE_PROGRESS_BAR_FOR_LIVE = 'PLAYER_UPDATE_PROGRESS_BAR_FOR_LIVE';

export const PLAYER_FULLPLAYER_INIT_REQUEST = 'PLAYER_FULLPLAYER_INIT_REQUEST';
export const PLAYER_FULLPLAYER_INIT_SUCCESS = 'PLAYER_FULLPLAYER_INIT_SUCCESS';
export const PLAYER_FULLPLAYER_INIT_FAILURE = 'PLAYER_FULLPLAYER_INIT_FAILURE';
export const PLAYER_FULLPLAYER_GET_EVENT_REQUEST = 'PLAYER_FULLPLAYER_GET_EVENT_REQUEST';
export const PLAYER_FULLPLAYER_GET_EVENT_SUCCESS = 'PLAYER_FULLPLAYER_GET_EVENT_SUCCESS';
export const PLAYER_FULLPLAYER_GET_EVENT_FAILURE = 'PLAYER_FULLPLAYER_GET_EVENT_FAILURE';
export const PLAYER_FULLPLAYER_GET_CHANNEL_REQUEST = 'PLAYER_FULLPLAYER_GET_CHANNEL_REQUEST';
export const PLAYER_FULLPLAYER_GET_CHANNEL_SUCCESS = 'PLAYER_FULLPLAYER_GET_CHANNEL_SUCCESS';
export const PLAYER_FULLPLAYER_GET_CHANNEL_FAILURE = 'PLAYER_FULLPLAYER_GET_CHANNEL_FAILURE';
export const PLAYER_FULLPLAYER_GET_STREAM_URL_REQUEST = 'PLAYER_FULLPLAYER_GET_STREAM_URL_REQUEST';
export const PLAYER_FULLPLAYER_GET_STREAM_URL_SUCCESS = 'PLAYER_FULLPLAYER_GET_STREAM_URL_SUCCESS';
export const PLAYER_FULLPLAYER_GET_STREAM_URL_FAILURE = 'PLAYER_FULLPLAYER_GET_STREAM_URL_FAILURE';

export const PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_REQUEST = 'PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_REQUEST';
export const PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_SUCCESS = 'PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_SUCCESS';
export const PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_FAILURE = 'PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_FAILURE';

export const PLAYER_LOAD_ONLY_SELECTED_EVENT_REQUEST = 'PLAYER_LOAD_ONLY_SELECTED_EVENT_REQUEST';
export const PLAYER_LOAD_ONLY_SELECTED_EVENT_SUCCESS = 'PLAYER_LOAD_ONLY_SELECTED_EVENT_SUCCESS';
export const PLAYER_LOAD_ONLY_SELECTED_EVENT_FAILURE = 'PLAYER_LOAD_ONLY_SELECTED_EVENT_FAILURE';

export const PLAYER_LOAD_ACTUAL_EVENT_IN_LIVE_STREAM_REQUEST = 'PLAYER_LOAD_ACTUAL_EVENT_IN_LIVE_STREAM_REQUEST';
export const PLAYER_LOAD_ACTUAL_EVENT_IN_LIVE_STREAM_SUCCESS = 'PLAYER_LOAD_ACTUAL_EVENT_IN_LIVE_STREAM_SUCCESS';
export const PLAYER_LOAD_ACTUAL_EVENT_IN_LIVE_STREAM_FAILURE = 'PLAYER_LOAD_ACTUAL_EVENT_IN_LIVE_STREAM_FAILURE';

export const CHANNELS_SUBSCRIBED_REQUEST = 'CHANNELS_SUBSCRIBED_REQUEST';
export const CHANNELS_SUBSCRIBED_SUCCESS = 'CHANNELS_SUBSCRIBED_SUCCESS';
export const CHANNELS_SUBSCRIBED_FAILURE = 'CHANNELS_SUBSCRIBED_FAILURE';

export const CHANNELS_STREAM_URL_REQUEST = 'CHANNELS_STREAM_URL_REQUEST';
export const CHANNELS_STREAM_URL_SUCCESS = 'CHANNELS_STREAM_URL_SUCCESS';
export const CHANNELS_STREAM_URL_FAILURE = 'CHANNELS_STREAM_URL_FAILURE';

export const CHANNELS_GET_STARTUP_CHANNEL_REQUEST = 'CHANNELS_GET_STARTUP_CHANNEL_REQUEST';
export const CHANNELS_GET_STARTUP_CHANNEL_SUCCESS = 'CHANNELS_GET_STARTUP_CHANNEL_SUCCESS';
export const CHANNELS_GET_STARTUP_CHANNEL_FAILURE = 'CHANNELS_GET_STARTUP_CHANNEL_FAILURE';

export const CHANNELS_DEFAULT_CHANNEL_REQUEST = 'CHANNELS_DEFAULT_CHANNEL_REQUEST';
export const CHANNELS_DEFAULT_CHANNEL_SUCCESS = 'CHANNELS_DEFAULT_CHANNEL_SUCCESS';
export const CHANNELS_DEFAULT_CHANNEL_FAILURE = 'CHANNELS_DEFAULT_CHANNEL_FAILURE';

export const CHANNELS_SET_PARAM = 'CHANNELS_SET_PARAM';

export const CHANNELS_UNPIN_ACTUAL_SELECTED_CHANNEL = 'CHANNELS_UNPIN_ACTUAL_SELECTED_CHANNEL';

export const CHANNEL_UPDATE_EPG_IN_ACTUAL_USED_CHANNEL_LIST = 'CHANNEL_UPDATE_EPG_IN_ACTUAL_USED_CHANNEL_LIST';

export const CHANNEL_RESET = 'CHANNEL_RESET';
export const CHANNEL_UPDATE_SEARCH = 'CHANNEL_UPDATE_SEARCH';

export const CHANNEL_CATEGORY__GET_CHANNEL_CATEGORIES_REQUEST = 'CHANNEL_CATEGORY__GET_CHANNEL_CATEGORIES_REQUEST';
export const CHANNEL_CATEGORY__GET_CHANNEL_CATEGORIES_SUCCESS = 'CHANNEL_CATEGORY__GET_CHANNEL_CATEGORIES_SUCCESS';
export const CHANNEL_CATEGORY__GET_CHANNEL_CATEGORIES_FAILURE = 'CHANNEL_CATEGORY__GET_CHANNEL_CATEGORIES_FAILURE';

export const CHANNEL_CATEGORY__SET_CHANNEL_CATEGORY = 'CHANNEL_CATEGORY__SET_CHANNEL_CATEGORY';

export const CHANNEL_INIT_CHANNEL_LIST_WITH_ACTUAL_EPG_REQUEST = 'CHANNEL_INIT_CHANNEL_LIST_WITH_ACTUAL_EPG_REQUEST';
export const CHANNEL_INIT_CHANNEL_LIST_WITH_ACTUAL_EPG_SUCCESS = 'CHANNEL_INIT_CHANNEL_LIST_WITH_ACTUAL_EPG_SUCCESS';
export const CHANNEL_INIT_CHANNEL_LIST_WITH_ACTUAL_EPG_FAILURE = 'CHANNEL_INIT_CHANNEL_LIST_WITH_ACTUAL_EPG_FAILURE';

export const CHANNEL_ROLLING_REQUEST = 'CHANNEL_ROLLING_REQUEST';
export const CHANNEL_ROLLING_SUCCESS = 'CHANNEL_ROLLING_SUCCESS';
export const CHANNEL_ROLLING_FAILURE = 'CHANNEL_ROLLING_FAILURE';

export const EPG_UPDATED_EVENTS_REQUEST = 'EPG_UPDATED_EVENTS_REQUEST';
export const EPG_UPDATED_EVENTS_SUCCESS = 'EPG_UPDATED_EVENTS_SUCCESS';
export const EPG_UPDATED_EVENTS_FAILURE = 'EPG_UPDATED_EVENTS_FAILURE';

export const EPG_UPDATED_EVENTS_REQUEST_WO_UPDATING_REDUX = 'EPG_UPDATED_EVENTS_REQUEST_WO_UPDATING_REDUX';
export const EPG_UPDATED_EVENTS_SUCCESS_WO_UPDATING_REDUX = 'EPG_UPDATED_EVENTS_SUCCESS_WO_UPDATING_REDUX';
export const EPG_UPDATED_EVENTS_FAILURE_WO_UPDATING_REDUX = 'EPG_UPDATED_EVENTS_FAILURE_WO_UPDATING_REDUX';

export const EPG_LOAD_EVENTS_FOR_DAY_AND_CHANNEL_REQUEST = 'EPG_LOAD_EVENTS_FOR_DAY_AND_CHANNEL_REQUEST';
export const EPG_LOAD_EVENTS_FOR_DAY_AND_CHANNEL_SUCCESS = 'EPG_LOAD_EVENTS_FOR_DAY_AND_CHANNEL_SUCCESS';
export const EPG_LOAD_EVENTS_FOR_DAY_AND_CHANNEL_FAILURE = 'EPG_LOAD_EVENTS_FOR_DAY_AND_CHANNEL_FAILURE';

export const EPG_LOAD_DIALOG_REQUEST = 'EPG_LOAD_DIALOG_REQUEST';
export const EPG_LOAD_DIALOG_SUCCESS = 'EPG_LOAD_DIALOG_SUCCESS';
export const EPG_LOAD_DIALOG_FAILURE = 'EPG_LOAD_DIALOG_FAILURE';

export const EPG_LOAD_SELECTED_EPG_REQUEST = 'EPG_LOAD_SELECTED_EPG_REQUEST';
export const EPG_LOAD_SELECTED_EPG_SUCCESS = 'EPG_LOAD_SELECTED_EPG_SUCCESS';
export const EPG_LOAD_SELECTED_EPG_FAILURE = 'EPG_LOAD_SELECTED_EPG_FAILURE';

export const EPG_LOAD_EPG_INFO_FOR_CHANNEL_ITEM_REQUEST = 'EPG_LOAD_EPG_INFO_FOR_CHANNEL_ITEM_REQUEST';
export const EPG_LOAD_EPG_INFO_FOR_CHANNEL_ITEM_SUCCESS = 'EPG_LOAD_EPG_INFO_FOR_CHANNEL_ITEM_SUCCESS';
export const EPG_LOAD_EPG_INFO_FOR_CHANNEL_ITEM_FAILURE = 'EPG_LOAD_EPG_INFO_FOR_CHANNEL_ITEM_FAILURE';

export const EPG_GET_EXTENDED_ITEM_REQUEST = 'EPG_GET_EXTENDED_ITEM_REQUEST';
export const EPG_GET_EXTENDED_ITEM_SUCCESS = 'EPG_GET_EXTENDED_ITEM_SUCCESS';
export const EPG_GET_EXTENDED_ITEM_FAILURE = 'EPG_GET_EXTENDED_ITEM_FAILURE';

export const EPG_RECHECK_IF_ALL_EVENT_HAS_GOOD_STATUS = 'EPG_RECHECK_IF_ALL_EVENT_HAS_GOOD_STATUS';

export const EPG_SET_PARAM = 'EPG_SET_PARAM';
export const EPG_UPDATE_SEARCH = 'EPG_UPDATE_SEARCH';

export const EPG_RESET_STATE = 'EPG_RESET_STATE';

export const TV_GUIDE_FLET_REQUEST = 'TV_GUIDE_FLET_REQUEST';
export const TV_GUIDE_FLET_SUCCESS = 'TV_GUIDE_FLET_SUCCESS';
export const TV_GUIDE_FLET_FAILURE = 'TV_GUIDE_FLET_FAILURE';

export const TV_GUIDE_ROLLING_REQUEST = 'TV_GUIDE_ROLLING_REQUEST';
export const TV_GUIDE_ROLLING_SUCCESS = 'TV_GUIDE_ROLLING_SUCCESS';
export const TV_GUIDE_ROLLING_FAILURE = 'TV_GUIDE_ROLLING_FAILURE';

export const CHANNEL_DATE_CURSORS = 'CHANNEL_DATE_CURSORS';

export const DRMS_REQUEST = 'DRMS_REQUEST';
export const DRMS_SUCCESS = 'DRMS_SUCCESS';
export const DRMS_FAILURE = 'DRMS_FAILURE';

export const USER_RESET_STATE = 'USER_RESET_STATE';
export const USER_SET_PARAM = 'USER_SET_PARAM';

export const USER_SET_SELECTED_PROFILE_REQUEST = 'USER_SET_SELECTED_PROFILE_REQUEST';
export const USER_SET_SELECTED_PROFILE_SUCCESS = 'USER_SET_SELECTED_PROFILE_SUCCESS';
export const USER_SET_SELECTED_PROFILE_FAILURE = 'USER_SET_SELECTED_PROFILE_FAILURE';

export const CATEGORY_GET_CATEGORY_WITH_MY_LIST_REQUEST = 'CATEGORY_GET_CATEGORY_WITH_MY_LIST_REQUEST';
export const CATEGORY_GET_CATEGORY_WITH_MY_LIST_SUCCESS = 'CATEGORY_GET_CATEGORY_WITH_MY_LIST_SUCCESS';
export const CATEGORY_GET_CATEGORY_WITH_MY_LIST_FAILURE = 'CATEGORY_GET_CATEGORY_WITH_MY_LIST_FAILURE';

export const DEVICE_GET_DATA_REQUEST = 'DEVICE_GET_DATA_REQUEST';
export const DEVICE_GET_DATA_SUCCESS = 'DEVICE_GET_DATA_SUCCESS';
export const DEVICE_GET_DATA_FAILURE = 'DEVICE_GET_DATA_FAILURE';

export const DEVICE_GET_DEVICES_REQUEST = 'DEVICE_GET_DEVICES_REQUEST';
export const DEVICE_GET_DEVICES_SUCCESS = 'DEVICE_GET_DEVICES_SUCCESS';
export const DEVICE_GET_DEVICES_FAILURE = 'DEVICE_GET_DEVICES_FAILURE';

export const DEVICE_REMOVE_DEVICE_REQUEST = 'DEVICE_REMOVE_DEVICE_REQUEST';
export const DEVICE_REMOVE_DEVICE_SUCCESS = 'DEVICE_REMOVE_DEVICE_SUCCESS';
export const DEVICE_REMOVE_DEVICE_FAILURE = 'DEVICE_REMOVE_DEVICE_FAILURE';

export const PROFILE_UPDATE_CHANNEL_ORDER_REQUEST = 'PROFILE_UPDATE_CHANNEL_ORDER_REQUEST';
export const PROFILE_UPDATE_CHANNEL_ORDER_SUCCESS = 'PROFILE_UPDATE_CHANNEL_ORDER_SUCCESS';
export const PROFILE_UPDATE_CHANNEL_ORDER_FAILURE = 'PROFILE_UPDATE_CHANNEL_ORDER_FAILURE';

export const PROFILE_RESET_CHANNEL_ORDER_REQUEST = 'PROFILE_RESET_CHANNEL_ORDER_REQUEST';
export const PROFILE_RESET_CHANNEL_ORDER_SUCCESS = 'PROFILE_RESET_CHANNEL_ORDER_SUCCESS';
export const PROFILE_RESET_CHANNEL_ORDER_FAILURE = 'PROFILE_RESET_CHANNEL_ORDER_FAILURE';

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILURE = 'PROFILE_UPDATE_FAILURE';

export const PROFILE_DELETE_PROFILE_REQUEST = 'PROFILE_DELETE_PROFILE_REQUEST';
export const PROFILE_DELETE_PROFILE_SUCCESS = 'PROFILE_DELETE_PROFILE_SUCCESS';
export const PROFILE_DELETE_PROFILE_FAILURE = 'PROFILE_DELETE_PROFILE_FAILURE';

export const PROFILE_GET_PROFILES_REQUEST = 'PROFILE_GET_PROFILES_REQUEST';
export const PROFILE_GET_PROFILES_SUCCESS = 'PROFILE_GET_PROFILES_SUCCESS';
export const PROFILE_GET_PROFILES_FAILURE = 'PROFILE_GET_PROFILES_FAILURE';

export const PROFILE_GET_CHANNEL_WHITELISTING_REQUEST = 'PROFILE_GET_CHANNEL_WHITELISTING_REQUEST';
export const PROFILE_GET_CHANNEL_WHITELISTING_SUCCESS = 'PROFILE_GET_CHANNEL_WHITELISTING_SUCCESS';
export const PROFILE_GET_CHANNEL_WHITELISTING_FAILURE = 'PROFILE_GET_CHANNEL_WHITELISTING_FAILURE';

export const PROFILE_UPDATE_CHANNEL_WHITELISTING_REQUEST = 'PROFILE_UPDATE_CHANNEL_WHITELISTING_REQUEST';
export const PROFILE_UPDATE_CHANNEL_WHITELISTING_SUCCESS = 'PROFILE_UPDATE_CHANNEL_WHITELISTING_SUCCESS';
export const PROFILE_UPDATE_CHANNEL_WHITELISTING_FAILURE = 'PROFILE_UPDATE_CHANNEL_WHITELISTING_FAILURE';

export const PROFILE_EVENT_AUDIO_CHANGE_REQUEST = 'PROFILE_EVENT_AUDIO_CHANGE_REQUEST';
export const PROFILE_EVENT_AUDIO_CHANGE_SUCCESS = 'PROFILE_EVENT_AUDIO_CHANGE_SUCCESS';
export const PROFILE_EVENT_AUDIO_CHANGE_FAILURE = 'PROFILE_EVENT_AUDIO_CHANGE_FAILURE';

export const PROFILE_EVENT_SUBTITLE_CHANGE_REQUEST = 'PROFILE_EVENT_SUBTITLE_CHANGE_REQUEST';
export const PROFILE_EVENT_SUBTITLE_CHANGE_SUCCESS = 'PROFILE_EVENT_SUBTITLE_CHANGE_SUCCESS';
export const PROFILE_EVENT_SUBTITLE_CHANGE_FAILURE = 'PROFILE_EVENT_SUBTITLE_CHANGE_FAILURE';

export const PROFILE_ADD_FAVORITE_CHANNEL_REQUEST = 'PROFILE_ADD_FAVORITE_CHANNEL_REQUEST';
export const PROFILE_ADD_FAVORITE_CHANNEL_SUCCESS = 'PROFILE_ADD_FAVORITE_CHANNEL_SUCCESS';
export const PROFILE_ADD_FAVORITE_CHANNEL_FAILURE = 'PROFILE_ADD_FAVORITE_CHANNEL_FAILURE';

export const PROFILE_REMOVE_FAVORITE_CHANNEL_REQUEST = 'PROFILE_REMOVE_FAVORITE_CHANNEL_REQUEST';
export const PROFILE_REMOVE_FAVORITE_CHANNEL_SUCCESS = 'PROFILE_REMOVE_FAVORITE_CHANNEL_SUCCESS';
export const PROFILE_REMOVE_FAVORITE_CHANNEL_FAILURE = 'PROFILE_REMOVE_FAVORITE_CHANNEL_FAILURE';

export const PROFILE_EVENT_VIDEO_QUALITY_WAS_CHANGE = 'PROFILE_EVENT_VIDEO_QUALITY_WAS_CHANGE';

export const CONFIRM_DIALOG_OPEN = 'CONFIRM_DIALOG_OPEN';
export const CONFIRM_DIALOG_CLOSE = 'CONFIRM_DIALOG_CLOSE';

export const VENDOR_GET_DATA_REQUEST = 'VENDOR_GET_DATA_REQUEST';
export const VENDOR_GET_DATA_SUCCESS = 'VENDOR_GET_DATA_SUCCESS';
export const VENDOR_GET_DATA_FAILURE = 'VENDOR_GET_DATA_FAILURE';

export const VENDOR_GET_LOCKED_CHANNEL_TEXT_REQUEST = 'VENDOR_GET_LOCKED_CHANNEL_TEXT_REQUEST';
export const VENDOR_GET_LOCKED_CHANNEL_TEXT_SUCCESS = 'VENDOR_GET_LOCKED_CHANNEL_TEXT_SUCCESS';
export const VENDOR_GET_LOCKED_CHANNEL_TEXT_FAILURE = 'VENDOR_GET_LOCKED_CHANNEL_TEXT_FAILURE';

export const VENDOR_GET_LOCKED_VOD_TEXT_REQUEST = 'VENDOR_GET_LOCKED_VOD_TEXT_REQUEST';
export const VENDOR_GET_LOCKED_VOD_TEXT_SUCCESS = 'VENDOR_GET_LOCKED_VOD_TEXT_SUCCESS';
export const VENDOR_GET_LOCKED_VOD_TEXT_FAILURE = 'VENDOR_GET_LOCKED_VOD_TEXT_FAILURE';

export const MESSAGING_UNSUBSCRIBE_TOKEN_REQUEST = 'MESSAGING_UNSUBSCRIBE_TOKEN_REQUEST';
export const MESSAGING_UNSUBSCRIBE_TOKEN_SUCCESS = 'MESSAGING_UNSUBSCRIBE_TOKEN_SUCCESS';
export const MESSAGING_UNSUBSCRIBE_TOKEN_FAILURE = 'MESSAGING_UNSUBSCRIBE_TOKEN_FAILURE';

export const FIREBASE_PUSH_MSG_RECEIVED = 'FIREBASE_PUSH_MSG_RECEIVED';
export const FIREBASE_ADD_NOTIFICATION = 'FIREBASE_ADD_NOTIFICATION';
export const FIREBASE_REMOVE_NOTIFICATION_WITH_ID = 'FIREBASE_REMOVE_NOTIFICATION_WITH_ID';
export const FIREBASE_REMOVE_ALL_NOTIFICATIONS = 'FIREBASE_REMOVE_ALL_NOTIFICATIONS';
export const FIREBASE_SAVE_TOKEN = 'FIREBASE_SAVE_TOKEN';
export const FIREBASE_REMOVE_SHORT_MESSAGE_WITH_ID = 'FIREBASE_REMOVE_SHORT_MESSAGE_WITH_ID';
export const FIREBASE_ADD_SHORT_MESSAGE = 'FIREBASE_ADD_SHORT_MESSAGE';

export const VOD_LOAD_SELECTED_VOD_REQUEST = 'VOD_LOAD_SELECTED_VOD_REQUEST';
export const VOD_LOAD_SELECTED_VOD_SUCCESS = 'VOD_LOAD_SELECTED_VOD_SUCCESS';
export const VOD_LOAD_SELECTED_VOD_FAILURE = 'VOD_LOAD_SELECTED_VOD_FAILURE';

export const RECOMMENDATION_UPDATE_MY_LIST_SUCCESS = "RECOMMENDATION_UPDATE_MY_LIST_SUCCESS"
