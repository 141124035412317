import {
  endOfDay,
  formatISO,
  getUnixTime,
  isAfter,
  isBefore,
  startOfDay
} from 'date-fns';

import { getClientConfig } from '../helpers/portal/getClientConfig';
import {
  fetch_epg_getUpdatedEventsV2,
  fetch_recommendation_getMyList,
  fetch_recording_getRecordingsByProfile,
} from '../fetchs';
import { actionTypes as types } from '../constants';
import { getExtendEpgItem } from '../helpers/epg/getExtendEpgItem';
import { includesStrLike } from '../helpers/string/includesStrLike';
import { isEqualArray } from '../helpers/array/isEqualArray';
import { isSuccessResponse } from '../helpers/fetch/isSuccessResponse';
import { post } from '../helpers';
import { rowsDataInFlatView } from '../helpers/recommendation/rowsDataInFlatView';

export const getUpdatedEvents = (param) => (dispatch) => {
  dispatch(fetch_epg_getUpdatedEventsV2(param));
};

export const epg_loadSelectedEpgWithId = (epgId) => (dispatch, getState) => {
  dispatch({type: types.EPG_LOAD_SELECTED_EPG_REQUEST});

  Promise.all([
    dispatch(fetch_epg_getUpdatedEventsV2({ids: [epgId], timestamp: 0})),
    dispatch(fetch_recording_getRecordingsByProfile()),
    dispatch(fetch_recommendation_getMyList()),
  ]).then(
    ([eventsResponse, recordingsResponse, myListResponse]) => {
      if(
        !isSuccessResponse(eventsResponse)
        || !isSuccessResponse(recordingsResponse)
        || !isSuccessResponse(myListResponse)
        || !eventsResponse.response[0]
      ) {
        dispatch({type: types.EPG_LOAD_SELECTED_EPG_FAILURE});
        return;
      }

      const epgItem = eventsResponse.response[0];

      const extendedEpgItem = getExtendEpgItem(epgItem, {
        recordingItems: rowsDataInFlatView(recordingsResponse.response),
        myListItems: myListResponse.response,
        selectedItemInState: getState().epg.selectedItem,
      });
      dispatch({type: types.EPG_LOAD_SELECTED_EPG_SUCCESS, param: {epgItem: extendedEpgItem}});
    },
    error => {
      dispatch({type: types.EPG_LOAD_SELECTED_EPG_FAILURE});
      console.error({error});
    }
  );
}

export const epg_loadEpgInfoForChannelItem = (param) => dispatch => {
  dispatch({
    type: types.EPG_LOAD_EPG_INFO_FOR_CHANNEL_ITEM_REQUEST,
    param,
  });
  post({
    url: `${getClientConfig().mwUrl}/public/epg/getUpdatedEventsV2`,
    body: { ...param },
    success: types.EPG_LOAD_EPG_INFO_FOR_CHANNEL_ITEM_SUCCESS,
    failure: types.EPG_LOAD_EPG_INFO_FOR_CHANNEL_ITEM_FAILURE,
    dispatch,
    param,
  });
};

export const epg_loadEventsForChannelAndDay = (param) => (dispatch, getState) => {
  const {selectedDate: actualSelectedDate} = param;
  const {epg: {
    selectedItem: selectedItemInState,
    searchText,
  }} = getState();

  // TODO: tady by se dalo šáhnout to cache a vytáhnout z toho epg pro tento den a pak jen obohatit.
  dispatch({ type: types.EPG_LOAD_EVENTS_FOR_DAY_AND_CHANNEL_REQUEST });

  Promise.all([
    dispatch(fetch_epg_getUpdatedEventsV2({
      timestamp: 0,
      channels: [param.channelId], // pro aktuálně hrající pořad
      from: formatISO(startOfDay(new Date(actualSelectedDate))), // a aktuální actualSelectedDate
      to: formatISO(endOfDay(new Date(actualSelectedDate))),
    })),
    dispatch(fetch_recording_getRecordingsByProfile()),
    dispatch(fetch_recommendation_getMyList()),
  ]).then(([eventsResponse, recordingsResponse, myListResponse]) => {
    if( // check if all response are allright
      !isSuccessResponse(eventsResponse)
      || !isSuccessResponse(recordingsResponse)
      || !isSuccessResponse(myListResponse)
    ) {
      dispatch({type: types.EPG_LOAD_EVENTS_FOR_DAY_AND_CHANNEL_FAILURE, param});
      console.error("EPG_LOAD_EVENTS_FOR_DAY_AND_CHANNEL_FAILURE", {param, eventsResponse, recordingsResponse, myListResponse});
      return;
    }

    const actualUsedItems = getEpgItemsWithApplyAllFilter({
      epgItems: eventsResponse.response.map(epgItem =>
        getExtendEpgItem(epgItem, {
          selectedItemInState,
          recordingItems: rowsDataInFlatView(recordingsResponse.response),
          myListItems: myListResponse.response,
        })
      ),
      searchText,
      actualSelectedDate,
      onlyForChannelId: eventsResponse?.response[0]?.channels_id || param?.channelId,
    });

    dispatch({
      type: types.EPG_LOAD_EVENTS_FOR_DAY_AND_CHANNEL_SUCCESS,
      actualUsedItems,
      actualSelectedDate,
    });
  },
  error => {
    dispatch({type: types.EPG_LOAD_EVENTS_FOR_DAY_AND_CHANNEL_FAILURE, param});
    console.error({error});
  });
};

export const epg_updateSearch = searchText => (dispatch, getState) => {
  const { epg: {
    items: epgItems,
    actualSelectedDate,
    selectedItem: selectedItemInState,
  }} = getState();
  const onlyForChannelId = selectedItemInState && selectedItemInState.channels_id;

  dispatch({
    type: types.EPG_UPDATE_SEARCH,
    actualUsedItems:
      getEpgItemsWithApplyAllFilter({epgItems, searchText, actualSelectedDate, onlyForChannelId}),
    searchText,
  });
};

const getEpgItemsWithApplyAllFilter = ({epgItems, searchText, actualSelectedDate, onlyForChannelId}) => (
  epgItems.filter(epgItem =>
    (
      !actualSelectedDate
      || (
        isAfter(new Date(epgItem.end), startOfDay(new Date(actualSelectedDate))) // jen z dneška
        && isBefore(new Date(epgItem.start), endOfDay(new Date(actualSelectedDate)))
      )
    )
    && (onlyForChannelId !== undefined && onlyForChannelId === epgItem.channels_id) // jen ze stejného kanálu
    && ( // odpovádá případnému vyhledávání
      searchText === ""
      || includesStrLike(epgItem.title, searchText)
    )
  )
  .sort((a, b) => getUnixTime(new Date(a.start)) - getUnixTime(new Date(b.start)))
);

export const epg_loadDialogEpg = (epgItem) => (dispatch, getState) => {
  dispatch({type: types.EPG_LOAD_DIALOG_REQUEST, param: {epgItem}});

  Promise.all([
    dispatch(fetch_epg_getUpdatedEventsV2({ids: [epgItem.id], timestamp: 0})),
    dispatch(fetch_recording_getRecordingsByProfile()),
    dispatch(fetch_recommendation_getMyList()),
  ]).then(
    ([eventsResponse, recordingsResponse, myListResponse]) => {
      if(!isSuccessResponse(eventsResponse) || !isSuccessResponse(recordingsResponse) || !isSuccessResponse(myListResponse)) {
        dispatch({type: types.EPG_LOAD_DIALOG_FAILURE, param: {epgItem}});
        return;
      }

      const extendedEpgItem = getExtendEpgItem(epgItem, {
        recordingItems: rowsDataInFlatView(recordingsResponse.response),
        myListItems: myListResponse.response,
        selectedItemInState: getState().epg.selectedItem,
      });

      dispatch({type: types.EPG_LOAD_DIALOG_SUCCESS, param: {epgItem: extendedEpgItem}});
    },
    error => {
      dispatch({type: types.EPG_LOAD_DIALOG_FAILURE, param: {epgItem}});
      console.error({error});
    }
  );
}


export const epg_recheckIfAllEventsHasGoodStatus = () => (dispatch, getState) => {
  const {epg: {
    actualUsedItems: epgItems,
    selectedItem: selectedEventItem,
  }} = getState();

  const newActualUsedItems = epgItems.map(epgItem =>
    getExtendEpgItem(epgItem, {
      recordingItems: getState().recording.items,
      myListItems: getState().recommendation.myList,
      selectedItemInState: getState().epg.selectedItem,
    }),
  );
  (
    !isEqualArray(epgItems, newActualUsedItems)
    && dispatch({
      type: types.EPG_RECHECK_IF_ALL_EVENT_HAS_GOOD_STATUS,
      actualUsedItems: newActualUsedItems,
    })
  );
}


export const epg_getExtendedItemInfo = (epgItem) => (dispatch) => {
  dispatch({type: types.EPG_GET_EXTENDED_ITEM_REQUEST, param: {epgItem}});
  Promise.all([
    dispatch(fetch_recording_getRecordingsByProfile()),
    dispatch(fetch_recommendation_getMyList()),
  ]).then(
    ([recordingsResponse, myListResponse]) => {
      if(!isSuccessResponse(recordingsResponse) || !isSuccessResponse(myListResponse)) {
        dispatch({type: types.EPG_GET_EXTENDED_ITEM_FAILURE, param: {epgItem}});
        return;
      }

      const extendedEpgItem = {...epgItem,
        isInMyList: myListResponse.response.some(myListItem => myListItem.id === epgItem.id),
        isRecordOn: recordingsResponse.response.reduce((acc, recordingRow) => (
          [...acc, ...recordingRow.data]
        ), [])
        .some(recItem => recItem.id === epgItem.id),
      }
      dispatch({type: types.EPG_GET_EXTENDED_ITEM_SUCCESS, param: {epgItem, extendedEpgItem}});
    },
    error => {
      dispatch({type: types.EPG_GET_EXTENDED_ITEM_FAILURE, param: {epgItem}});
      console.error({error});
    }
  );
};

export const epg_setParam = (param) => dispatch => {
  dispatch({
    type: types.EPG_SET_PARAM,
    param: param,
  });
}

export const epg_resetState = () => dispatch => {
  dispatch({
    type: types.EPG_RESET_STATE,
  });
}
