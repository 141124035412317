import { actionTypes as types, urls } from '../constants';
import { post } from '../helpers';
import { getClientConfig } from '../helpers/portal/getClientConfig';
import {
  fetch_vendor_getData,
  fetch_vendor_getLockedChannelTextImage,
  fetch_vendor_getLockedVodTextImage,
} from '../fetchs';

export const vendor_getData = (param) => async(dispatch) => {
  await fetch_vendor_getData(dispatch, param);
};

export const vendor_getLockedChannelTextImage = (param) => dispatch => {
  fetch_vendor_getLockedChannelTextImage(dispatch, param);
};

export const vendor_getLockedVodTextImage = (param) => dispatch => {
  fetch_vendor_getLockedVodTextImage(dispatch, param);
};
