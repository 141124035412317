import { getJSONCheckSum } from '../json/getJSONCheckSum';

export const getNextOrFirstElement = (selectedElement, array) => {
  // console.log("getNextOrFirstElement", {
  //   selectedElement, array,
  //   index: getIndexOfElementByCheckSum(selectedElement, array),
  // });
  const indexOfActualElement = getIndexOfElementByCheckSum(selectedElement, array);

  return (indexOfActualElement < 0) ? array[0] // nic jsme nenašli, vrátíme první objekt
  : (indexOfActualElement + 1 === array.length) ? array[0] // jsme na poslední položce, vrátíme první
  : array[indexOfActualElement + 1]; // vrátíme následující element
};

const getIndexOfElementByCheckSum = (selectedElement, array) =>
  array.findIndex(arrayItem =>
    true || isVariableJSONType(selectedElement) // je vybraný element JSON a nebo String? Nyní umíme jen JSON
      ? getJSONCheckSum(arrayItem) === getJSONCheckSum(selectedElement) // jsou JSON stejné?
      : arrayItem === selectedElement // nebo to jsou stejné string?
  );

const isVariableJSONType = (variable) => {
  try {
      return JSON.parse(variable);
  } catch (e) {
      return false;
  }
};
