import {
  fetch_recommendation_search,
  fetch_recommendation_getMyList,
  fetch_recording_getRecordingsByProfile,
} from '../fetchs';

import { actionTypes as types } from '../constants';
import { transformRecommendationResponseForReduce } from '../helpers/recommendation/transformRecommendationResponseForReduce';
import { isSuccessResponse } from '../helpers/fetch/isSuccessResponse';
import { rowsDataInFlatView } from '../helpers/recommendation/rowsDataInFlatView';

export const search_setText = (text) => dispatch => {
  dispatch({
    type: types.SEARCH_TEXT,
    text: text,
  });
};


export const search_getHp = ({searchText}) => async(dispatch, getState) => {
  dispatch({type: types.SEARCH_GET_HP_SUCCESS});

  const {
    hpRowsResponse,
    myListResponse,
    recordingsResponse,
  } = await Promise.all([
    dispatch(fetch_recommendation_search({search: searchText})),
    dispatch(fetch_recommendation_getMyList()),
    dispatch(fetch_recording_getRecordingsByProfile()),
  ]).then(
    ([hpRowsResponse, myListResponse, recordingsResponse]) => ({
      hpRowsResponse,
      myListResponse,
      recordingsResponse,
    })
  );

  if(!isSuccessResponse(hpRowsResponse)) {
    dispatch({type: types.SEARCH_GET_HP_FAILURE});
    return;
  }

  const myListItems = isSuccessResponse(myListResponse)
    ? myListResponse.response
    : getState().recommendation.myList;

  const recordingItems = isSuccessResponse(recordingsResponse)
    ? rowsDataInFlatView(recordingsResponse.response)
    : getState().recording.items;

  dispatch({
    type: types.SEARCH_GET_HP_SUCCESS,
    recommendationUpdate: transformRecommendationResponseForReduce({
      recommendationResponse: hpRowsResponse.response,
      includeData: true,
      myListItems,
      recordingItems,
      prevRecommendationRows: getState().recommendation.rows,
    }),
    myListItems,
    recordingItems,
  });
};

export const search_reset = () => dispatch => {
  dispatch({
    type: types.SEARCH_RESET,
  });
};
