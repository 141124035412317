import { v4 as uuidv4 } from "uuid";
import { actionTypes as types } from '../constants';
import { setClientStorage, removeClientStorage } from '../helpers/device/clientStorage';

const defaultState = {
  authorization: undefined,
  badResult: false,
  config: [],
  configFlatView: [],
  data: undefined,
  dataGeneration: 0,
  dataSchema: undefined,
  channelWhiteListing: [], // možná na toto udělat vlastní reducer profile?
  language: 'en',
  loaded: true,
  lostPasswordRequestTimestampSend: null,
  needRealoadProfiles: false,
  needRelogin: false,
  passwordWasChange: false,
  profiles: [],
  registrationWasSuccessSend: undefined,
  reloginLogin: undefined,
  reloginPassword: undefined,
  requestLogin: undefined,
  selectedProfile: undefined,
};

const user = (state = defaultState, action) => {
  if (action.data && action.data.status === 103) { // někdo nám změnil heslo, musíme vynutit přelogování
    removeClientStorage('authorization');
    return defaultState;
  }

  switch (action.type) {
    case types.USER_SET_PARAM:
      return { ...state, ...action.param };

    case types.USER_SET_SELECTED_PROFILE_REQUEST:
      return {...state,
        loaded: false,
      };
    case types.USER_SET_SELECTED_PROFILE_SUCCESS:
      return [{...state,
        selectedProfile: state.profiles.find(
          profile => profile.profiles_id === action.profilesId,
        ),
        loaded: true,
        isDefaultProfile: action.isDefaultProfile,
    }]
    .map(stateItem => ({...stateItem,
      selectedProfile: {...stateItem.selectedProfile,
        isDefaultProfile: (stateItem.selectedProfile?.profiles_id === stateItem.customers_profiles_id),
      }
    }))[0];
    case types.USER_SET_SELECTED_PROFILE_FAILURE:
      return {...state,
        selectedProfile: undefined,
        loaded: true,
      };

    case types.SMS_LOGIN_REQUEST:
      return {
        ...state,
        badResult: false,
        loaded: false,
        useStateDataForAutologin: false,
      };
    case types.SMS_LOGIN_SUCCESS:
      if (state.needRelogin) { // je li relogin, tak musíme aktualizovat token v client storage
        setClientStorage('authorization', action.data.response.customers_token);
      }
      setClientStorage('authorization', action.data.response.customers_token);


      return [{ ...state,
        badResult: false,
        loaded: true,
        ...action.data.response
      }]
      .map(stateItem => ({...stateItem,
        profiles: stateItem.profiles.map(profileItem => ({...profileItem,
          isDefaultProfile: (profileItem.profiles_id === stateItem.customers_profiles_id),
        })),
      }))
      .map(stateItem => ({...stateItem,
        ...(stateItem.needRelogin) // jsme po automatickém reloginu, doplníme si vybraný profil
        ? {
          selectedProfile: stateItem.profiles.find(profileItem => (
            stateItem.selectedProfile
            && profileItem.profiles_id === stateItem.selectedProfile.profiles_id
          )),
          needRelogin: false,
          reloginLogin: undefined,
          reloginPassword: undefined,
        }
        : {}
      }))[0];

    case types.CUSTOMER_LOGIN_REQUEST:
      return {...state,
        loaded: false,
      };
    case types.CUSTOMER_LOGIN_SUCCESS:
      return {...state,
        loaded: true,
        ...(action.selectedProfile) ? {selectedProfile: action.selectedProfile} : {},
        ...action.customerData,
        dataGeneration: state.dataGeneration + 1,
      };
    case types.CUSTOMER_LOGIN_FAILURE:
      return {...state,
        loaded: true,
      };



    case types.SMS_LOGIN_FAILURE:
      return { ...state, loaded: true,
        badResult: true,
        ...(state.needRelogin) // máme li automatické přihlášení a zhavaruje, tak ho invalidujeme
        ? {
          needRelogin: false,
          reloginLogin: undefined,
          reloginPassword: undefined,
        }
        : {},
      }; // TODO: dodělat info o typu chyby dle stavového kódu

    case types.SMS_LOGIN_WITH_TOKEN_REQUEST:
      return { ...defaultState, language: state.language, loaded: false };
    case types.SMS_LOGIN_WITH_TOKEN_SUCCESS:
        return { ...state,
          badResult: false, loaded: true, ...action.data.response
        };
    case types.SMS_LOGIN_WITH_TOKEN_FAILURE:
      return { ...state,
        badResult: true, loaded: true };

    case types.SMS_REQUEST_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        badResult: false,
        loaded: false,
        requestLogin: action.param.login,
        lostPasswordRequestTimestampSend: null,
      };
    case types.SMS_REQUEST_NEW_PASSWORD_SUCCESS:
      return { ...state,
        loaded: true,
        lostPasswordRequestTimestampSend: Date.now(),
      };
    case types.SMS_REQUEST_NEW_PASSWORD_FAILURE:
      return { ...state, badResult: true, loaded: true };

    case types.SMS_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        badResult: false,
        loaded: false,
        passwordWasChange: false,
      };
    case types.SMS_CHANGE_PASSWORD_SUCCESS:
      return { ...state,
        loaded: true, passwordWasChange: true
      };
    case types.SMS_CHANGE_PASSWORD_FAILURE:
      return { ...state,
        badResult: true, loaded: true
      };

    case types.CUSTOMER_GET_DATA_REQUEST:
      return { ...state, loaded: false };
    case types.CUSTOMER_GET_DATA_SUCCESS:
      return { ...state, loaded: true, ...action.data.response,
        profiles: action.data.response.profiles.map(profileItem => ({...profileItem,
          isDefaultProfile: (profileItem.profiles_id === state.customers_profiles_id),
        }))
      };
    case types.CUSTOMER_GET_DATA_FAILURE:
      return { ...state, loaded: true };


    case types.SMS__CUSTOMER__GET_CUSTOMER_CONFIG_REQUEST:
      return { ...state, loaded: false };
    case types.SMS__CUSTOMER__GET_CUSTOMER_CONFIG_SUCCESS:
      return [{ ...state, loaded: true,
        config: action.data.response.map(configField => ({...configField,
          key: uuidv4(),
        })),
      }].map(stateItem => ({...stateItem,
        configFlatView: getFlatViewFromConfig(stateItem.config),
      }))[0];
    case types.SMS__CUSTOMER__GET_CUSTOMER_CONFIG_FAILURE:
      return { ...state, loaded: true };

    case types.SMS_CUSTOMER_UPDATE_CONFIG:
      return [{...state,
        config: state.config.map(configField => ({...configField,
          fields: configField.fields.map(fieldItem => ({...fieldItem,
            ...(fieldItem.key === action.key)
              ? {value: action.value}
              : {},
          })),
        })),
      }].map(stateItem => ({...stateItem,
        configFlatView: getFlatViewFromConfig(stateItem.config),
      }))[0];

    case types.SMS__CUSTOMER__GET_DATA_REQUEST:
      return { ...state, loaded: false };
    case types.SMS__CUSTOMER__GET_DATA_SUCCESS:
      return { ...state, loaded: true, data: action.data.response };

    case types.SMS__CUSTOMER__UPDATE_REQUEST:
      return { ...state, loaded: false };
    case types.SMS__CUSTOMER__UPDATE_SUCCESS:
      console.log("update custak", action.param)
      return { ...state,
        loaded: true,
        device_motv_social_registration_completed: 1,
        ...(action.param.data.devices && action.param.data.devices[0].device_motv_password)
          ? {
            reloginLogin: action.param.data.devices[0].device_motv_login,
            reloginPassword: action.param.data.devices[0].device_motv_password,
            needRelogin: true,
          }
          : {},
      };
    case types.SMS__CUSTOMER__UPDATE_FAILURE:
      return { ...state, loaded: true };

    case types.SMS__CUSTOMER__REGISTER_REQUEST:
      return { ...state, loaded: false,
        warningMessage: null,
      };
    case types.SMS__CUSTOMER__REGISTER_SUCCESS:
      return { ...state, loaded: true,
        registrationWasSuccessSend: true,
      };
    case types.SMS__CUSTOMER__REGISTER_FAILURE:
      return { ...state, loaded: true };

    case types.SMS__DEVICES__API_REGISTER_REQUEST:
      return { ...state, loaded: false };
    case types.SMS__DEVICES__API_REGISTER_SUCCESS:
      return { ...state,
        loaded: true,
        useStateDataForAutologin: !!action.data.response.device_motv_viewers_id, // vrátilo se nám, že je registrovaný, použijeme data na přihlášení
        registrationWasSuccessSend: true,
        warningMessage: null,
      };
    case types.SMS__DEVICES__API_REGISTER_FAILURE:
      return { ...state,
        loaded: true, // FIXME: dát na danou stránku error box
        warningMessage: // TODO: tohle přebírat z helperu getValuesForMWErrorCode
            action.data.status === 104 ? action.data.response
          : action.data.status === 14000 ? 'Login is used'
          : null,
      };

    case types.PROFILE_UPDATE_REQUEST:
      return { ...state,
        loaded: false,
        profiles:
          (action.param.profilesId)
          ? state.profiles.map(profileItem => (profileItem.profiles_id === action.param.profilesId) // stavající profil
              ? {...profileItem, ...action.param.data}
              : {...profileItem}
            )
          : [...state.profiles,  // nový profil
            {
              profiles_id: uuidv4(),
              itIsNewProfileInCreateProcess: true,
              ...action.param.data
            }
          ],
      };
    case types.PROFILE_UPDATE_SUCCESS:
      return { ...state, loaded: true, needRealoadProfiles: true,
        profiles: state.profiles.map(profileItem => ({...profileItem,
          ...(profileItem.itIsNewProfileInCreateProcess) ? { // jestli máme nějaký zrovna čerstvě založený profil tak mu dáme správné ID
            profiles_id: action.data.response,
            itIsNewProfileInCreateProcess: undefined,
          } : {}
        }))
      };
    case types.PROFILE_UPDATE_FAILURE:
      return { ...state, loaded: true };

    case types.PROFILE_DELETE_PROFILE_REQUEST:
      return { ...state, loaded: false,
        profiles: state.profiles.filter(profileItem => profileItem.profiles_id !== action.param.profilesId)
      };
    case types.PROFILE_DELETE_PROFILE_SUCCESS:
      return { ...state, needRealoadProfiles: true, loaded: true };
    case types.PROFILE_GET_PROFILES_REQUEST:
      return { ...state, loaded: false, needRealoadProfiles: false };
    case types.PROFILE_GET_PROFILES_SUCCESS:
      return [{ ...state,
        loaded: true,
        profiles: action.data.response.map(profileItem => ({...profileItem,
          isDefaultProfile: (profileItem.profiles_id === state.customers_profiles_id),
        })),
      }].map(stateItem => ({...stateItem,
        selectedProfile: stateItem.profiles.find(profile => profile.profiles_id === state.selectedProfile.profiles_id),
      }))[0];
    case types.PROFILE_GET_PROFILES_FAILURE:
      return { ...state,
        loaded: true,
        // profiles: [state.selectedProfile], // divnostav, zobrazím jen sám sebe
      };
    case types.PROFILE_GET_CHANNEL_WHITELISTING_REQUEST:
      return { ...state, loaded: false };
    case types.PROFILE_GET_CHANNEL_WHITELISTING_FAILURE:
      return {
        ...state,
        loaded: true,
        // channelWhiteListing: undefined,
      };
    case types.PROFILE_GET_CHANNEL_WHITELISTING_SUCCESS:
      return {
        ...state,
        loaded: true,
        channelWhiteListing: action.data.response,
      };

    case types.CUSTOMER_RESET_SCHEME_AND_DATA:
      return { ...state, dataSchema: undefined, data: undefined };

    case types.SMS_FORCE_CHANGE_PASSWORD_REQUEST:
      return { ...state, loaded: false };
    case types.SMS_FORCE_CHANGE_PASSWORD_SUCCESS:
      return { ...defaultState,
        loaded: true,
        customers_password_changed: state.customers_password_changed + 1,
        needRelogin: true,
        reloginLogin: state.device_motv_login,
        reloginPassword: action.param.password,
      };
    case types.SMS_FORCE_CHANGE_PASSWORD_FAILURE:
      return { ...state, loaded: true };

    case types.RECORDING_ADD_SUCCESS:
      return {...state,
        customers_recording_used: state.customers_recording_used + parseInt(
          (
            (+new Date(action.param.eventItem.end)) - (+new Date (action.param.eventItem.start))
          ) / (60 * 1000)
          , 10),
      };
    case types.RECORDING_REMOVE_SUCCESS:
      return {...state,
        customers_recording_used: state.customers_recording_used - parseInt(
          (
            (+new Date(action.param.eventItem.end)) - (+new Date (action.param.eventItem.start))
          ) / (60 * 1000)
          , 10),
      };

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
    return { ...defaultState, language: state.language };

    case types.RESET_STATE:
      return { ...defaultState, language: state.language };
    case types.USER_RESET_STATE:
      return {
        ...state,
        ...defaultState,
        authorization: state.authorization,
        profiles: state.profiles,
        profilesId: state.profilesId,
      };
    default:
      return state;
  }
};

const getFlatViewFromConfig = (config) => {
  return config.reduce((accumulator, fieldsetItem) => ([...accumulator,
    ...fieldsetItem.fields,
  ]), []);

};


export default user;
