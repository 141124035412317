export const FETCH_SMS_CUSTOMER_LOGIN_FAILURE = 'FETCH_SMS_CUSTOMER_LOGIN_FAILURE';
export const FETCH_SMS_CUSTOMER_LOGIN_REQUEST = 'FETCH_SMS_CUSTOMER_LOGIN_REQUEST';
export const FETCH_SMS_CUSTOMER_LOGIN_SUCCESS = 'FETCH_SMS_CUSTOMER_LOGIN_SUCCESS';

export const FETCH_SMS_CUSTOMER_LOGIN_GOOGLE_FAILURE = 'FETCH_SMS_CUSTOMER_LOGIN_GOOGLE_FAILURE';
export const FETCH_SMS_CUSTOMER_LOGIN_GOOGLE_REQUEST = 'FETCH_SMS_CUSTOMER_LOGIN_GOOGLE_REQUEST';
export const FETCH_SMS_CUSTOMER_LOGIN_GOOGLE_SUCCESS = 'FETCH_SMS_CUSTOMER_LOGIN_GOOGLE_SUCCESS';

export const FETCH_SMS_CUSTOMER_LOGIN_FACEBOOK_FAILURE = 'FETCH_SMS_CUSTOMER_LOGIN_FACEBOOK_FAILURE';
export const FETCH_SMS_CUSTOMER_LOGIN_FACEBOOK_REQUEST = 'FETCH_SMS_CUSTOMER_LOGIN_FACEBOOK_REQUEST';
export const FETCH_SMS_CUSTOMER_LOGIN_FACEBOOK_SUCCESS = 'FETCH_SMS_CUSTOMER_LOGIN_FACEBOOK_SUCCESS';

export const FETCH_SMS_CUSTOMER_LOGIN_APPLE_FAILURE = 'FETCH_SMS_CUSTOMER_LOGIN_APPLE_FAILURE';
export const FETCH_SMS_CUSTOMER_LOGIN_APPLE_REQUEST = 'FETCH_SMS_CUSTOMER_LOGIN_APPLE_REQUEST';
export const FETCH_SMS_CUSTOMER_LOGIN_APPLE_SUCCESS = 'FETCH_SMS_CUSTOMER_LOGIN_APPLE_SUCCESS';
export const FETCH_SMS_CUSTOMER_REGISTER_APPLE_FAILURE = 'FETCH_SMS_CUSTOMER_REGISTER_APPLE_FAILURE';
export const FETCH_SMS_CUSTOMER_REGISTER_APPLE_REQUEST = 'FETCH_SMS_CUSTOMER_REGISTER_APPLE_REQUEST';
export const FETCH_SMS_CUSTOMER_REGISTER_APPLE_SUCCESS = 'FETCH_SMS_CUSTOMER_REGISTER_APPLE_SUCCESS';
