import {TV_CONTENT_TYPE} from "../../constants/contentTypes";
import {TV_ASSET_TYPE} from "../../constants/assetTypes";
import {getUnixTime} from "date-fns";

export const getPlayerUrl = (item) => {
  if (item.type === TV_ASSET_TYPE || item.type === TV_CONTENT_TYPE) {
    return(
      (item.isNowPlaying)
        ? (`/${item.type}/player/${item.channels_id}`)
        : (`/${item.type}/player/${item.channels_id}/${getUnixTime(new Date(item.start || item.start))}`)
      )
  } else {
    return(
      (`/${item.type}/player/${item.id}`)
    )
  }

};
