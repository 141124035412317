import { actionTypes as types } from '../constants';
import { concatAndDeDuplicateObjects } from '../helpers/array/concatAndDeDuplicateObjects';

const defaultState = {
  dialogRec: undefined,
  dialogRecIsLoaded: true,
  dialogRecIsOpen: false,
  items: [],
  loaded: true,
  recordIsAddingRemove: false,
  selectedItem: undefined,
};

const recording = (
  state = defaultState,
  action,
) => {
  switch (action.type) {
    case types.RECORDING_GET_STREAM_URL_REQUEST:
      return {...state,
        loaded: false,
        selectedItem: state.items.find(recItem => recItem.id === action.param.epgEventsId)
      };
    case types.RECORDING_GET_STREAM_URL_SUCCESS:
      return {...state,
        loaded: true,
      };
    case types.RECORDING_GET_STREAM_URL_FAILURE:
      return {...state,
        loaded: true,
      };

    case types.RECORDING_LOAD_PLAYER_SUCCESS:
      return {...state,
        loaded: true,
        selectedItem: action.dataForReduce.selectedRecordingItem,
      };

    case types.RECORDING_GET_DATA_REQUEST:
      return {...state,
        loaded: false,
      };
    case types.RECORDING_GET_DATA_SUCCESS:
      return {...state,
        loaded: true,
        selectedItem: action.data.response,
      };
    case types.RECORDING_GET_DATA_FAILURE:
      return {...state,
        loaded: true,
        selectedItem: undefined,
      };

    case types.SEARCH_GET_HP_SUCCESS:
      return {...state,
        items: action.recordingItems,
    };

    case types.RECORDING_ADD_REQUEST:
      return {...state,
        loaded: false,
        recordIsAddingRemove: true,
      };
    case types.RECORDING_ADD_SUCCESS:
      return {...state,
        items: concatAndDeDuplicateObjects('recordings_id', state.items, [action.data.response]),
        loaded: true,
        recordIsAddingRemove: false,
      };
    case types.RECORDING_ADD_FAILURE:
    return {...state,
      loaded: true,
      recordIsAddingRemove: false,
    };


    case types.RECORDING_REMOVE_REQUEST:
      return {...state,
        loaded: false,
        recordIsAddingRemove: true,
      };
    case types.RECORDING_REMOVE_SUCCESS:
      return {...state,
        loaded: true,
        recordIsAddingRemove: false,
        items: state.items.filter(item => item.recordings_id !== action.param.epgEventsId),
      };
    case types.RECORDING_REMOVE_FAILURE:
      return {...state,
        loaded: true,
        recordIsAddingRemove: false,
      };

    case types.RECORDING_DIALOG_WITH_BOX_ITEM_REQUEST:
      return {...state,
        loaded: false,
        ...action.recordingUpdate,
      };
    case types.RECORDING_DIALOG_WITH_BOX_ITEM_SUCCESS:
      return {...state,
        loaded: true,
        ...action.recordingUpdate,
      };
    case types.RECORDING_DIALOG_WITH_BOX_ITEM_FAILURE:
      return {...state,
        loaded: true,
      };

    case types.RECORDING_SET_PARAM:
      return { ...state, ...action.param };

    case types.RECOMMENDATION_HOMEPAGE_SUCCESS:
      return {...state,
        items: action.recordingItems,
      };

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;

    case types.RESET_STATE:
      return defaultState;
    default:
      return state;
  }
};

export default recording;
