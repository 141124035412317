export const dayStringOptions = { weekday: 'short' };
export const fullDateOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};
export const fullDateWithTimeOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};
export const hourTimeOptions = {
  hour: 'numeric',
  minute: 'numeric',
};
export const shortDateOptions = {
  month: 'short',
  day: 'numeric',
};
export const shortDateWithTimeOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};
export const shortDateWithTimeFromToOptions = {
  optionsFrom: {
    month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',
  },
  optionsTo: {
    hour: 'numeric', minute: 'numeric',
  },
};
export const yearStringOptions = {
  year: 'numeric',
};
