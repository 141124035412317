import { actionTypes as types, urls } from '../constants';
import { post } from '../helpers';


export const confirmDialog_open = (param) => dispatch => {
  dispatch({
    type: types.CONFIRM_DIALOG_OPEN,
    param: param,
  });
}

export const confirmDialog_close = () => dispatch => {
  dispatch({type: types.CONFIRM_DIALOG_CLOSE});
}
