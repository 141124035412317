import { actionTypes as types } from '../constants';
import { getValuesForMWErrorCode } from '../helpers/getValuesForMWErrorCode';
import { getValuesForSMSErrorCode } from '../helpers/getValuesForSMSErrorCode';

import { v4 as uuidv4 } from "uuid";

const defaultState = {
  items: [], // blokující error
  flashItems: [], // error který zobrazíme klientovi, ale není blokující pro provoz
  tryReloadFromMWErrorIsActive: false,
  useOutageImage: false,
  redirectToHp: false,
  inlineFormItems: {},
};

const error = (state = defaultState, action) => {
  const actionData = action.data;
  const pathname = action?.param?.pathname;

  switch (action.type) {
    case types.ERROR_DELETE_ITEM:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.id),
      }
    case types.ERROR_DELETE_ALL:
      return defaultState;

    case types.ERROR_ADD_ITEM:
      return { ...state,
        items: saveExpandErrorItems(state.items, action.item),
        useOutageImage: (action.item.useOutageImage) ? true : state.useOutageImage,
      }
    case types.ERROR_SET_PARAM:
      return {...state, ...action.param}

    case types.ERROR_DELETE_FLASH_ITEM:
      return {
        ...state,
        flashItems: state.flashItems.filter(item => item.id !== action.id),
      }


    case types.SMS__CUSTOMER__UPDATE_REQUEST:
      return {...state, items: []};


    /**
     * Specific case for error posible
     */
    case types.CHANNELS_DEFAULT_CHANNEL_SUCCESS:
      return {
        ...state,
        items:
          !Array.isArray(action.data.response) ||
          action.data.response.length === 0
            ? [ ...state.items,
                {
                  id:  uuidv4(),
                  title: 'live.no_channels_heading',
                  content: 'live.no_channels_text',
                  canBeDelete: false,
                  buttons: [
                    {
                      title: 'error.btn_go_to_subscriptions_page',
                      url: '/settings/subscriptions',
                    },
                  ],
                },
              ]
            : state.items,
        useOutageImage: false,
      }

    case types.PLAYER_FULLPLAYER_INIT_FAILURE:
      return {...state,
        items: saveExpandErrorItems(state.items,
          action.error === "label_no_channels_purchased" ?
            {
              id:  uuidv4(),
              title: "label_no_channels_purchased",
              canBeDelete: false,
            }
          : action.error === "label_no_radios_purchased" ?
            {
              id:  uuidv4(),
              title: "label_no_radios_purchased",
              canBeDelete: false,
            }
          : {
              id: uuidv4(),
              canBeDelete: false,
              ...getValuesForMWErrorCode(action?.data?.status || 0)
          }),
      }
    case types.PLAYER_FULLPLAYER_INIT_SUCCESS:
      return {...state, items: []}

    case types.PLAYER_FULLPLAYER_GET_CHANNEL_FAILURE:
      return {...state,
        items: saveExpandErrorItems(state.items,
          {
              id: uuidv4(),
              canBeDelete: false,
              ...getValuesForMWErrorCode(action?.data?.status || 0)
          }),
      }

    case types.VOD_GET_STREAM_URL_FAILURE:
      return {...state,
        items: saveExpandErrorItems(state.items,
          {
              id: uuidv4(),
              canBeDelete: false,
              ...getValuesForMWErrorCode(action?.data?.status || 0)
          }),
      }

    case types.RECORDING_GET_STREAM_URL_FAILURE: // recording load error
      return {...state,
        items: saveExpandErrorItems(state.items,
          {
              id: uuidv4(),
              canBeDelete: false,
              ...getValuesForMWErrorCode(action?.data?.status || 0)
          }),
      }

    case types.VENDOR_GET_LOCKED_VOD_TEXT_FAILURE: // recording load error
      return {...state,
        items: saveExpandErrorItems(state.items,
          {
              id: uuidv4(),
              canBeDelete: false,
              ...getValuesForMWErrorCode(action?.data?.status || 0)
          }),
      }

    case types.PLAYER_FULLPLAYER_INIT_SUCCESS:
      return { ...state, items: [] };


    case types.PLAYER_LOAD_LIVE_CHANNEL_IN_PLAYER_SUCCESS:
      return { ...state, items: [] };

    case types.PLAYER_LOAD_ONLY_SELECTED_EVENT_SUCCESS:
      return { ...state, items: [] };


    case types.CHANNELS_STREAM_URL_FAILURE: return newStateForMWFailure(state, actionData);
    case types.CHANNELS_STREAM_URL_SUCCESS:
      return { ...state, items: [] };


    case types.VOD_GET_STREAM_URL_SUCCESS:
      return { ...state,
        items: [],
        tryReloadFromMWErrorIsActive: false,
        useOutageImage: false,
      }
    case types.VOD_GET_DATA_FAILURE: return newStateForMWFailure(state, actionData);

    case types.RECORDING_GET_STREAM_URL_SUCCESS:
      return { ...state,
        items: [],
        useOutageImage: false,
      }

    case types.SMS_FORCE_CHANGE_PASSWORD_FAILURE: return newStateForSMSFailure(state, actionData, pathname);
    case types.SMS_LOGIN_FAILURE: return newStateForSMSFailure(state, actionData);

    case types.SMS__DEVICES__API__CONFIRM_REGISTRATION__FAILURE:
      return newStateForSMSFailure(state, actionData)

    case types.SMS__CUSTOMER__UPDATE_FAILURE: return newStateForSMSFailure(state, actionData, pathname);

    case types.SMS__DEVICES__API_REGISTER_FAILURE: return newStateForSMSFailure(state, actionData, pathname);


    case types.SEARCH_RESET: return {...state, items: []};
    case types.RECOMMENDATION_SEARCH_REQUEST: return {...state, items: []};
    case types.RECOMMENDATION_SEARCH_FAILURE: return newStateForMWFailure(state, actionData);

    case types.CUSTOMER_LOGIN_REQUEST:
      return { ...state, items: [] }
    case types.CUSTOMER_LOGIN_FAILURE:
      return { ...state,
        items: saveExpandErrorItems(state.items,
          {
              id: uuidv4(),
              canBeDelete: false,
              ...getValuesForSMSErrorCode(action?.data?.status || 0)
          }),
      };

    case types.SMS_LOGIN_WITH_TOKEN_FAILURE:
      return {...state,
        items: saveExpandErrorItems(state.items,
          {
              id: uuidv4(),
              canBeDelete: false,
              ...getValuesForSMSErrorCode(action?.data?.status || 0)
          }),
        redirectToHp: true,
      }

    case types.RECORDING_ADD_FAILURE:
      return {...state,
        flashItems: saveExpandErrorItems(state.flashItems,
          {
            ...getValuesForMWErrorCode(action.data.status),
            autoremove: true,
          }
        ),
      }

    case types.SMS__CUSTOMER__REGISTER_REQUEST:
      return {...state, inlineFormItems: [], items: []};
    case types.SMS__CUSTOMER__REGISTER_FAILURE:
      return {...state,
        ...typeof action.data.response !== "string"
        ? {inlineFormItems: action.data.response}
        : {items: [{
          id: uuidv4(),
          canBeDelete: false,
          statusCode: action.data.status,
          title: action.data.response,
          defaultMessage: action.data.response,
        }]},
      }

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_FAILURE:
      return {...state,
        items: [{
          id: uuidv4(),
          canBeDelete: false,
          statusCode: action.data.status,
          title: "message_provider_not_found",
          defaultMessage: action.data.response,
        }],
      };

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;

    case types.RESET_STATE:
      return defaultState;
    case types.ERROR_RESET_STATE:
      return defaultState

    default:
      return state;
  }
}

const newStateForMWFailure = (state, actionData) => ([{ ...state,
  ...(actionData)
    ? {items: saveExpandErrorItems(state.items, getValuesForMWErrorCode(actionData.status))}
    : {},
}].map(stateItem => ({...stateItem,
  useOutageImage: (stateItem.items.find(errorItem => errorItem.useOutageImage)) ? true : false,
}))[0]);

const newStateForSMSFailure = (state, actionData, pathname) => ({ ...state,
  ...(actionData)
    ? {items: saveExpandErrorItems(state.items, getValuesForSMSErrorCode(actionData.status, pathname))}
    : {},
});


const saveExpandErrorItems = (items, errorItem) => items.reduce(
  (
    res,
    dataItem, // we added only unique error item
  ) =>
    res.find(
      elementToAdd =>
        elementToAdd.title === dataItem.title &&
        elementToAdd.content === dataItem.content,
    )
      ? res
      : [...res, dataItem],
  [
    {
      id: + new Date(),
      canBeDelete: false,
      ...errorItem,
    },
  ],
);


export default error
