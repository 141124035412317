import { actionTypes as types } from '../constants';
import { v4 as uuidv4 } from "uuid";
import formatISO from 'date-fns/formatISO'

import { customer_logout } from './customer';
import { getEpgItemPlayerUrl } from '../helpers/epg/getEpgItemPlayerUrl';
import { URL_CACHE_NAME } from '../constants/smallPieces';

export const firebase_pushMsgReceived = ({msgData, history}) => dispatch => {
  if( // Notifikace z MW
    msgData?.fcmOptions?.analyticsLabel === "Notification"
    && msgData.notification
  ) {
    dispatch(firebase_addNotification({msgData}));
  } else if ( // On video message - dočasná zpráva která se zobrazí když se koukám na přehrávač?
    msgData?.fcmOptions?.analyticsLabel === "On_video_message"
    && msgData?.data?.message
  ) {
    dispatch({
      type: types.FIREBASE_ADD_SHORT_MESSAGE,
      shortMessageItem: {
        created: formatISO(new Date()),
        id: uuidv4(),
        message: msgData.data.message,
      },
    });
  } else if ( // Customer logout - pokyn abych uživatele odhlásil
    msgData?.fcmOptions?.analyticsLabel === "Customer_logout"
  ) {
    dispatch(customer_logout());
  } else if ( // Channel Switch - přepnu uživatele na tv s daným kanálem ať je v portále kdekoliv
    msgData?.fcmOptions?.analyticsLabel === "Channel_switch"
    && msgData?.data?.channelsId
  ) {
    const channelId = parseInt(msgData.data.channelsId, 10);
    if(!channelId) return;
      window.location = getEpgItemPlayerUrl({ // FIXME: využít history.push a ne refresh celé stránky
        channels_id: channelId,
        isNowPlaying: true,
      }, "tv");

  } else if ( // Cleare device cache - Na to udělat vyčištění mezipaměti url cache a refresh stránky, neodhlašovat
    msgData?.fcmOptions?.analyticsLabel === "Clear_device_cache"
  ) {
    // TODO: v budoucnu možná i separátní pročištění reduce?
    localStorage.removeItem(URL_CACHE_NAME);
    location.reload();
  } else if ( // notifikace z firebase console
    msgData.notification
    && msgData.notification.title
    && msgData.notification.body
  ) {
    dispatch(firebase_addNotification({msgData}));
  } else { /* ... */ }

}

const firebase_addNotification = ({msgData}) => dispatch => {
  dispatch({
    type: types.FIREBASE_ADD_NOTIFICATION,
    notificationItem: {...msgData.notification,
      created: formatISO(new Date()),
      id: uuidv4(),
      ...(msgData.notification?.titleLocArgs) ? {
        titleLocArgs: getTranslateJsonParamsFromArray(msgData.notification.titleLocArgs)
      } : {},
      ...(msgData.notification?.subtitleLocArgs) ? {
        subtitleLocArgs: getTranslateJsonParamsFromArray(msgData.notification.subtitleLocArgs)
      } : {},
    },
  });
}

const getTranslateJsonParamsFromArray = (array) => array
  .map((arrayItem, index) => ({
    index: index + 1,
    value: arrayItem,
  }))
  .reduce((acc, arrayItem) => ({...acc,
    ...(array.length === 1)
      ? {s: arrayItem.value}
      : {[`s${arrayItem.index}`]: arrayItem.value}
  }), {});

export const firebase_deleteShortMessageWithId = id => dispatch => {
  dispatch({
    type: types.FIREBASE_REMOVE_SHORT_MESSAGE_WITH_ID,
    id,
  });
}


export const firebase_deleteNotificationWithId = id => dispatch => {
  dispatch({
    type: types.FIREBASE_REMOVE_NOTIFICATION_WITH_ID,
    id,
  });
}

export const firebase_deleteAllNotifications = () => dispatch => {
  dispatch({
    type: types.FIREBASE_REMOVE_ALL_NOTIFICATIONS,
  });
}
