import { getRecItemUrl } from "./getRecItemUrl";
import { getPlayerUrl } from "./getPlayerUrl";
import { removeEpgEventsPrefix } from "../epg/removeEpgEventsPrefix";
import { isAfter } from "date-fns/esm";

export const getExtendedRecItem = (recItem, {
    // place for another props like myList, etc...
} = {}) => {
    return [{...recItem,
        ...removeEpgEventsPrefix(recItem),
    }].map(recStateItem => ({...recStateItem,
        url: getRecItemUrl(recStateItem),
        playerUrl: getPlayerUrl(recStateItem),
        playable: recStateItem.end && isAfter(new Date(), new Date(recStateItem.end)),
    }))[0];
};