import { fetchTypes } from '../constants';
import { getClientConfig } from '../helpers/portal/getClientConfig';
import { post } from '../helpers';
import { actionTypes as types } from '../constants';

export const fetch_channel_getStreamUrl = (dispatch, param) => post({
  url: `${getClientConfig().mwUrl}/public/channel/getStreamUrlV3`,
  body: { ...param },
  request: types.CHANNELS_STREAM_URL_REQUEST,
  success: types.CHANNELS_STREAM_URL_SUCCESS,
  failure: types.CHANNELS_STREAM_URL_FAILURE,
  dispatch,
  param,
});

export const fetch_channel_getSubscribedAndLockedChannels = (dispatch, param) => post({
  url: `${getClientConfig().mwUrl}/public/channel/getSubscribedAndLockedChannels`,
  body: { ...param },
  success: types.PLAYER_FULLPLAYER_GET_CHANNEL_SUCCESS,
  failure: types.PLAYER_FULLPLAYER_GET_CHANNEL_FAILURE,
  dispatch,
});

export const fetch_channel_getData = (dispatch, param) => post({
  url: `${getClientConfig().mwUrl}/public/channel/getData`,
  body: { ...param },
  success: types.PLAYER_FULLPLAYER_GET_CHANNEL_SUCCESS,
  failure: types.PLAYER_FULLPLAYER_GET_CHANNEL_FAILURE,
  dispatch,
});

export const fetch_channel_getDefaultChannel = (dispatch, param, headers = {}) => post({
  url: `${getClientConfig().mwUrl}/public/channel/getSubscribedChannels`,
  body: { ...param },
  headers,
  request: types.CHANNELS_DEFAULT_CHANNEL_REQUEST,
  success: types.CHANNELS_DEFAULT_CHANNEL_SUCCESS,
  failure: types.CHANNELS_DEFAULT_CHANNEL_FAILURE,
  param: param,
  dispatch,
});

export const fetch_channel_getStartupChannel = (dispatch, param, headers = {}) => post({
  url: `${getClientConfig().mwUrl}/public/channel/getStartupChannel`,
  body: { ...param },
  headers,
  request: types.CHANNELS_GET_STARTUP_CHANNEL_REQUEST,
  success: types.CHANNELS_GET_STARTUP_CHANNEL_SUCCESS,
  failure: types.CHANNELS_GET_STARTUP_CHANNEL_FAILURE,
  param: param,
  dispatch,
});



export const fetch_channel_getSubscribedChannels = (dispatch, param, headers = {}) => post({
  url: `${getClientConfig().mwUrl}/public/channel/getSubscribedChannels`,
  body: { ...param },
  headers,
  request: fetchTypes.FETCH_CHANNEL_GET_SUBSCRIBED_CHANNELS_REQUEST,
  success: fetchTypes.FETCH_CHANNEL_GET_SUBSCRIBED_CHANNELS_SUCCESS,
  failure: fetchTypes.FETCH_CHANNEL_GET_SUBSCRIBED_CHANNELS_FAILURE,
  param,
  dispatch,
});
