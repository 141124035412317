import { getClientConfig } from '../helpers/portal/getClientConfig';
import { post } from '../helpers';
import { actionTypes as types } from '../constants';


export const fetch_device_getData = (dispatch, param) => post({
    url: `${getClientConfig().mwUrl}/public/device/getData`,
    body: { ...param },
    request: types.DEVICE_GET_DATA_REQUEST,
    success: types.DEVICE_GET_DATA_SUCCESS,
    failure: types.DEVICE_GET_DATA_FAILURE,
    dispatch,
    param,
});

export const fetch_device_getDevice = (dispatch, param) => post({
    url: `${getClientConfig().mwUrl}/public/device/getDevices`,
    body: { ...param },
    request: types.DEVICE_GET_DEVICES_REQUEST,
    success: types.DEVICE_GET_DEVICES_SUCCESS,
    failure: types.DEVICE_GET_DEVICES_FAILURE,
    dispatch,
    param,
});

export const fetch_device_removeDevice = (dispatch, param) => post({
    url: `${getClientConfig().mwUrl}/public/device/removeDevice`,
    body: { ...param },
    request: types.DEVICE_REMOVE_DEVICE_REQUEST,
    success: types.DEVICE_REMOVE_DEVICE_SUCCESS,
    failure: types.DEVICE_REMOVE_DEVICE_FAILURE,
    dispatch,
    param,
});
