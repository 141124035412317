import 'array-flat-polyfill';

import {
  addHours,
  addMinutes,
  formatISO,
  getHours,
  getUnixTime,
  isAfter,
  isBefore,
  startOfHour
} from 'date-fns';

import {
  fetch_channel_getSubscribedAndLockedChannels,
  fetch_recording_getRecordingsByProfile, fetch_epg_getUpdatedEventsV2,
} from '../fetchs';
import { actionTypes as types } from '../constants';
import { getExtendEpgItem } from '../helpers/epg/getExtendEpgItem';
import { isSameOrAfter } from '../helpers/datetime';
import { isSuccessResponse } from '../helpers/fetch/isSuccessResponse';
import { removeEpgEventsPrefix } from '../helpers/epg/removeEpgEventsPrefix';

export const tvGuide_loadFletDesing = (param) => async(dispatch, getState) => {
  dispatch({
    type: types.TV_GUIDE_FLET_REQUEST,
  });

  const dateCursorInReduceState = getState()?.tvGuide?.dateCursor;
  const selectedChannelCategoryInReduceState = getState()?.channel?.selectedCategory;

  const preferedTime = param?.preferedTime || dateCursorInReduceState || formatISO(new Date());

  const dateCursor = formatISO(startOfHour(new Date(preferedTime)));
  const isOdd = getHours(new Date(dateCursor)) % 2 === 0;
  const minusHour = isOdd ? 1 : 0; // když je lichá hodina, odebereme hodinu, u sudé nic

  const hourColumns = [
    {
      from: formatISO(addHours(new Date(dateCursor), -2 - minusHour)),
      to: formatISO(addHours(new Date(dateCursor), 0 - minusHour)),
      isFirst: true,
    },
    {
      from: formatISO(addHours(new Date(dateCursor), 0 - minusHour)),
      to: formatISO(addHours(new Date(dateCursor), 2 - minusHour)),
    },
    {
      from: formatISO(addHours(new Date(dateCursor), 2 - minusHour)),
      to: formatISO(addHours(new Date(dateCursor), 4 - minusHour)),
      isLast: true,
    },
  ];


  const dateFrom = formatISO(addHours(new Date(dateCursor), -2 - minusHour));
  const dateTo = formatISO(addHours(new Date(dateCursor), 4 - minusHour));


  const [channelData, recordingData] = await Promise.all([
    fetch_channel_getSubscribedAndLockedChannels(dispatch),
    dispatch(fetch_recording_getRecordingsByProfile()),
  ]);
  const channelItems = channelData.response;
  const recordingItems = recordingData.response.map(recommendationItem => (
    recommendationItem.data
  )).flat();


  const epgData = await dispatch(fetch_epg_getUpdatedEventsV2({
    from: dateFrom,
    to: dateTo,
    timestamp: 0,
  }));
  const epgItems = epgData.response;

  const actualChannelsWithEpg = channelItems.map(channelItem => ({...channelItem,
    epgItems: [...epgItems.filter(epgItem =>
      epgItem.channels_id === channelItem.channels_id // bereme jen ty co jsou ze stejného kanálu
    )]
  }))
  .map(channelItem => ({...channelItem,
    hourColumns: hourColumns.map(hourItem => ({...hourItem,
      epgItems: channelItem.epgItems.filter(epgItem => (
        ( // pro běžné sloupce je pro nás prioritní, že pořad začíná v daný čas
          isSameOrAfter(new Date(epgItem.start), new Date(hourItem.from))
          && isBefore(new Date(epgItem.start), new Date(hourItem.to))
        )
      ))
      .map(epgItem => ({...epgItem,
        isInRecording: recordingItems.find(recItem => recItem.id === epgItem.id) !== undefined,
        isStartingInFuture: isAfter(new Date(epgItem.start), new Date()),
      }))
    }))
  }));

  dispatch({
    type: types.TV_GUIDE_FLET_SUCCESS,
    fletDesingItems: actualChannelsWithEpg,
    fletDesingItemsWithFilters: filterChannelsWithActualCategory(actualChannelsWithEpg, selectedChannelCategoryInReduceState),
    hourColumns,
    dateCursor,
  });
};


export const tvGuide_RollingDesing = ({
  dateFromInIso,
  showMinutes = 120,
  rowStartIndex = 0,
  rowShowItems = 10,
}) => async(dispatch, getState) => {

  dispatch({
    type: types.TV_GUIDE_ROLLING_REQUEST,
  });

  const dateToInIso = addMinutes(new Date(dateFromInIso), showMinutes);

  const [channelData, recordingData] = await Promise.all([
    fetch_channel_getSubscribedAndLockedChannels(dispatch),
    dispatch(fetch_recording_getRecordingsByProfile()),
  ]);
  if(!isSuccessResponse(channelData) || !isSuccessResponse(recordingData)) { dispatch({type: types.TV_GUIDE_ROLLING_FAILURE}); return; }

  const channelsCount = channelData.response.length;

  const channelItems = channelData.response
    .filter((channelItem, rowOrderIndex) =>
      rowOrderIndex >= rowStartIndex
      && rowOrderIndex < rowStartIndex + rowShowItems
    );
  const recordingItems = recordingData.response.map(recommendationItem => (
    recommendationItem.data
  )).flat();

  const ids = channelItems.reduce((accumulator, channelItem) => ([...accumulator,
      channelItem.channels_id
    ]), []);

  const epgData = await dispatch(fetch_epg_getUpdatedEventsV2({
    from: dateFromInIso,
    to: dateToInIso,
    timestamp: 0,
    channels: ids
  }));
  if(!isSuccessResponse(epgData)) { dispatch({type: types.TV_GUIDE_ROLLING_FAILURE}); return; }

  const epgItems = epgData.response
    .filter(epgItem => (
        isAfter(new Date(epgItem.end), new Date(dateFromInIso))
        && isBefore(new Date(epgItem.start), new Date(dateToInIso))
      )
  );
  const rollingDesignItems = [channelItems.map(channelItem => ({...channelItem,
    epgItems:
      epgItems
        .filter(epgItem => epgItem.channels_id === channelItem.channels_id)
        .map(epgItem => ({...epgItem,
          lengthInMinutes: [parseInt(
            (getUnixTime(new Date(epgItem.end)) - getUnixTime(new Date(epgItem.start))) / 60
          , 10)]
          .map(lengthItem => // případné zkrácení pořadů které začali před naším pohledem
            isBefore(new Date(epgItem.start), new Date(dateFromInIso))
            ? lengthItem - (parseInt(
              (getUnixTime(new Date(dateFromInIso)) - getUnixTime(new Date(epgItem.start))) / 60
              , 10
            ))
            : lengthItem
          )
          .map(lengthItem => // případné zkrácení pořadů které končí za naším pohledem
            isAfter(new Date(epgItem.end), new Date(dateToInIso))
            ? lengthItem - (parseInt(
              (getUnixTime(new Date(epgItem.end)) - getUnixTime(new Date(dateToInIso))) / 60
              , 10
            ))
            : lengthItem
          )[0],
          isInRecording: recordingItems.find(recItem => recItem.id === epgItem.id) !== undefined,
        }))
        .map(epgItem => ({...epgItem,
          type: "TV",
          ...removeEpgEventsPrefix(epgItem),
        }))
        .map(epgItem => getExtendEpgItem(epgItem, {
          recordingItems: getState().recording.items,
          myListItems: getState().recommendation.myList,
          selectedItemInState: getState().epg.selectedItem,
        }))
      }))]
      .map(rollingDesignItemsState => rollingDesignItemsState.map(
        (channelItem, channelItemIndex) => ({...channelItem,
          epgItems: channelItem.epgItems.map((epgItem, epgItemIndex) => ({...epgItem,
            isFirstColumn: epgItemIndex === 0,
            isLastColumn: epgItemIndex === channelItem.epgItems.length - 1,
            isFirstRow: channelItemIndex === 0,
            isLastRow: channelItemIndex === rollingDesignItemsState.length - 1,
          })),
        })
      ))[0];

  dispatch({
    type: types.TV_GUIDE_ROLLING_SUCCESS,
    rollingDesignItems,
    channelsCount,
  })



}

export const filterChannelsWithActualCategory = (channels, filterCategory) =>
  channels.filter(channelItem => (
    !filterCategory
    || channelItem.channels_categories.some(
        categorieItemId => categorieItemId === filterCategory?.channels_categories_id
    )
  ))
