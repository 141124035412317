import { getCategoryItemUrl } from "./getCategoryItemUrl";
import * as boxItemTypes from "../../constants/boxItemTypes";

export const getExtendCategoryItem = (
  categoryItem,
  { myListItems = [] } = {}
) => {
  if (!categoryItem || typeof categoryItem !== "object") return undefined;
  return [
    {
      ...categoryItem,
      url: getCategoryItemUrl(categoryItem),
      isInMyList: myListItems?.some(
        (listItem) =>
          listItem.type === categoryItem.type && listItem.id === categoryItem.id
      ),
      follow: categoryItem.follow
        ? {
            ...categoryItem.follow,
            isInMyList: myListItems?.some(
              (listItem) =>
                listItem.type === categoryItem.follow.type &&
                listItem.id === categoryItem.follow.id
            ),
          }
        : {},
    },
  ].map((categoryStateItem) => ({
    ...categoryStateItem,
    isInMyList:
      categoryStateItem.isInMyList ||
      categoryStateItem.follow.isInMyList ||
      false,
  }))[0];
};
