


export const getValuesForMWErrorCode = (statusCode) => ([
    statusCode === 0 ? {defaultMessage: "Unknown error, please try again later"}
  : statusCode === 902 ? {defaultMessage: "Unfortunately, this event is not available."}
  : statusCode === 903 ? {
      defaultMessage: "Unfortunately, you are not authorized to view this event.",
      hasLockIcon: true,
    }
  : statusCode === 910 ? {defaultMessage: "Unfortunately, this event is unavailable to you due to the event's maturity rating."}
  : statusCode === 911 ? {defaultMessage: "Unfortunately, this event is not available in the country you are currently in."}
  : statusCode === 914 ? {defaultMessage: "This content is not available within your network."}
  : statusCode === 1001 ? {defaultMessage: "Unfortunately, this VOD is not available."}
  : statusCode === 1500 ? {
      defaultMessage: "Unfortunately, there are no free devices available in your account, please consider removing some.",
      killActualEdgeItem: false,
  }
  : statusCode === 1502 ? {
    defaultMessage: "Maximum of concurrent devices exceeded. Please turn off other application using this account and retry.",
    killActualEdgeItem: false,
  }
  : statusCode === 2605 ? {defaultMessage: "We are having issues getting the license for playback, please try again later."}
  : statusCode === 2606 ? {defaultMessage: "We are having issues getting the license for playback, please try again later."}
  : {defaultMessage: "Ups, we have trouble."}
].map(errorCode => ({
  title: `mw_exception_${statusCode}`,
  killActualEdgeItem: true,
  useOutageImage: (statusCode === 902 || statusCode === 1001),
  statusCode,
  ...errorCode,
}))[0]);
