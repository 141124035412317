export const SMS_LOGIN_REQUEST = 'SMS_LOGIN_REQUEST';
export const SMS_LOGIN_SUCCESS = 'SMS_LOGIN_SUCCESS';
export const SMS_LOGIN_FAILURE = 'SMS_LOGIN_FAILURE';

export const SMS_LOGIN_WITH_TOKEN_REQUEST = 'SMS_LOGIN_WITH_TOKEN_REQUEST';
export const SMS_LOGIN_WITH_TOKEN_SUCCESS = 'SMS_LOGIN_WITH_TOKEN_SUCCESS';
export const SMS_LOGIN_WITH_TOKEN_FAILURE = 'SMS_LOGIN_WITH_TOKEN_FAILURE';

export const SMS__DEVICES__GET_PORTAL_REQUEST = 'SMS__DEVICES__GET_PORTAL_REQUEST';
export const SMS__DEVICES__GET_PORTAL_SUCCESS = 'SMS__DEVICES__GET_PORTAL_SUCCESS';
export const SMS__DEVICES__GET_PORTAL_FAILURE = 'SMS__DEVICES__GET_PORTAL_FAILURE';

export const SMS__DEVICES__API_REGISTER_REQUEST = 'SMS__DEVICES__API_REGISTER_REQUEST';
export const SMS__DEVICES__API_REGISTER_SUCCESS = 'SMS__DEVICES__API_REGISTER_SUCCESS';
export const SMS__DEVICES__API_REGISTER_FAILURE = 'SMS__DEVICES__API_REGISTER_FAILURE';

export const SMS__DEVICES__API__CONFIRM_REGISTRATION__REQUEST = 'SMS__DEVICES__API__CONFIRM_REGISTRATION__REQUEST';
export const SMS__DEVICES__API__CONFIRM_REGISTRATION__SUCCESS = 'SMS__DEVICES__API__CONFIRM_REGISTRATION__SUCCESS';
export const SMS__DEVICES__API__CONFIRM_REGISTRATION__FAILURE = 'SMS__DEVICES__API__CONFIRM_REGISTRATION__FAILURE';

export const SMS__DEVICES__GET_PORTAL_PAGE_BY_NAME_REQUEST = 'SMS__DEVICES__GET_PORTAL_PAGE_BY_NAME_REQUEST';
export const SMS__DEVICES__GET_PORTAL_PAGE_BY_NAME_SUCCESS = 'SMS__DEVICES__GET_PORTAL_PAGE_BY_NAME_SUCCESS';
export const SMS__DEVICES__GET_PORTAL_PAGE_BY_NAME_FAILURE = 'SMS__DEVICES__GET_PORTAL_PAGE_BY_NAME_FAILURE';

export const SMS__CUSTOMER__GET_DATA_REQUEST = 'SMS__CUSTOMER__GET_DATA_REQUEST';
export const SMS__CUSTOMER__GET_DATA_SUCCESS = 'SMS__CUSTOMER__GET_DATA_SUCCESS';
export const SMS__CUSTOMER__GET_DATA_FAILURE = 'SMS__CUSTOMER__GET_DATA_FAILURE';

export const SMS__CUSTOMER__UPDATE_REQUEST = 'SMS__CUSTOMER__UPDATE_REQUEST';
export const SMS__CUSTOMER__UPDATE_SUCCESS = 'SMS__CUSTOMER__UPDATE_SUCCESS';
export const SMS__CUSTOMER__UPDATE_FAILURE = 'SMS__CUSTOMER__UPDATE_FAILURE';

export const SMS__CUSTOMER__REGISTER_REQUEST = 'SMS__CUSTOMER__REGISTER_REQUEST';
export const SMS__CUSTOMER__REGISTER_SUCCESS = 'SMS__CUSTOMER__REGISTER_SUCCESS';
export const SMS__CUSTOMER__REGISTER_FAILURE = 'SMS__CUSTOMER__REGISTER_FAILURE';

export const SMS__CUSTOMER__GET_CUSTOMER_CONFIG_REQUEST = 'SMS__CUSTOMER__GET_CUSTOMER_CONFIG_REQUEST';
export const SMS__CUSTOMER__GET_CUSTOMER_CONFIG_SUCCESS = 'SMS__CUSTOMER__GET_CUSTOMER_CONFIG_SUCCESS';
export const SMS__CUSTOMER__GET_CUSTOMER_CONFIG_FAILURE = 'SMS__CUSTOMER__GET_CUSTOMER_CONFIG_FAILURE';

export const SMS_CUSTOMER_UPDATE_CONFIG = 'SMS_CUSTOMER_UPDATE_CONFIG';

export const SMS__SUBSCRITION__GET_CUSTOMER_SUBSCRITION_INFO_REQUEST = 'SMS__SUBSCRITION__GET_CUSTOMER_SUBSCRITION_INFO_REQUEST';
export const SMS__SUBSCRITION__GET_CUSTOMER_SUBSCRITION_INFO_SUCCESS = 'SMS__SUBSCRITION__GET_CUSTOMER_SUBSCRITION_INFO_SUCCESS';
export const SMS__SUBSCRITION__GET_CUSTOMER_SUBSCRITION_INFO_FAILURE = 'SMS__SUBSCRITION__GET_CUSTOMER_SUBSCRITION_INFO_FAILURE';

export const SMS_REQUEST_NEW_PASSWORD_REQUEST = 'SMS_REQUEST_NEW_PASSWORD_REQUEST';
export const SMS_REQUEST_NEW_PASSWORD_SUCCESS = 'SMS_REQUEST_NEW_PASSWORD_SUCCESS';
export const SMS_REQUEST_NEW_PASSWORD_FAILURE = 'SMS_REQUEST_NEW_PASSWORD_FAILURE';

export const SMS_CHANGE_PASSWORD_REQUEST = 'SMS_CHANGE_PASSWORD_REQUEST';
export const SMS_CHANGE_PASSWORD_SUCCESS = 'SMS_CHANGE_PASSWORD_SUCCESS';
export const SMS_CHANGE_PASSWORD_FAILURE = 'SMS_CHANGE_PASSWORD_FAILURE';

export const SMS_FORCE_CHANGE_PASSWORD_REQUEST = 'SMS_FORCE_CHANGE_PASSWORD_REQUEST';
export const SMS_FORCE_CHANGE_PASSWORD_SUCCESS = 'SMS_FORCE_CHANGE_PASSWORD_SUCCESS';
export const SMS_FORCE_CHANGE_PASSWORD_FAILURE = 'SMS_FORCE_CHANGE_PASSWORD_FAILURE';
