import { combineReducers } from 'redux';

import confirmDialog from './confirmDialog';
import customer from './customer';
import device from './device';
import edge from './edge';
import epg from './epg';
import error from './error';
import firebase from './firebase';
import channel from './channel';
import network from './network';
import person from './person';
import player from './player';
import portal from './portal';
import recommendation from './recommendation';
import recording from './recording';
import search from './search';
import subscription from './subscription';
import tvGuide from './tvGuide';
import user from './user';
import universal from './universal';
import vendor from './vendor';
import vod from './vod';

import cicoSubscriptions from '../client-extensions/cico/reducers/subscriptions';

const rootReducer = combineReducers({
  cicoSubscriptions,
  confirmDialog,
  customer,
  device,
  edge,
  epg,
  error,
  firebase,
  channel,
  network,
  person,
  player,
  portal,
  recommendation,
  recording,
  search,
  subscription,
  tvGuide,
  user,
  universal,
  vendor,
  vod,
});

export default rootReducer;
