export const FETCH_RECOMMENDATION_HOMEPAGE_REQUEST = 'FETCH_RECOMMENDATION_HOMEPAGE_REQUEST';
export const FETCH_RECOMMENDATION_HOMEPAGE_SUCCESS = 'FETCH_RECOMMENDATION_HOMEPAGE_SUCCESS';
export const FETCH_RECOMMENDATION_HOMEPAGE_FAILURE = 'FETCH_RECOMMENDATION_HOMEPAGE_FAILURE';

export const FETCH_RECOMMENDATION_HOMEPAGE_ROW_REQUEST = 'FETCH_RECOMMENDATION_HOMEPAGE_ROW_REQUEST';
export const FETCH_RECOMMENDATION_HOMEPAGE_ROW_SUCCESS = 'FETCH_RECOMMENDATION_HOMEPAGE_ROW_SUCCESS';
export const FETCH_RECOMMENDATION_HOMEPAGE_ROW_FAILURE = 'FETCH_RECOMMENDATION_HOMEPAGE_ROW_FAILURE';

export const FETCH_RECOMMENDATION_GET_MY_LIST_REQUEST = 'FETCH_RECOMMENDATION_GET_MY_LIST_REQUEST';
export const FETCH_RECOMMENDATION_GET_MY_LIST_SUCCESS = 'FETCH_RECOMMENDATION_GET_MY_LIST_SUCCESS';
export const FETCH_RECOMMENDATION_GET_MY_LIST_FAILURE = 'FETCH_RECOMMENDATION_GET_MY_LIST_FAILURE';

export const FETCH_RECOMMENDATION_SEARCH_REQUEST = 'FETCH_RECOMMENDATION_SEARCH_REQUEST';
export const FETCH_RECOMMENDATION_SEARCH_SUCCESS = 'FETCH_RECOMMENDATION_SEARCH_SUCCESS';
export const FETCH_RECOMMENDATION_SEARCH_FAILURE = 'FETCH_RECOMMENDATION_SEARCH_FAILURE';

export const FETCH_RECOMMENDATION_GET_CATEGORY_REQUEST = 'FETCH_RECOMMENDATION_GET_CATEGORY_REQUEST';
export const FETCH_RECOMMENDATION_GET_CATEGORY_SUCCESS = 'FETCH_RECOMMENDATION_GET_CATEGORY_SUCCESS';
export const FETCH_RECOMMENDATION_GET_CATEGORY_FAILURE = 'FETCH_RECOMMENDATION_GET_CATEGORY_FAILURE';

export const FETCH_RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_REQUEST = 'FETCH_RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_REQUEST';
export const FETCH_RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_SUCCESS = 'FETCH_RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_SUCCESS';
export const FETCH_RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_FAILURE = 'FETCH_RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_FAILURE';
