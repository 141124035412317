export const VOD_GET_LIBRARY_HP_REQUEST = 'VOD_GET_LIBRARY_HP_REQUEST';
export const VOD_GET_LIBRARY_HP_SUCCESS = 'VOD_GET_LIBRARY_HP_SUCCESS';
export const VOD_GET_LIBRARY_HP_FAILURE = 'VOD_GET_LIBRARY_HP_FAILURE';

export const VOD_GET_LIBRARY_HP_ROW_ITEM_REQUEST = 'VOD_GET_LIBRARY_HP_ROW_ITEM_REQUEST';
export const VOD_GET_LIBRARY_HP_ROW_ITEM_SUCCESS = 'VOD_GET_LIBRARY_HP_ROW_ITEM_SUCCESS';
export const VOD_GET_LIBRARY_HP_ROW_ITEM_FAILURE = 'VOD_GET_LIBRARY_HP_ROW_ITEM_FAILURE';

export const VOD_GET_DATA_REQUEST = 'VOD_GET_DATA_REQUEST';
export const VOD_GET_DATA_SUCCESS = 'VOD_GET_DATA_SUCCESS';
export const VOD_GET_DATA_FAILURE = 'VOD_GET_DATA_FAILURE';

export const VOD_GET_DATA_V2_REQUEST = 'VOD_GET_DATA_V2_REQUEST';
export const VOD_GET_DATA_V2_SUCCESS = 'VOD_GET_DATA_V2_SUCCESS';
export const VOD_GET_DATA_V2_FAILURE = 'VOD_GET_DATA_V2_FAILURE';

export const VOD_LOAD_DIALOG_REQUEST = 'VOD_LOAD_DIALOG_REQUEST';
export const VOD_LOAD_DIALOG_SUCCESS = 'VOD_LOAD_DIALOG_SUCCESS';
export const VOD_LOAD_DIALOG_FAILURE = 'VOD_LOAD_DIALOG_FAILURE';

export const VOD_GET_STREAM_URL_REQUEST = 'VOD_GET_STREAM_URL_REQUEST';
export const VOD_GET_STREAM_URL_SUCCESS = 'VOD_GET_STREAM_URL_SUCCESS';
export const VOD_GET_STREAM_URL_FAILURE = 'VOD_GET_STREAM_URL_FAILURE';

export const VOD_LOAD_PLAYER_REQUEST = 'VOD_LOAD_PLAYER_REQUEST';
export const VOD_LOAD_PLAYER_SUCCESS = 'VOD_LOAD_PLAYER_SUCCESS';
export const VOD_LOAD_PLAYER_FAILURE = 'VOD_LOAD_PLAYER_FAILURE';

export const VOD_LOAD_YOUTUBE_DIALOG = "VOD_LOAD_YOUTUBE_DIALOG"
export const VOD_UNLOAD_YOUTUBE_DIALOG = "VOD_UNLOAD_YOUTUBE_DIALOG"

export const VOD_SET_PARAM = 'VOD_SET_PARAM';
