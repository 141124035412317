import { actionTypes as types, fetchTypes } from '../constants';
import { getClientConfig } from '../helpers/portal/getClientConfig';
import { post } from '../helpers';

export const fetch_vod_getVodHomepageRows = param => dispatch => post({
  url: `${getClientConfig().mwUrl}/public/vod/getVodHomepageRows`,
  body: { ...param },
  request: fetchTypes.FETCH_VOD_GET_HOMEPAGE_ROWS_REQUEST,
  success: fetchTypes.FETCH_VOD_GET_HOMEPAGE_ROWS_SUCCESS,
  failure: fetchTypes.FETCH_VOD_GET_HOMEPAGE_ROWS_FAILURE,
  param,
  dispatch,
});

export const fetch_vod_getVodHomepageRowItem = param => dispatch => post({
  url: `${getClientConfig().mwUrl}/public/vod/getVodHomepageRow`,
  body: { ...param },
  request: fetchTypes.FETCH_VOD_GET_HOMEPAGE_ROW_ITEM_REQUEST,
  success: fetchTypes.FETCH_VOD_GET_HOMEPAGE_ROW_ITEM_SUCCESS,
  failure: fetchTypes.FETCH_VOD_GET_HOMEPAGE_ROW_ITEM_FAILURE,
  param,
  dispatch,
});

export const fetch_vod_getDataV2 = param => dispatch => post({
  url: `${getClientConfig().mwUrl}/public/vod/getDataV2`,
  noAuth: false,
  body: param,
  request: types.VOD_GET_DATA_V2_REQUEST,
  success: types.VOD_GET_DATA_V2_SUCCESS,
  failure: types.VOD_GET_DATA_V2_FAILURE,
  dispatch,
});

export const fetch_vod_getStreamUrl = param => dispatch => post({
  url: `${getClientConfig().mwUrl}/public/vod/getStreamUrlV3`,
  body: param,
  request: types.VOD_GET_STREAM_URL_REQUEST,
  success: types.VOD_GET_STREAM_URL_SUCCESS,
  failure: types.VOD_GET_STREAM_URL_FAILURE,
  dispatch,
});
