import { formatISO } from 'date-fns';

import { actionTypes as types } from '../constants';
import { convertToTimeCode } from '../helpers/datetime';
import { getClientConfig } from '../helpers/portal/getClientConfig';
import { getEdgesForStreamUrl } from '../helpers/player/getEdgesForStreamUrl';
import { insertNewBoxItemsToRecommendation } from '../helpers/recommendation/insertNewBoxItemsToRecommendation';
import { isSuccessResponse } from '../helpers/fetch/isSuccessResponse';
import { post } from '../helpers';
import { removeRowWithIdFromRecommendationState } from '../helpers/recommendation/removeRowWithIdFromRecommendationState';
import { transformRecommendationResponseForReduce } from '../helpers/recommendation/transformRecommendationResponseForReduce';
import { vendor_getLockedVodTextImage } from './';

import {
  fetch_vod_getDataV2,
  fetch_vod_getStreamUrl,
  fetch_vod_getVodHomepageRowItem,
  fetch_vod_getVodHomepageRows,
} from '../fetchs';

export const vod_getLibraryHp = ({
  includeData = false,
} = {}) => async (dispatch, getState) => {
  dispatch({type: types.VOD_GET_LIBRARY_HP_REQUEST});

  const {
    hpRowsResponse,
  } = await Promise.all([
    dispatch(fetch_vod_getVodHomepageRows()),
  ]).then(
    ([hpRowsResponse]) => ({
      hpRowsResponse,
    })
  );

  if(!isSuccessResponse(hpRowsResponse)) {
    dispatch({type: types.VOD_GET_LIBRARY_HP_FAILURE});
    return;
  }

  dispatch({
    type: types.VOD_GET_LIBRARY_HP_SUCCESS,
    recommendationUpdate: transformRecommendationResponseForReduce({
      recommendationResponse: hpRowsResponse.response,
      includeData,
      prevRecommendationRows: getState().recommendation.rows,
    }),
  });
};

export const vod_getLibraryHpRow = ({
  insertToRowWithId,
} = {}) => async (dispatch, getState) => {
  dispatch({type: types.VOD_GET_LIBRARY_HP_ROW_ITEM_REQUEST});

  const {
    hpRowItemResponse,
  } = await Promise.all([
    dispatch(fetch_vod_getVodHomepageRowItem({
      genresId: insertToRowWithId,
      all: true
    })),
  ]).then(
    ([hpRowItemResponse]) => ({
      hpRowItemResponse,
    })
  );

  if(!isSuccessResponse(hpRowItemResponse)) {
    dispatch({
      recommendationUpdate: removeRowWithIdFromRecommendationState(insertToRowWithId, getState().recommendation),
      type: types.VOD_GET_LIBRARY_HP_ROW_ITEM_FAILURE,
    });
    return;
  }

  dispatch({
    type: types.VOD_GET_LIBRARY_HP_ROW_ITEM_SUCCESS,
    recommendationUpdate:
      hpRowItemResponse.response.data.length > 0
        ? insertNewBoxItemsToRecommendation({
          boxItems: hpRowItemResponse.response.data,
          insertToRowWithId,
          recommendationPrevState: getState().recommendation,
        })
        : removeRowWithIdFromRecommendationState(insertToRowWithId, getState().recommendation),
  });

};

export const vod_setSelectedVod = selectedVod => dispatch => {
  dispatch({
    type: types.SET_SELECTED_VOD,
    selectedVod: selectedVod,
  });
};

export const vod_setParam = param => dispatch => {
  dispatch({
    type: types.VOD_SET_PARAM,
    param: param,
  });
};

export const setDialogVod = param => dispatch => {
  dispatch({
    type: types.SET_DIALOG_VOD,
    param: param,
  });
};

export const vod_loadDialogVod = vodItem => dispatch => {
  dispatch({
    type: types.VOD_LOAD_DIALOG_REQUEST,
    vodItem: vodItem,
  });
  post({
    url: `${getClientConfig().mwUrl}/public/vod/getDataV2`,
    body: { vodsId: vodItem.id },
    success: types.VOD_LOAD_DIALOG_SUCCESS,
    failure: types.VOD_LOAD_DIALOG_FAILURE,
    dispatch,
  });
};

export const getVodDataForDialog = vodsId => dispatch => {
  dispatch({ type: types.GET_VOD_DATA_FOR_DIALOG_REQUEST })
  post({
    url: `${getClientConfig().mwUrl}/public/vod/getDataV2`,
    body: { vodsId: vodsId },
    success: types.GET_VOD_DATA_FOR_DIALOG_SUCCESS,
    failure: types.GET_VOD_DATA_FOR_DIALOG_FAILURE,
    dispatch,
  });
};



export const vod_loadPlayer = param => async (dispatch, getState) => {
  dispatch({ type: types.VOD_LOAD_PLAYER_REQUEST });

  const { vodsId } = param;
  const {edge: {items: edgeItems}} = getState();

  const vodData = await dispatch(fetch_vod_getDataV2(param));
  const vodUrlData = await dispatch(fetch_vod_getStreamUrl({
    type: 'dash',
    vodsId: vodsId,
    ...getEdgesForStreamUrl(edgeItems),
  }));

  const vodItem = isSuccessResponse(vodData) && vodData?.response;
  const vodUrlItem = isSuccessResponse(vodUrlData) && vodUrlData?.response;

  if(!vodItem || !vodUrlItem) {
    dispatch({ type: types.VOD_LOAD_PLAYER_FAILURE });
    if (vodUrlItem.status === 1001) {
      dispatch(vendor_getLockedVodTextImage({vodsId}));
    }
  }

  dispatch({
    type: types.VOD_LOAD_PLAYER_SUCCESS,
    dataForReduce: {
      audio: vodUrlItem.audio,
      drms: vodUrlItem.drms,
      adVmap: vodUrlItem.adVmap,
      dateOfResponseStream: formatISO(new Date()),
      duration: vodItem.duration,
      edgesId: vodUrlItem.edgesId,
      follow: vodUrlItem.follow,
      poster: vodItem.image,
      readAbleVideoActualPosition: convertToTimeCode(0),
      readAbleVideoDuration: convertToTimeCode(vodItem.duration),
      remoteChannelsUnicastId: vodUrlItem.remoteChannelsUnicastId,
      selectedVod: vodItem,
      startTime: 0,
      subtitle: vodUrlItem.subtitle,
      takeRealDurationFromVideoElement: true,
      thumbnails: vodUrlItem.thumbnails,
      videoProfiles: vodUrlItem.videoProfiles,
      url: vodUrlItem.url,
    },
  });
}

export const vod_leavePlayer = () => dispatch => {
  dispatch({type: types.VOD_LEAVE_PLAYER});
}

export const vod_loadYoutubeDialog = (boxItem) => (dispatch) => {
  dispatch({type: types.VOD_LOAD_YOUTUBE_DIALOG, param: {boxItem}});
}

export const vod_unloadYoutubeDialog = (dispatch) => {
  dispatch({type: types.VOD_UNLOAD_YOUTUBE_DIALOG})
}
