import { actionTypes as types } from '../constants';
import { concatAndDeDuplicateObjects } from '../helpers/array/concatAndDeDuplicateObjects';

const defaultState = {
  actualCategoryId: null,
  actualCategory: undefined,
  categories: [],
  loaded: true,
  dialogVod: undefined,
  dialogVodId: undefined,
  dialogVodIsOpen: false,
  dialogVodIsLoaded: true,
  selectedVod: undefined,
  vods: [],
  youtubeDialog: undefined,
  youtubeDialogIsOpen: false,
};

const vod = (state = defaultState, action) => {
  switch (action.type) {
    case types.VOD_SET_PARAM:
      return { ...state, ...action.param };

    // case types.RECOMMENDATION_GET_CATEGORY_REQUEST:
    //   return {...state,
    //     loaded: false,
    //     actualCategory: state.categories.find(catItem => catItem.categories_id === action.param.categoriesId),
    //   };
    // case types.RECOMMENDATION_GET_CATEGORY_SUCCESS:
    //   const actualCategory = action.data.response.category;
    //   const actualCategoryWithFixAttr = {...actualCategory,
    //     categories_description:
    //       (actualCategory.categories_description && actualCategory.categories_description !== "")
    //         ? actualCategory.categories_description
    //       : (actualCategory.follow && actualCategory.follow.description && actualCategory.follow.description !== "")
    //         ? actualCategory.follow.description
    //       : null
    //   };
    //   return {...state,
    //     loaded: true,
    //     actualCategory: actualCategoryWithFixAttr,
    //     categories: concatAndDeDuplicateObjects(
    //       "categories_id",
    //       [actualCategoryWithFixAttr],
    //       state.categories
    //     ),
    //   };
    // case types.RECOMMENDATION_GET_CATEGORY_FAILURE:
    //   return { ...state,
    //     loaded: false,
    //   };

    case types.VOD_GET_DATA_REQUEST:
      return { ...state,
        // selectedVod: undefined,
        loaded: false,
      };
    case types.VOD_GET_DATA_SUCCESS:

      return { ...state,
        selectedVod: action.data.response,
        loaded: true,
        vods: concatAndDeDuplicateObjects(
          'vods_id',
          state.vods,
          action.data.response,
        ),
        // TODO plus přidat i do seznamu vods, když tam není
      };
    case types.VOD_GET_DATA_FAILURE:
      return { ...state,
        loaded: true,
      };

    case types.VOD_LOAD_SELECTED_VOD_REQUEST:
      return { ...state,
        loaded: false,
      };
    case types.VOD_LOAD_SELECTED_VOD_SUCCESS:
      return { ...state,
        selectedVod: action.selectedVod,
        loaded: true,
        vods: concatAndDeDuplicateObjects(
          'vods_id',
          state.vods,
          action.selectedVod,
        ),
      };
    case types.VOD_LOAD_SELECTED_VOD_FAILURE:
      return { ...state,
        loaded: true,
      };

    case types.VOD_LOAD_DIALOG_REQUEST:
      return { ...state,
        dialogVod: action.vodItem,
        dialogVodIsOpen: true,
        dialogVodIsLoaded: false,
      };
    case types.VOD_LOAD_DIALOG_SUCCESS:
      return { ...state,
        dialogVod: action.data.response,
        dialogVodIsLoaded: true,
      };
    case types.VOD_LOAD_DIALOG_FAILURE:
      return { ...state,
        dialogVodIsLoaded: false,
      };

    case types.GET_VOD_DATA_FOR_DIALOG_REQUEST:
      return { ...state,
        loaded: false,
      };
    case types.GET_VOD_DATA_FOR_DIALOG_SUCCESS:
      return {
        ...state,
        loaded: true,
        vods: concatAndDeDuplicateObjects(
          'vods_id',
          state.vods,
          action.data.response,
        ),
        // TODO plus přidat i do seznamu vods, když tam není
      };
    case types.GET_VOD_DATA_FOR_DIALOG_FAILURE:
      return { ...state,
        loaded: false,
      };

    case types.SET_SELECTED_VOD:
      return {
        ...state,
        selectedVod: action.selectedVod, // set selected vod
        vods: concatAndDeDuplicateObjects(
          'vods_id',
          state.vods,
          action.selectedVod,
        ), // and add to cache
      };
    case types.SET_DIALOG_VOD:
      return { ...state, ...action.param };

    case types.CATEGORY_GET_CATEGORY_WITH_MY_LIST_REQUEST:
      return { ...state,
        loaded: false,
        actualCategory: {...state.actualCategory, loaded: false}
      };
    case types.CATEGORY_GET_CATEGORY_WITH_MY_LIST_SUCCESS:
      return { ...state,
        loaded: true,
        actualCategory: {...action.actualCategory, loaded: true},
      };
    case types.CATEGORY_GET_CATEGORY_WITH_MY_LIST_FAILURE:
      return { ...state,
        loaded: true,
        actualCategory: undefined,
      };

    case types.RECOMMENDATION_ADD_TO_MY_LIST_REQUEST:
      return {...state,
        actualCategory: {...state.actualCategory, loaded: false}
      };
    case types.RECOMMENDATION_ADD_TO_MY_LIST_SUCCESS:
      return {...state,
        actualCategory: {...state.actualCategory, loaded: true, isInMyList: true}
      };
    case types.RECOMMENDATION_ADD_TO_MY_LIST_FAILURE:
      return {...state,
        actualCategory: {...state.actualCategory, loaded: true}
      };

    case types.RECOMMENDATION_REMOVE_FROM_MY_LIST_REQUEST:
      return {...state,
        actualCategory: {...state.actualCategory, loaded: false}
      };
    case types.RECOMMENDATION_REMOVE_FROM_MY_LIST_SUCCESS:
      return {...state,
        actualCategory: {...state.actualCategory, loaded: true, isInMyList: false}
      };
    case types.RECOMMENDATION_REMOVE_FROM_MY_LIST_FAILURE:
      return {...state,
        actualCategory: {...state.actualCategory, loaded: true}
      };

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;

    case types.RESET_STATE:
      return defaultState;

    case types.VOD_LEAVE_PLAYER:
      return {...state, ...defaultState};

    case types.VOD_LOAD_YOUTUBE_DIALOG:
      return {...state,
        youtubeDialog: {...action.param.boxItem, loaded: true},
        youtubeDialogIsOpen: true
      }

   case types.VOD_UNLOAD_YOUTUBE_DIALOG:
      return {...state,
        youtubeDialog: undefined,
        youtubeDialogIsOpen: false
      }

    default:
      return state;
  }
};

export default vod;
