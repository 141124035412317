import { actionTypes as types } from '../constants';
import { concatAndDeDuplicateObjects } from '../helpers/array/concatAndDeDuplicateObjects';

const defaultState = {
  contentText: "",
  isOpen: false,
  // toto nefunguje, v reduce může být jen čistý json
  handleDisagree: undefined,
  handleAgree: undefined,
  handleClose: undefined,
};

const confirmDialog = (
  state = defaultState,
  action,
) => {
  switch (action.type) {
    case types.CONFIRM_DIALOG_OPEN:
      return {...state,
        isOpen: true,
        ...action.param,
      }
    case types.CONFIRM_DIALOG_CLOSE:
      return defaultState

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;
      
    case types.RESET_STATE:
      return defaultState;
    default:
      return state;
  }
}

export default confirmDialog;
