import { actionTypes as types } from '../constants';
import { concatAndDeDuplicateObjects } from '../helpers/array/concatAndDeDuplicateObjects';

const defaultState = {
  items: [],
  loaded: true,
  needReloadItems: false,
};

const device = (
  state = defaultState,
  action,
) => {
  switch (action.type) {
    case types.DEVICE_GET_DEVICES_REQUEST:
      return {...state,
        ...action.param,
        loaded: false,
      };
    case types.DEVICE_GET_DEVICES_SUCCESS:
      return {...state,
        loaded: true,
        items: action.data.response,
        needReloadItems: false,
      };
    case types.DEVICE_GET_DEVICES_FAILURE:
      return {...state,
        loaded: true,
      };


    case types.DEVICE_REMOVE_DEVICE_REQUEST:
      return {...state,
        loaded: false,
        items: state.items.filter(deviceItem => deviceItem.devices_id !== action.param.devicesId),
      };
    case types.DEVICE_REMOVE_DEVICE_SUCCESS:
      return {...state,
        loaded: true,
        needReloadItems: true,
      };

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;

    case types.RESET_STATE:
      return defaultState;
    default:
      return state;
  }
};

export default device;
