import { actionTypes as types } from '../constants'
import { concatAndDeDuplicateObjects } from '../helpers/array/concatAndDeDuplicateObjects'

const defaultState = {
  items: [],
  loaded: true,
  allItemsHasFinishedSpeedTest: false,
  selectedItem: undefined,
}

const edge = (state = defaultState, action) => {
  const actualDateInUnix = + new Date();

  switch (action.type) {

    case types.EDGE_GET_LIST_REQUEST:
      return { ...state,
        loaded: false,
        items: [], // delete
        selectedItem: undefined,
      };
    case types.EDGE_GET_LIST_SUCCESS:
      return { ...state,
        loaded: true,
        allItemsHasFinishedSpeedTest: false,
        items: action.data.response.map(edgeItem => ({...edgeItem,
          speedTestResult: null,
          lastSpeedTestFinished: null,
          lastSpeedTestStarted: null,
          isDeath: false,
          loaded: true,
        })),
      };
    case types.EDGE_GET_LIST_FAILURE:
      return { ...state, loaded: true };

    case types.EDGE_ITEM_SPEED_TEST_REQUEST:
      const edgeItemForTest = action.param.edgeItem;
      return { ...state,
        items: state.items.map(edgeItem => (edgeItem.edges_id === edgeItemForTest.edges_id)
          ? { ...edgeItem,
            lastSpeedTestStarted: + new Date(),
            loaded: false,
          }
          : edgeItem
        ),
      };
    case types.EDGE_ITEM_SPEED_TEST_SUCCESS:
      const edgeItemForFinishTest = action.param.edgeItem;
      const newNotDeathEdgeItem = [{
        ...edgeItemForFinishTest, // only for save if we dont find it
        ...state.items.find(edgeItem => edgeItem.edges_id === edgeItemForFinishTest.edges_id),
        lastSpeedTestFinished: actualDateInUnix,
        loaded: true,
        isDeath: false,
      }].map(edgeItem => ({...edgeItem,
        speedTestResult: actualDateInUnix - edgeItem.lastSpeedTestStarted,
      }))[0];

      return [{ ...state,
        items: state.items.map(edgeItem => (edgeItem.edges_id === newNotDeathEdgeItem.edges_id)
          ? newNotDeathEdgeItem : edgeItem
        ),
        ...(!state.selectedItem || state.selectedItem.speedTestResult > newNotDeathEdgeItem.speedTestResult)
          ? {selectedItem: newNotDeathEdgeItem} : {},
      }].map(stateItem => ({ ...stateItem,
        allItemsHasFinishedSpeedTest: stateItem.items.find(edgeItem => edgeItem.loaded === false) === undefined,
      }))[0];
    case types.EDGE_ITEM_SPEED_TEST_FAILURE:
      const edgeItemForFailTest = action.param.edgeItem;
      return [{ ...state,
        items: state.items.map(edgeItem => (edgeItem.edges_id === edgeItemForFailTest.edges_id)
          ? { ...edgeItem,
            lastSpeedTestFinished: actualDateInUnix,
            speedTestResult: actualDateInUnix - edgeItem.lastSpeedTestStarted,
            loaded: true,
            isDeath: true,
          }
          : edgeItem
        ),
      }].map(stateItem => ({ ...stateItem,
        allItemsHasFinishedSpeedTest: stateItem.items.find(edgeItem => edgeItem.loaded === false) === undefined,
      }))[0];

      case types.VOD_GET_STREAM_URL_SUCCESS:
      return getStateWithActivateEdge(state, action.data.response.edgesId);

    case types.CHANNELS_STREAM_URL_SUCCESS:
      return getStateWithActivateEdge(state, action.data.response.edgesId);

    case types.RECORDING_GET_STREAM_URL_SUCCESS:
      return getStateWithActivateEdge(state, action.data.response.edgesId);

    case types.ERROR_ADD_ITEM:
      return [{ ...state,
        ...(action.item?.killActualEdgeItem && state.selectedItem)
          ? {
            items: state.items.map(edgeItem => edgeItem.edges_id === state.selectedItem.edges_id
              ? {...edgeItem, isDeath: true}
              : edgeItem
            ),
          }
          : {},
      }].map(stateItem => ({ ...stateItem,
        selectedItem: stateItem.items.find(edgeItem => !edgeItem.isDeath)
      }))[0];

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;

    case types.RESET_STATE:
      return defaultState;
    default:
      return state;
  }
};

const getStateWithActivateEdge = (state, edgesId) => {
  return ([{ ...state,
      items: state.items.map(edgeItem => ( edgeItem.edges_id === edgesId )
        ? ({ ...edgeItem, isDeath: false })
        : (edgeItem)
      ),
    }].map(stateItem => ({ ...stateItem,
      selectedItem: state.items.find(edgeItem => ( edgeItem.edges_id === edgesId ))
    }))
    .map(stateItem => ({ ...stateItem,
      selectedItem:
        stateItem.selectedItem
        || (
          typeof edgesId !== "undefined"
          && { // fake object when we have edgesId but we dont have it in edge/getActiveEdges response
            edges_id: edgesId,
          }
        )
        || undefined,
    }))[0]);

}

const getStateWithBrokenActualEdge = (state) => {
  const s = {...state,
    items: items.map(edgeItem => ({...edgeItem,
      ...(edgeItem.edges_id === selectedItem?.edges_id)
      ? {isDeath: true} : {},
    }))
  };
  return s;
}

export default edge;
