import { getClientConfig } from '../helpers/portal/getClientConfig';
import { post } from '../helpers';
import { actionTypes as types, fetchTypes } from '../constants';

export const fetch_sms_devicesMotvApiLogin = (dispatch, param) => post({
  url: `${getClientConfig().smsUrl}/api/Devices/Motv/apiLoginV2`,
  smsPost: true,
  body: param,
  request: fetchTypes.FETCH_SMS_CUSTOMER_LOGIN_REQUEST,
  success: fetchTypes.FETCH_SMS_CUSTOMER_LOGIN_SUCCESS,
  failure: fetchTypes.FETCH_SMS_CUSTOMER_LOGIN_FAILURE,
  dispatch,
});

export const fetch_sms_devices_loginWithToken = (dispatch, param) => post({
  url: `${getClientConfig().smsUrl}/api/Devices/Motv/apiLoginWithToken`,
  smsPost: true,
  body: param,
  request: types.SMS_LOGIN_WITH_TOKEN_REQUEST,
  success: types.SMS_LOGIN_WITH_TOKEN_SUCCESS,
  failure: types.SMS_LOGIN_WITH_TOKEN_FAILURE,
  dispatch,
});

export const fetch_sms_customer_getData = (dispatch, param) => post({
  url: `${getClientConfig().smsUrl}/api/devices/motv/apiGetCustomer`,
  body: param,
  headers: getHeadersForSms(),
  request: types.SMS__CUSTOMER__GET_DATA_REQUEST,
  success: types.SMS__CUSTOMER__GET_DATA_SUCCESS,
  failure: types.SMS__CUSTOMER__GET_DATA_FAILURE,
  dispatch,
  smsPost: true,
});

const getHeadersForSms = () => {
  return {};
};

export const fetch_sms_devicesGoogleApiLogin = (dispatch, param) => post({
  url: `${getClientConfig().smsUrl}/api/Devices/Google/register`,
  smsPost: true,
  body: param,
  request: fetchTypes.FETCH_SMS_CUSTOMER_LOGIN_GOOGLE_REQUEST,
  success: fetchTypes.FETCH_SMS_CUSTOMER_LOGIN_GOOGLE_SUCCESS,
  failure: fetchTypes.FETCH_SMS_CUSTOMER_LOGIN_GOOGLE_FAILURE,
  dispatch,
});

export const fetch_sms_devicesFacebookApiLogin = (dispatch, param) => post({
  url: `${getClientConfig().smsUrl}/api/Devices/Facebook/register`,
  smsPost: true,
  body: param,
  request: fetchTypes.FETCH_SMS_CUSTOMER_LOGIN_FACEBOOK_REQUEST,
  success: fetchTypes.FETCH_SMS_CUSTOMER_LOGIN_FACEBOOK_SUCCESS,
  failure: fetchTypes.FETCH_SMS_CUSTOMER_LOGIN_FACEBOOK_FAILURE,
  dispatch,
});

export const fetch_sms_devicesAppleApiRegister = (dispatch, param) => post({
  url: `${getClientConfig().smsUrl}/api/Devices/Apple/register`,
  smsPost: true,
  body: param,
  request: fetchTypes.FETCH_SMS_CUSTOMER_REGISTER_APPLE_REQUEST,
  success: fetchTypes.FETCH_SMS_CUSTOMER_REGISTER_APPLE_SUCCESS,
  failure: fetchTypes.FETCH_SMS_CUSTOMER_REGISTER_APPLE_FAILURE,
  dispatch,
});

export const fetch_sms_devicesAppleApiLogin = (dispatch, param) => post({
  url: `${getClientConfig().smsUrl}/api/Devices/Apple/login`,
  smsPost: true,
  body: param,
  request: fetchTypes.FETCH_SMS_CUSTOMER_LOGIN_APPLE_REQUEST,
  success: fetchTypes.FETCH_SMS_CUSTOMER_LOGIN_APPLE_SUCCESS,
  failure: fetchTypes.FETCH_SMS_CUSTOMER_LOGIN_APPLE_FAILURE,
  dispatch,
});
