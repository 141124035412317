import { actionTypes as types } from '../constants';
import { concatAndDeDuplicateObjects } from '../helpers/array/concatAndDeDuplicateObjects';

const defaultState = {
  items: [],
  loaded: true,
};

const subscription = (
  state = defaultState,
  action,
) => {
  switch (action.type) {

    case types.SMS__SUBSCRITION__GET_CUSTOMER_SUBSCRITION_INFO_REQUEST:
      return {...state,
        loaded: false,
      };
    case types.SMS__SUBSCRITION__GET_CUSTOMER_SUBSCRITION_INFO_SUCCESS:
      return {...state,
        loaded: true,
        items: action.data.response,
      };
    case types.SMS__SUBSCRITION__GET_CUSTOMER_SUBSCRITION_INFO_FAILURE:
      return {...state,
        loaded: true,
      };

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;

    case types.RESET_STATE:
      return defaultState;
    default:
      return state;
  }
};

export default subscription;
