import { actionTypes as types } from '../constants';


const defaultState = {
  notifications: [], // plnohodnotné zprávy které mají vlastní stránku
  shortMessages: [], // rychlé popup informace, samo-mizící
  googleToken: undefined,
};

const firebase = (state = defaultState, action) => {
  switch (action.type) {

    case types.FIREBASE_SAVE_TOKEN:
      return {...state,
        googleToken: action.googleToken,
      };

      case types.FIREBASE_ADD_NOTIFICATION:
        return {...state,
          notifications: [
            action.notificationItem,
            ...state.notifications,
          ],
        };

    case types.FIREBASE_REMOVE_NOTIFICATION_WITH_ID:
      return {...state,
        notifications: state.notifications.filter(notificationItem => notificationItem.id !== action.id),
      };

    case types.FIREBASE_REMOVE_ALL_NOTIFICATIONS:
      return {...state,
        notifications: defaultState.notifications,
      };

    case types.FIREBASE_ADD_SHORT_MESSAGE:
      return {...state,
        shortMessages: [
          action.shortMessageItem,
          ...state.shortMessages,
        ],
      };

    case types.FIREBASE_REMOVE_SHORT_MESSAGE_WITH_ID:
      return {...state,
        shortMessages: state.shortMessages.filter(shortMessageItem => shortMessageItem.id !== action.id),
      };

    case types.PROVIDER_LOAD_PROVIDER_WITH_NAME_SUCCESS:
      return defaultState;

    case types.RESET_STATE:
      return defaultState;

    default:
      return state;
  }

};

export default firebase;
