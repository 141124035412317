import {
  isAfter,
  isBefore,
} from 'date-fns';

import { getEpgItemPlayerUrl } from './getEpgItemPlayerUrl';
import { getEpgItemUrl } from './getEpgItemUrl';
import { getIHaveWatchedPercentForEpgItem } from './getIHaveWatchedPercentForEpgItem';
import { getProgressTimeForEpgItem } from './getProgressTimeForEpgItem';
import { hourTime } from '../localeDate/hourTime';
import * as boxItemTypes from '../../constants/boxItemTypes';

export const getExtendEpgItem = (epgItem, {
  recordingItems = [],
  myListItems = [],
  selectedItemInState,
} = {}) => {
  return [{...epgItem, // sjednotíme si případné nerovnosti prefixovaný / neprefixovaných eventů
    start: epgItem.start || epgItem.start,
    end: epgItem.end || epgItem.end,
    id: epgItem.id || epgItem.id,
  }].map(epgItem => ({...epgItem,
    isStartingInFuture: isAfter(new Date(epgItem.start), new Date()),
    isEndingInPast:  isBefore(new Date(epgItem.end), new Date()),
    isNowPlaying:
      (isBefore(new Date(epgItem.start), new Date())
        && isAfter(new Date(epgItem.end), new Date())),
    isRecordOn: recordingItems?.some(recItem => (recItem.id === epgItem.id)),
    isInMyList: myListItems?.some(listItem => listItem.id === epgItem.id), // vyjmuto: listItem.type === boxItemTypes.BOX_ITEM_TV_TYPE &&
    }
  )).map(epgItem => ({...epgItem,
    progressTime: (epgItem.isNowPlaying) ? getProgressTimeForEpgItem(epgItem) : undefined,
    iHaveWatchedPercent: getIHaveWatchedPercentForEpgItem(epgItem),
    isSelected: (
      selectedItemInState !== undefined
      && selectedItemInState?.id === epgItem.id
    ),
    hasReminderMark: epgItem.isInMyList && epgItem.isStartingInFuture && myListItems
      .find(listItem => listItem.type === boxItemTypes.BOX_ITEM_TV_TYPE && listItem.id === epgItem.id) // najdu si ho v myListItems
      ?.reminder !== null, // a má nějak nastavený reminder --> má tudíž příznak
    url: getEpgItemUrl(epgItem),
    playerUrl: getEpgItemPlayerUrl(epgItem, "tv"), // FIXME: tohle fixní "tv", je zlo
    readableHourTimeStart: hourTime(epgItem.start),
    readableHourTimeEnd: hourTime(epgItem.end),
  }))[0]
};
