import * as boxItemTypeConst from '../../constants/boxItemTypes';

export const isSafeBoxItemType = (boxItemType) => (
  boxItemType === boxItemTypeConst.BOX_ITEM_CATEGORY_TYPE
  || boxItemType === boxItemTypeConst.BOX_ITEM_GENRE_TYPE
  || boxItemType === boxItemTypeConst.BOX_ITEM_CHANNEL_TYPE
  || boxItemType === boxItemTypeConst.BOX_ITEM_IMAGE_TYPE
  || boxItemType === boxItemTypeConst.BOX_ITEM_PERSON_TYPE
  || boxItemType === boxItemTypeConst.BOX_ITEM_RECORDING_TYPE
  || boxItemType === boxItemTypeConst.BOX_ITEM_TV_TYPE
  || boxItemType === boxItemTypeConst.BOX_ITEM_VOD_TYPE
  || boxItemType === boxItemTypeConst.BOX_ITEM_VIDEO_TYPE
  || boxItemType === boxItemTypeConst.BOX_ITEM_YOUTUBE_TYPE
);
