import { add, formatISO, isAfter } from 'date-fns';

import { URL_CACHE_NAME } from '../../constants/smallPieces';
import { isEqualJSON } from '../json/isEqualJSON';

const MAX_LIMIT_FOR_CACHE_ITEM = 1024 * 100; // max 0.1MB to one item

const NOT_ALLOWED_RULES = [
  (url) => url?.endsWith("/public/recommendationEngine/addToMyList"),
  (url) => url?.endsWith("/public/recommendationEngine/removeFromMyList"),
  (url) => url?.includes("/public/profile/"),
  (url) => url?.includes("/getStreamUrlV3"),
  (url) => url?.includes("/getStreamUrl"),
  (url) => url?.includes("/sms/api/"), // old sms using express proxy
  (url) => url?.includes("/api/"), // new sms
  (url) => url?.includes("/public/device/removeDevice"),
  (url) => url?.includes("/public/customer/getData"),
  (url) => url?.includes("/public/customer/requestLoginQRCode"),
  (url) => url?.includes("/public/customer/getQRCodeData"),
  (url) => url?.endsWith("/public/messaging/unsubscribeToken"),
  (url) => url?.includes("/public/device/getData"),
  (url) => url?.includes("/public/recording/addRecordingV2"),
  (url) => url?.includes("/public/recording/removeRecording"),
  (url) => url?.endsWith("/public/epg/getUpdatedEventsV2"),
];

const REMOVED_RULES = [
  ({cacheItem, url}) => (
    cacheItem?.url.endsWith("/public/epg/getUpdatedEventsV2")
    && (url?.endsWith("/public/epg/getUpdatedEventsV2"))
  ),
  ({cacheItem, url}) => (
    cacheItem?.url.endsWith("/public/recommendationEngine/getMyList")
    && (url?.endsWith("/public/recommendationEngine/addToMyList") || url?.endsWith("/public/recommendationEngine/removeFromMyList"))
  ),
  ({cacheItem, url}) => (
    cacheItem?.url.endsWith("/public/vendor/getData")
    && url?.endsWith("/sms/api/Devices/Motv/apiLogin")
  ),
  ({cacheItem, url}) => (
    cacheItem?.url.endsWith("/public/device/getDevices")
    && url?.endsWith("/public/device/removeDevice")
  ),
  ({cacheItem, url}) => (
    cacheItem?.url.endsWith("/public/recommendationEngine/getHomepageRowV2")
    && (url?.endsWith("/public/recommendationEngine/addToMyList") || url?.endsWith("/public/recommendationEngine/removeFromMyList"))
  ),
  ({cacheItem, url}) => (
    cacheItem?.url.endsWith("/public/recommendationEngine/getHomepageRowV2")
    && url?.endsWith("/public/recommendationEngine/removeRecommendation")
  ),
  ({cacheItem, url}) => (
    (cacheItem?.url.endsWith("/public/recording/getRecordingsByProfileV2") || cacheItem?.url.endsWith("/public/customer/getData") || cacheItem?.url.endsWith("/recommendationEngine/getHomepageRowV2"))
    && (url?.endsWith("/public/recording/addRecordingV2") || url?.endsWith("/public/recording/removeRecording"))
  ),
  ({cacheItem, url}) => (
    cacheItem?.url.endsWith("/public/channel/getSubscribedChannels")
    && url?.endsWith("/public/profile/updateChannelOrder")
  ),
  ({cacheItem, url}) => (
    cacheItem?.url.endsWith("/public/channel/getSubscribedChannels")
    && url?.endsWith("/public/profile/update")
  ),
  ({cacheItem, url}) => (
    cacheItem?.url.endsWith("/public/channel/getSubscribedChannels")
    && url?.endsWith("/public/profile/resetChannelOrder")
  ),
  ({cacheItem, url}) => (
    cacheItem?.url.endsWith("/public/channel/getSubscribedAndLockedChannels")
    && url?.endsWith("/public/profile/updateChannelOrder")
  ),
  ({cacheItem, url}) => (
    cacheItem?.url.endsWith("/public/channel/getSubscribedAndLockedChannels")
    && url?.endsWith("/public/profile/update")
  ),
  ({cacheItem, url}) => (
    cacheItem?.url.endsWith("/public/channel/getSubscribedAndLockedChannels")
    && url?.endsWith("/public/profile/resetChannelOrder")
  ),
  ({cacheItem, url}) => (
    cacheItem?.url.endsWith("/public/channel/getSubscribedAndLockedChannels")
    && url?.endsWith("/public/profile/addFavoriteChannel")
  ),
  ({cacheItem, url}) => (
    cacheItem?.url.endsWith("/public/channel/getSubscribedAndLockedChannels")
    && url?.endsWith("/public/profile/removeFavoriteChannel")
  ),
  ({cacheItem, url}) => (
    cacheItem?.url.endsWith("/public/channel/getSubscribedChannels")
    && url?.endsWith("/public/profile/addFavoriteChannel")
  ),
  ({cacheItem, url}) => (
    cacheItem?.url.endsWith("/public/channel/getSubscribedChannels")
    && url?.endsWith("/public/profile/removeFavoriteChannel")
  ),
];



export const loadFromCache = async({url, body, header}) =>
  getCacheStorageWithValidItems().find(cacheItem => (
    url === cacheItem.url
    && isEqualJSON(cacheItem.body, body)
    && isEqualJSON(cacheItem.header, header)
  ))?.response;


export const saveToCache = async({url, body, response, validTo = formatISO(add(new Date(), {minutes: 10})), header}) => {
  const newCache = [
    ...getCacheStorageWithValidItems().filter(cacheItem => !(url === cacheItem.url && isEqualJSON(cacheItem.body, body))),
    ...isNotAllowedSaveThisItem({url, body, response})
    ? []
    : [{
      url,
      body,
      header,
      response,
      validTo,
    }]
  ]
  .reduce((res, cacheItem) => {
    return [...res,
      ...hasCacheItemAnyDependentTrouble({cacheItem, url, body}) ? [] : [cacheItem],
    ]
  }, []);
  try {
    localStorage.setItem(URL_CACHE_NAME, JSON.stringify(newCache));
  } catch (e) {
    // TODO: přidat nějaký čistič?
    // console.info("Local Storage is full. Nothing was save.");
  }
}

export const clearCache = () => {
  window.localStorage.removeItem(URL_CACHE_NAME);
}

const hasCacheItemAnyDependentTrouble = ({cacheItem, url, body}) => REMOVED_RULES.find(rulesFce => rulesFce({cacheItem, url, body})) !== undefined;


const getCacheStorageWithValidItems = () =>
  (JSON.parse(localStorage.getItem(URL_CACHE_NAME)) || []).filter(storageItem => isAfter(new Date(storageItem.validTo), new Date()));

const isNotAllowedSaveThisItem = ({url, body, response}) => (
  NOT_ALLOWED_RULES.some(rulesFce => rulesFce(url, body))
  || JSON.stringify(response).length > MAX_LIMIT_FOR_CACHE_ITEM
);
