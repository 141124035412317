import { removeChannelItemPrefix } from './removeChannelItemPrefix';


export const getDataFromChannelStreamResponse = (streamResponse) =>
 streamResponse.status === 1
 && [streamResponse.response]
  .map(streamDataItem => ({...streamDataItem,
    audio: streamDataItem?.audio.map(audioItem => removeChannelItemPrefix(audioItem)),
    subtitle: [
      {
        label: "-----",
        language: null,
        order: 0,
      },
      ...streamDataItem?.subtitle.map(subtitleItem => removeChannelItemPrefix(subtitleItem)),
    ],
  })).
  map(streamDataItem => ({...streamDataItem,
    selectedAudio: streamDataItem.audio.find(audioItem => audioItem.default),
    selectedSubtitle:
      streamDataItem.subtitle.find(subtitleItem => subtitleItem.default)
      || streamDataItem.subtitle[0], // or default "none"
  }))[0];
