import { getClientConfig } from '../helpers/portal/getClientConfig';
import { fetch_sms_devicesMotvApiLogin } from '../fetchs';
import { fetch_sms_devices_loginWithToken } from '../fetchs';
import { post } from '../helpers';
import { actionTypes as types } from '../constants';

import { customer_logout } from './customer';
import { fetch_sms_customer_getData } from '../fetchs';
import { getClientStorage } from '../helpers/device/clientStorage';


export const sms_loginMe = param => (dispatch) => {
  fetch_sms_devicesMotvApiLogin(dispatch, param);
};

export const sms_devices_loginWithToken = param => async(dispatch) => {
  await dispatch(customer_logout());
  fetch_sms_devices_loginWithToken(dispatch, param);
};

export const sms_lostPassword = param => (dispatch) => {
  post({
    url: `${getClientConfig().smsUrl}/api/Devices/Motv/apiRequestNewPassword`,
    smsPost: true,
    body: param,
    param,
    request: types.SMS_REQUEST_NEW_PASSWORD_REQUEST,
    success: types.SMS_REQUEST_NEW_PASSWORD_SUCCESS,
    failure: types.SMS_REQUEST_NEW_PASSWORD_FAILURE,
    dispatch,
  });
};

export const sms_changePassword = param => (dispatch) => {
  post({
    url: `${getClientConfig().smsUrl}/api/Devices/Motv/apiChangePassword`,
    smsPost: true,
    body: param,
    request: types.SMS_CHANGE_PASSWORD_REQUEST,
    success: types.SMS_CHANGE_PASSWORD_SUCCESS,
    failure: types.SMS_CHANGE_PASSWORD_FAILURE,
    dispatch,
  });
};

export const sms_forceChangePassword = param => (dispatch) => {
  post({
    url: `${getClientConfig().smsUrl}/api/devices/motv/apiForceChangePassword`,
    smsPost: true,
    body: param,
    request: types.SMS_FORCE_CHANGE_PASSWORD_REQUEST,
    success: types.SMS_FORCE_CHANGE_PASSWORD_SUCCESS,
    failure: types.SMS_FORCE_CHANGE_PASSWORD_FAILURE,
    param: {...param,
      pathname: window.location.pathname,
    },
    dispatch,
  });
};


export const sms_subscription_getCustomerSubscriptionInfo = param => (dispatch) => {
  post({
    url: `${getClientConfig().smsUrl}/api/devices/motv/apiGetCustomerSubscriptionInfo`,
    smsPost: true,
    body: param,
    request: types.SMS__SUBSCRITION__GET_CUSTOMER_SUBSCRITION_INFO_REQUEST,
    success: types.SMS__SUBSCRITION__GET_CUSTOMER_SUBSCRITION_INFO_SUCCESS,
    failure: types.SMS__SUBSCRITION__GET_CUSTOMER_SUBSCRITION_INFO_FAILURE,
    dispatch,
  });
};

export const sms_customer_getData = param => (dispatch) => {
  fetch_sms_customer_getData(dispatch, param);
};

export const sms_customer_update = (customers_token = undefined) => (dispatch, getState) => {
  const language = getClientStorage("language") || "en";
  const param = {
    data: getState().user.configFlatView.reduce((accumulator, fieldItem) => ({...accumulator,
      [fieldItem.key]: fieldItem.value,
    }), {}),
    language,
    customers_token,
  };
  post({
    url: `${getClientConfig().smsUrl}/api/devices/motv/apiUpdateCustomerV2`,
    smsPost: true,
    body: param,
    request: types.SMS__CUSTOMER__UPDATE_REQUEST,
    success: types.SMS__CUSTOMER__UPDATE_SUCCESS,
    failure: types.SMS__CUSTOMER__UPDATE_FAILURE,
    dispatch,
    param,
  });
};

export const sms_customer_register = (social = false) => (dispatch, getState) => {
  const language = getClientStorage("language") || "en";
  const param = {
    data: getState().user.configFlatView.reduce((accumulator, fieldItem) => ({...accumulator,
      [fieldItem.key]: fieldItem.value,
    }), {}),
    language,
    portalId: getClientConfig().portalId,
    social
  };

  post({
    url: `${getClientConfig().smsUrl}/api/devices/motv/apiRegisterV2`,
    smsPost: true,
    body: param,
    request: types.SMS__CUSTOMER__REGISTER_REQUEST,
    success: types.SMS__CUSTOMER__REGISTER_SUCCESS,
    failure: types.SMS__CUSTOMER__REGISTER_FAILURE,
    dispatch,
    param,
  });
};

export const sms_customer_getCustomerConfig = (param) => (dispatch, getState) => {
  const language = getClientStorage("language") || "en";
  post({
    url: `${getClientConfig().smsUrl}/api/devices/motv/getMotvCustomerConfig`,
    smsPost: true,
    body: {...param,
      language,
      portals_id: getState().portal.motv_portals_id,
    },
    request: types.SMS__CUSTOMER__GET_CUSTOMER_CONFIG_REQUEST,
    success: types.SMS__CUSTOMER__GET_CUSTOMER_CONFIG_SUCCESS,
    failure: types.SMS__CUSTOMER__GET_CUSTOMER_CONFIG_FAILURE,
    dispatch,
  });
};

export const sms_customer_updateConfig = ({key, value}) => (dispatch) => {

  dispatch({
    type: types.SMS_CUSTOMER_UPDATE_CONFIG,
    key,
    value,
  });
};

export const sms_devices_getPortal = param => async(dispatch) => {
  await post({
    url: `${getClientConfig().smsUrl}/api/Devices/Motv/getPortal`,
    smsPost: true,
    body: param,
    request: types.SMS__DEVICES__GET_PORTAL_REQUEST,
    success: types.SMS__DEVICES__GET_PORTAL_SUCCESS,
    failure: types.SMS__DEVICES__GET_PORTAL_FAILURE,
    dispatch,
  });
};

export const sms_devices_getPortalPageByName = param => (dispatch) => {
  post({
    url: `${getClientConfig().smsUrl}/api/Devices/Motv/getPortalPageByName`,
    smsPost: true,
    body: param,
    request: types.SMS__DEVICES__GET_PORTAL_PAGE_BY_NAME_REQUEST,
    success: types.SMS__DEVICES__GET_PORTAL_PAGE_BY_NAME_SUCCESS,
    failure: types.SMS__DEVICES__GET_PORTAL_PAGE_BY_NAME_FAILURE,
    dispatch,
  });
};

export const sms_devices_apiRegister = param => (dispatch) => {
  post({
    url: `${getClientConfig().smsUrl}/api/Devices/Motv/apiRegister`,
    smsPost: true,
    body: param,
    request: types.SMS__DEVICES__API_REGISTER_REQUEST,
    success: types.SMS__DEVICES__API_REGISTER_SUCCESS,
    failure: types.SMS__DEVICES__API_REGISTER_FAILURE,
    dispatch,
    param: {...param,
      pathname: window.location.pathname,
    },
  });
};

export const sms_devices_apiConfirmRegistration = param => (dispatch) => {
  post({
    url: `${getClientConfig().smsUrl}/api/Devices/Motv/apiConfirmRegistration`,
    smsPost: true,
    body: param,
    request: types.SMS__DEVICES__API__CONFIRM_REGISTRATION__REQUEST,
    success: types.SMS__DEVICES__API__CONFIRM_REGISTRATION__SUCCESS,
    failure: types.SMS__DEVICES__API__CONFIRM_REGISTRATION__FAILURE,
    dispatch,
  });
};
