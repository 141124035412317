export const removeRowWithIdFromRecommendationState = (rowId, recommendationState) => {
  if(
    typeof rowId !== "number"
    || !recommendationState
  ) return undefined;


  return {
    ...recommendationState,
    rows: recommendationState.rows.filter(rowItem => rowItem.id !== rowId)
  };
};
