export const RECOMMENDATION_RESET = 'RECOMMENDATION_RESET';

export const RECOMMENDATION_HOMEPAGE_REQUEST = 'RECOMMENDATION_HOMEPAGE_REQUEST';
export const RECOMMENDATION_HOMEPAGE_SUCCESS = 'RECOMMENDATION_HOMEPAGE_SUCCESS';
export const RECOMMENDATION_HOMEPAGE_FAILURE = 'RECOMMENDATION_HOMEPAGE_FAILURE';

export const RECOMMENDATION_HOMEPAGE_ROW_REQUEST = 'RECOMMENDATION_HOMEPAGE_ROW_REQUEST';
export const RECOMMENDATION_HOMEPAGE_ROW_SUCCESS = 'RECOMMENDATION_HOMEPAGE_ROW_SUCCESS';
export const RECOMMENDATION_HOMEPAGE_ROW_FAILURE = 'RECOMMENDATION_HOMEPAGE_ROW_FAILURE';

export const RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_REQUEST = 'RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_REQUEST';
export const RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_SUCCESS = 'RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_SUCCESS';
export const RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_FAILURE = 'RECOMMENDATION_GET_EVENT_RECOMMENDATION_ROWS_FAILURE';

export const RECOMMENDATION_GET_SUBVIEW_EVENT_RECOMMENDATION_ROWS_REQUEST = 'RECOMMENDATION_GET_SUBVIEW_EVENT_RECOMMENDATION_ROWS_REQUEST';
export const RECOMMENDATION_GET_SUBVIEW_EVENT_RECOMMENDATION_ROWS_SUCCESS = 'RECOMMENDATION_GET_SUBVIEW_EVENT_RECOMMENDATION_ROWS_SUCCESS';
export const RECOMMENDATION_GET_SUBVIEW_EVENT_RECOMMENDATION_ROWS_FAILURE = 'RECOMMENDATION_GET_SUBVIEW_EVENT_RECOMMENDATION_ROWS_FAILURE';

export const RECOMMENDATION_SEARCH_REQUEST = 'RECOMMENDATION_SEARCH_REQUEST';
export const RECOMMENDATION_SEARCH_SUCCESS = 'RECOMMENDATION_SEARCH_SUCCESS';
export const RECOMMENDATION_SEARCH_FAILURE = 'RECOMMENDATION_SEARCH_FAILURE';

export const RECOMMENDATION_REMOVE_REQUEST = 'RECOMMENDATION_REMOVE_REQUEST';
export const RECOMMENDATION_REMOVE_SUCCESS = 'RECOMMENDATION_REMOVE_SUCCESS';
export const RECOMMENDATION_REMOVE_FAILURE = 'RECOMMENDATION_REMOVE_FAILURE';

export const RECOMMENDATION_ADD_TO_MY_LIST_REQUEST = 'RECOMMENDATION_ADD_TO_MY_LIST_REQUEST';
export const RECOMMENDATION_ADD_TO_MY_LIST_SUCCESS = 'RECOMMENDATION_ADD_TO_MY_LIST_SUCCESS';
export const RECOMMENDATION_ADD_TO_MY_LIST_FAILURE = 'RECOMMENDATION_ADD_TO_MY_LIST_FAILURE';

export const RECOMMENDATION_REMOVE_FROM_MY_LIST_REQUEST = 'RECOMMENDATION_REMOVE_FROM_MY_LIST_REQUEST';
export const RECOMMENDATION_REMOVE_FROM_MY_LIST_SUCCESS = 'RECOMMENDATION_REMOVE_FROM_MY_LIST_SUCCESS';
export const RECOMMENDATION_REMOVE_FROM_MY_LIST_FAILURE = 'RECOMMENDATION_REMOVE_FROM_MY_LIST_FAILURE';

export const RECOMMENDATION_LOAD_ROWS_WITH_KEY_REQUEST = 'RECOMMENDATION_LOAD_ROWS_WITH_KEY_REQUEST';
export const RECOMMENDATION_LOAD_ROWS_WITH_KEY_SUCCESS = 'RECOMMENDATION_LOAD_ROWS_WITH_KEY_SUCCESS';
export const RECOMMENDATION_LOAD_ROWS_WITH_KEY_FAILURE = 'RECOMMENDATION_LOAD_ROWS_WITH_KEY_FAILURE';

export const RECOMMENDATION_LOAD_EXTENDED_INFO_FOR_ROW_ITEM_REQUEST = 'RECOMMENDATION_LOAD_EXTENDED_INFO_FOR_ROW_ITEM_REQUEST';
export const RECOMMENDATION_LOAD_EXTENDED_INFO_FOR_ROW_ITEM_SUCCESS = 'RECOMMENDATION_LOAD_EXTENDED_INFO_FOR_ROW_ITEM_SUCCESS';
export const RECOMMENDATION_LOAD_EXTENDED_INFO_FOR_ROW_ITEM_FAILURE = 'RECOMMENDATION_LOAD_EXTENDED_INFO_FOR_ROW_ITEM_FAILURE';
