import {
  fetch_customer_getData,
  fetch_customer_getQRCodeData,
  fetch_customer_profileSelected,
  fetch_customer_requestLoginQRCode,
  fetch_sms_devices_loginWithToken,
  fetch_sms_devicesAppleApiLogin, fetch_sms_devicesAppleApiRegister,
  fetch_sms_devicesFacebookApiLogin,
  fetch_sms_devicesGoogleApiLogin,
  fetch_sms_devicesMotvApiLogin,
} from '../fetchs';
import { actionTypes as types } from '../constants';
import { getClientConfig } from '../helpers/portal/getClientConfig';
import { isSuccessResponse } from '../helpers/fetch/isSuccessResponse';
import { setClientStorage } from '../helpers/device/clientStorage';
import { SYSTEM_WEB } from '../constants/system';
import { messaging_unsubscribeToken } from './messaging';
import { removeClientStorage } from '../helpers/device/clientStorage';

export const customer_getData = (param) => dispatch => {
  fetch_customer_getData(dispatch, param);
};

export const customer_requestLoginQRCode = (param) => async(dispatch) => {
  fetch_customer_requestLoginQRCode(dispatch, param);
};

export const customer_loginWithQrCode = (param) => async(dispatch) => {

  const qrTokenResponse = await fetch_customer_getQRCodeData(dispatch, param);

  if(!isSuccessResponse(qrTokenResponse)) {
    dispatch({type: types.CUSTOMER_LOGIN_FAILURE});
    return;
  }

  if(!qrTokenResponse.response.login_qr_token) return;
  dispatch(customer_loginWithUrlParams({appToken: qrTokenResponse.response.login_qr_token}));
};

export const customer_resetSchemeAndData = () => dispatch => {
  dispatch({
    type: types.CUSTOMER_RESET_SCHEME_AND_DATA,
  });
};

export const customer_login = (param) => async(dispatch, getState) => {
  // idea for future
  // po přihlášení jdeme ne select-profile - DONE
  // nebo si automaticky vybereme první a jdeme na něj - DONE
  // a pokud máme start-up channel tak jdeme na tv a rovnou pustíme


  dispatch({type: types.CUSTOMER_LOGIN_REQUEST});

  const loginResponse = await fetch_sms_devicesMotvApiLogin(dispatch, {...param,
      vendors_id: getState().vendor.vendors_id,
  });

  if(!isSuccessResponse(loginResponse)) {
    dispatch({type: types.CUSTOMER_LOGIN_FAILURE, data: loginResponse});
    return;
  }

  if( // máme jen jeden profil a nemám vynucené zadání PIN z MW, rovnou ho přihlásíme
    loginResponse.response.profiles.length === 1
    && !loginResponse.response.profiles[0].profiles_login_requires_pin
  ) {
    // const profileInfoResponse = await fetch_customer_profileSelected(dispatch, {
    //   profilesId: loginResponse.response.profiles[0].profiles_id,
    //   customerToken: loginResponse.response.customers_token,
    // });

    const profileId = loginResponse.response.profiles[0].profiles_id;

    const fullProfileInfo = await dispatch(getFullProfileInfo({profileId, loginResponse}));

    setLogedUserToClientStorage({
      ...(fullProfileInfo.selectedProfile) ? {profilesId: fullProfileInfo.selectedProfile.profiles_id} : {},
      authorization: loginResponse.response.customers_token,
    });

    dispatch({
      type: types.CUSTOMER_LOGIN_SUCCESS,
      customerData: loginResponse.response,
      selectedProfile: fullProfileInfo.selectedProfile,
    });

    // const hpUrl = getHpUrl(getState().portal.motv_portals_sections, getState().portal.motv_portals_account_update_enabled);
    // history.push(hpUrl || "/");
    return;
  }

  setLogedUserToClientStorage({
    authorization: loginResponse.response.customers_token,
  });

  dispatch({
    type: types.CUSTOMER_LOGIN_SUCCESS,
    customerData: loginResponse.response,
  });

};


export const customer_loginWithUrlParams = ({
  appToken,
  profileId,
  profilePin,
  urlAfterLogin,
}) => async(dispatch, getState) => {
  dispatch({type: types.CUSTOMER_LOGIN_REQUEST});
  await dispatch(customer_logout());
  const loginResponse = await fetch_sms_devices_loginWithToken(dispatch, {token: appToken});

  if(!isSuccessResponse(loginResponse)) {
    dispatch({type: types.CUSTOMER_LOGIN_FAILURE});
    return;
  }

  const fullProfileInfo = await dispatch(getFullProfileInfo({profileId, profilePin, loginResponse}));

  setLogedUserToClientStorage({
    ...(fullProfileInfo.selectedProfile) ? {profilesId: fullProfileInfo.selectedProfile.profiles_id} : {},
    authorization: loginResponse.response.customers_token,
  });

  const urlRedirectAfterLogin = getUrlAfterLogin({fullProfileInfo, urlAfterLogin});

  dispatch({
    type: types.CUSTOMER_LOGIN_SUCCESS,
    selectedProfile: fullProfileInfo.selectedProfile,
    customerData: loginResponse.response,
    ...(urlRedirectAfterLogin) ? {urlRedirectAfterLogin} : {},
  });
};

const getUrlAfterLogin = ({
  fullProfileInfo,
  urlAfterLogin,
}) => {
  return urlAfterLogin
    || fullProfileInfo.profileInfoResponse?.response?.preferedUrlAfterLogin
    || "/";
};

const getAutoSelectedProfile = ({
  profileId,
  profilePin,
  loginResponse,
}) => {
  if(
    !loginResponse
    || !isSuccessResponse(loginResponse)
    || loginResponse.response.profiles?.length < 1
  ) return;

  const selectedProfile =
    loginResponse.response.profiles.find(profileItem => // we have prefered profileID, try find it
      profileItem.profiles_id === profileId
    )
    || (loginResponse.response.profiles.length === 1 && loginResponse.response.profiles[0]) // we have only one profile
    || undefined;

  return selectedProfile;
};

const getFullProfileInfo = ({
  profileId,
  profilePin,
  loginResponse,
}) => async(dispatch) => {
  const selectedProfile = getAutoSelectedProfile({profileId, profilePin, loginResponse});
  if(!selectedProfile) return {selectedProfile: undefined, profileInfoResponse: undefined};

  const profileInfoResponse = await fetch_customer_profileSelected(dispatch, {
    profilesId: loginResponse.response.profiles[0].profiles_id,
    customerToken: loginResponse.response.customers_token,
  });
  return {
    selectedProfile: {...selectedProfile,
      isDefaultProfile: (selectedProfile.profiles_id === loginResponse.response.customers_profiles_id),
    },
    profileInfoResponse,
  };
};

export const customer_logout = () => async(dispatch, getState) => {
  const googleToken = getState().firebase.googleToken;
  if(googleToken) await dispatch(messaging_unsubscribeToken(googleToken));
  ['authorization', 'profilesId'].map(storageItem => removeClientStorage(storageItem));
  dispatch({type: types.RESET_STATE});
};

const setLogedUserToClientStorage = ({
  authorization,
  profilesId,
}) => {
  (profilesId && setClientStorage('profilesId', btoa(profilesId)));
  (authorization && setClientStorage('authorization', authorization));
};

const getHistory = async() => (getClientConfig().devicesType === SYSTEM_WEB)
    ? await import('../index.js')
    : await import('../index-tv.js');

export const customer_loginGoogle = (param) => async(dispatch, getState) => {
  dispatch({type: types.CUSTOMER_LOGIN_GOOGLE_REQUEST});

  const loginResponse = await fetch_sms_devicesGoogleApiLogin(dispatch, param);

  if(!isSuccessResponse(loginResponse)) {
    dispatch({type: types.CUSTOMER_LOGIN_GOOGLE_FAILURE, data: loginResponse});
    return;
  }

  if( // máme jen jeden profil a nemám vynucené zadání PIN z MW, rovnou ho přihlásíme
    loginResponse.response.profiles.length === 1
    && !loginResponse.response.profiles[0].profiles_login_requires_pin
  ) {
    // const profileInfoResponse = await fetch_customer_profileSelected(dispatch, {
    //   profilesId: loginResponse.response.profiles[0].profiles_id,
    //   customerToken: loginResponse.response.customers_token,
    // });

    const profileId = loginResponse.response.profiles[0].profiles_id;

    const fullProfileInfo = await dispatch(getFullProfileInfo({profileId, loginResponse}));

    setLogedUserToClientStorage({
      ...(fullProfileInfo.selectedProfile) ? {profilesId: fullProfileInfo.selectedProfile.profiles_id} : {},
      authorization: loginResponse.response.customers_token,
    });

    dispatch({
      type: types.CUSTOMER_LOGIN_SUCCESS,
      customerData: loginResponse.response,
      selectedProfile: fullProfileInfo.selectedProfile,
    });

    // const hpUrl = getHpUrl(getState().portal.motv_portals_sections, getState().portal.motv_portals_account_update_enabled);
    // history.push(hpUrl || "/");
    return;
  }

  setLogedUserToClientStorage({
    authorization: loginResponse.response.customers_token,
  });

  dispatch({
    type: types.CUSTOMER_LOGIN_SUCCESS,
    customerData: loginResponse.response,
  });

};

export const customer_loginFacebook = (param) => async(dispatch, getState) => {
  dispatch({type: types.CUSTOMER_LOGIN_FACEBOOK_REQUEST});

  const loginResponse = await fetch_sms_devicesFacebookApiLogin(dispatch, param);

  if(!isSuccessResponse(loginResponse)) {
    dispatch({type: types.CUSTOMER_LOGIN_FACEBOOK_FAILURE, data: loginResponse});
    return;
  }

  if( // máme jen jeden profil a nemám vynucené zadání PIN z MW, rovnou ho přihlásíme
    loginResponse.response.profiles.length === 1
    && !loginResponse.response.profiles[0].profiles_login_requires_pin
  ) {
    // const profileInfoResponse = await fetch_customer_profileSelected(dispatch, {
    //   profilesId: loginResponse.response.profiles[0].profiles_id,
    //   customerToken: loginResponse.response.customers_token,
    // });

    const profileId = loginResponse.response.profiles[0].profiles_id;

    const fullProfileInfo = await dispatch(getFullProfileInfo({profileId, loginResponse}));

    setLogedUserToClientStorage({
      ...(fullProfileInfo.selectedProfile) ? {profilesId: fullProfileInfo.selectedProfile.profiles_id} : {},
      authorization: loginResponse.response.customers_token,
    });

    dispatch({
      type: types.CUSTOMER_LOGIN_SUCCESS,
      customerData: loginResponse.response,
      selectedProfile: fullProfileInfo.selectedProfile,
    });

    // const hpUrl = getHpUrl(getState().portal.motv_portals_sections, getState().portal.motv_portals_account_update_enabled);
    // history.push(hpUrl || "/");
    return;
  }

  setLogedUserToClientStorage({
    authorization: loginResponse.response.customers_token,
  });

  dispatch({
    type: types.CUSTOMER_LOGIN_SUCCESS,
    customerData: loginResponse.response,
  });

};

export const customer_registerApple = (param) => async(dispatch, getState) => {
  dispatch({type: types.CUSTOMER_REGISTER_APPLE_REQUEST});

  const loginResponse = await fetch_sms_devicesAppleApiRegister(dispatch, param);

  if(!isSuccessResponse(loginResponse)) {
    dispatch({type: types.CUSTOMER_REGISTER_APPLE_FAILURE, data: loginResponse});
    return;
  }

  if( // máme jen jeden profil a nemám vynucené zadání PIN z MW, rovnou ho přihlásíme
    loginResponse.response.profiles.length === 1
    && !loginResponse.response.profiles[0].profiles_login_requires_pin
  ) {
    // const profileInfoResponse = await fetch_customer_profileSelected(dispatch, {
    //   profilesId: loginResponse.response.profiles[0].profiles_id,
    //   customerToken: loginResponse.response.customers_token,
    // });

    const profileId = loginResponse.response.profiles[0].profiles_id;

    const fullProfileInfo = await dispatch(getFullProfileInfo({profileId, loginResponse}));

    setLogedUserToClientStorage({
      ...(fullProfileInfo.selectedProfile) ? {profilesId: fullProfileInfo.selectedProfile.profiles_id} : {},
      authorization: loginResponse.response.customers_token,
    });

    dispatch({
      type: types.CUSTOMER_LOGIN_SUCCESS,
      customerData: loginResponse.response,
      selectedProfile: fullProfileInfo.selectedProfile,
    });

    // const hpUrl = getHpUrl(getState().portal.motv_portals_sections, getState().portal.motv_portals_account_update_enabled);
    // history.push(hpUrl || "/");
    return;
  }

  setLogedUserToClientStorage({
    authorization: loginResponse.response.customers_token,
  });

  dispatch({
    type: types.CUSTOMER_LOGIN_SUCCESS,
    customerData: loginResponse.response,
  });

};

export const customer_loginApple = (param) => async(dispatch, getState) => {
  dispatch({type: types.CUSTOMER_LOGIN_APPLE_REQUEST});

  const loginResponse = await fetch_sms_devicesAppleApiLogin(dispatch, param);

  if(!isSuccessResponse(loginResponse)) {
    dispatch({type: types.CUSTOMER_LOGIN_APPLE_FAILURE, data: loginResponse});
    return;
  }

  if( // máme jen jeden profil a nemám vynucené zadání PIN z MW, rovnou ho přihlásíme
    loginResponse.response.profiles.length === 1
    && !loginResponse.response.profiles[0].profiles_login_requires_pin
  ) {
    // const profileInfoResponse = await fetch_customer_profileSelected(dispatch, {
    //   profilesId: loginResponse.response.profiles[0].profiles_id,
    //   customerToken: loginResponse.response.customers_token,
    // });

    const profileId = loginResponse.response.profiles[0].profiles_id;

    const fullProfileInfo = await dispatch(getFullProfileInfo({profileId, loginResponse}));

    setLogedUserToClientStorage({
      ...(fullProfileInfo.selectedProfile) ? {profilesId: fullProfileInfo.selectedProfile.profiles_id} : {},
      authorization: loginResponse.response.customers_token,
    });

    dispatch({
      type: types.CUSTOMER_LOGIN_SUCCESS,
      customerData: loginResponse.response,
      selectedProfile: fullProfileInfo.selectedProfile,
    });

    // const hpUrl = getHpUrl(getState().portal.motv_portals_sections, getState().portal.motv_portals_account_update_enabled);
    // history.push(hpUrl || "/");
    return;
  }

  setLogedUserToClientStorage({
    authorization: loginResponse.response.customers_token,
  });

  dispatch({
    type: types.CUSTOMER_LOGIN_SUCCESS,
    customerData: loginResponse.response,
  });

};
