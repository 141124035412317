import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/styles";

const _YoutubeEmbed = styled("div")({
  overflow: "hidden",
  paddingBottom: "70vh",
  position: "relative",
  height: 0,
  marginBottom: "16px",
  "& iframe": {
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    position: "absolute",
  },
});

const YoutubeEmbed = ({ embedId }) => {
  return (
    <_YoutubeEmbed>
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}?autoplay=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </_YoutubeEmbed>
  );
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
