export const BOX_ITEM_CATEGORY_TYPE = "Category";
export const BOX_ITEM_GENRE_TYPE = "Genre";
export const BOX_ITEM_CHANNEL_TYPE = "Channel";
export const BOX_ITEM_IMAGE_TYPE = "Image";
export const BOX_ITEM_VIDEO_TYPE = "Video";
export const BOX_ITEM_PERSON_TYPE = "Person";
export const BOX_ITEM_RECORDIN_CATEGORY_TYPE = "RecordingCategory";
export const BOX_ITEM_RECORDING_TYPE = "Recording";
export const BOX_ITEM_TV_TYPE = "TV";
export const BOX_ITEM_VOD_TYPE = "VOD";
export const BOX_ITEM_YOUTUBE_TYPE = "Youtube";
