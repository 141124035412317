import { actionTypes as types } from '../constants'

const defaultState = {
  availableProducts: [],
  loaded: true,
  needRefreshAllService: false,
  redirectToStatusPage: false,
  status: undefined,
}

const cicoSubscriptions = (state = defaultState, action) => {
  switch (action.type) {
    case types.CICO__GET_AVAILABLE_PRODUCTS__REQUEST:
      return { ...state, loaded: false }
    case types.CICO__GET_AVAILABLE_PRODUCTS__SUCCESS:
      return { ...state, availableProducts: action.data.response, loaded: true }
    case types.CICO__GET_AVAILABLE_PRODUCTS__FAILURE:
      return { ...state, availableProducts: [], loaded: true }

    case types.CICO__GET_ORDER_URL__REQUEST:
      return { ...state, loaded: false }
    case types.CICO__GET_ORDER_URL__SUCCESS:
      return { ...state,
        loaded: true }
      case types.CICO__GET_ORDER_URL__FAILURE:
        return { ...state, orderUrl: undefined, loaded: true }

    case types.CICO__GET_SUBSCRIPTION_STATUS__REQUEST:
      return { ...state, loaded: false }
    case types.CICO__GET_SUBSCRIPTION_STATUS__SUCCESS:
      return { ...state,
        status: action.data.response,
        loaded: true }
      case types.CICO__GET_SUBSCRIPTION_STATUS__FAILURE:
        return { ...state, loaded: true }

    case types.CICO__CONFIRM_ORDER__REQUEST:
      return { ...state, loaded: false }
    case types.CICO__CONFIRM_ORDER__SUCCESS:
      return { ...state, loaded: true, redirectToStatusPage: true }
    case types.CICO__CONFIRM_ORDER__FAILURE:
      return { ...state, loaded: true, redirectToStatusPage: false }

    case types.CICO__CANCEL_SUBSCRIPTION__REQUEST:
      return { ...state, loaded: false }
    case types.CICO__CANCEL_SUBSCRIPTION__SUCCESS:
      return { ...state, loaded: true, needRefreshAllService: true }
    case types.CICO__CANCEL_SUBSCRIPTION__FAILURE:
      return { ...state, loaded: true, needRefreshAllService: false }

    case types.CICO__LOAD_PACKAGES__REQUEST:
      return { ...state, loaded: false }
    case types.CICO__LOAD_PACKAGES__SUCCESS:
      return { ...state, loaded: true, ...action.dataForReducer }
    case types.CICO__LOAD_PACKAGES__FAILURE:
      return { ...state, ...defaultState, loaded: true, needRefreshAllService: false }

    case types.CICO__SET_PARAM:
      return { ...state, ...action.param }

    case types.RESET_STATE:
      return defaultState

    default:
      return state
  }
}

export default cicoSubscriptions
