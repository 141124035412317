import { getEpgItemUrl } from '../epg/getEpgItemUrl';
import { getRecItemUrl } from '../recording/getRecItemUrl';
import * as boxItemTypes from '../../constants/boxItemTypes';
import { getVodItemUrl } from '../url/getVodItemUrl';

export const getUrlFromItemType = (item) =>
    (item.type === boxItemTypes.BOX_ITEM_TV_TYPE)  ? getEpgItemUrl(item)
  : (item.type === boxItemTypes.BOX_ITEM_CHANNEL_TYPE) ? `/tv/player/${item.channels_id}`
  : (item.type === boxItemTypes.BOX_ITEM_VOD_TYPE) ? getVodItemUrl(item) // `/vod/player/${item.id}`
  : (item.type === boxItemTypes.BOX_ITEM_RECORDIN_CATEGORY_TYPE) ? getRecItemUrl(item)
  : (item.type === boxItemTypes.BOX_ITEM_RECORDING_TYPE) ? getRecItemUrl(item) // change from /recording/player/${item.id}
  : (item.type === boxItemTypes.BOX_ITEM_CATEGORY_TYPE) ? `/category/${item.id}`
  : (item.type === boxItemTypes.BOX_ITEM_IMAGE_TYPE && item.action && item.action !== "") ? `${item.action}`
  : (item.type === boxItemTypes.BOX_ITEM_IMAGE_TYPE && item.action_type === "enlarge") ? `#show-detail`
  : (item.persons_id) ? `/person/${item.persons_id}`
  : "/"
;
