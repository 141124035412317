import {
  fetch_channel_getData,
  fetch_recording_addRecording,
  fetch_recording_getDataV2,
  fetch_recording_getEventDetailV2,
  fetch_recording_getRecordingsByProfile,
  fetch_recording_getStreamUrlV3,
  fetch_recording_removeRecording,
} from "../fetchs";

import { actionTypes as types } from "../constants";
import { convertToTimeCode } from "../helpers/datetime";
import { formatISO } from "date-fns";
import { getDataFromChannelStreamResponse } from "../helpers/channel/getDataFromChannelStreamResponse";
import { getEdgesForStreamUrl } from "../helpers/player/getEdgesForStreamUrl";
import { getExtendedRecItem } from '../helpers/recording/getExtendedRecItem';
import { isSuccessResponse } from "../helpers/fetch/isSuccessResponse";
import { removeEpgEventsPrefix } from "../helpers/epg/removeEpgEventsPrefix";
import { transformRecommendationResponseForReduce } from "../helpers/recommendation/transformRecommendationResponseForReduce";
import {customer_getData} from "./customer";

export const recording_getHp = () => async (dispatch, getState) => {
  dispatch({ type: types.RECORDING_GET_HP_REQUEST });

  const { hpRowsResponse } = await Promise.all([
    dispatch(fetch_recording_getRecordingsByProfile()),
  ]).then(([hpRowsResponse]) => ({
    hpRowsResponse,
  }));

  if (!isSuccessResponse(hpRowsResponse)) {
    dispatch({ type: types.RECORDING_GET_HP_FAILURE });
    return;
  }

  dispatch({
    type: types.RECORDING_GET_HP_SUCCESS,
    recommendationUpdate: transformRecommendationResponseForReduce({
      recommendationResponse: hpRowsResponse.response,
      includeData: true,
      prevRecommendationRows: getState().recommendation.rows,
    }),
  });
};

export const recording_addRecording = (eventItem) => async (dispatch) => {
  await fetch_recording_addRecording(dispatch, eventItem);
  dispatch(customer_getData());
};

export const recording_removeRecording = (eventItem) => async (dispatch) => {
  await fetch_recording_removeRecording(dispatch, eventItem);
  dispatch(customer_getData());
};

export const recording_getStreamUrl = (param) => (dispatch) => {
  dispatch(fetch_recording_getDataV2({
    epgEventsId: param.epgEventsId,
  }))
  .then(
    () => {
      fetch_recording_getStreamUrlV3(dispatch, param);
    },
    (error) => {
      console.error("recording_getStreamUrl failed", { error });
    }
  );
};

export const recording_loadPlayer = (param) => async (dispatch, getState) => {
  dispatch({ type: types.RECORDING_LOAD_PLAYER_REQUEST });

  const {
    edge: { items: edgeItems },
  } = getState();

  const recordingData = await dispatch(fetch_recording_getDataV2({
    epgEventsId: param.epgEventsId,
  }));
  const recordingStreamResponse = await fetch_recording_getStreamUrlV3(
    dispatch,
    {
      ...param,
      ...getEdgesForStreamUrl(edgeItems),
    }
  );

  const channelsId = recordingData?.response?.channels_id;
  const recordingItem = recordingData?.response;
  const streamData = getDataFromChannelStreamResponse(recordingStreamResponse);

  if (channelsId === undefined || streamData === undefined) {
    dispatch({ type: types.RECORDING_LOAD_PLAYER_FAILURE });
  }
  const channelData = await fetch_channel_getData(dispatch, { channelsId });
  const channelItem = channelData?.response;

  if (channelItem === undefined) {
    dispatch({ type: types.RECORDING_LOAD_PLAYER_FAILURE });
  }

  const dataForReduce = [
    {
      ...streamData,
      dateOfResponseStream: formatISO(new Date()),
      duration: recordingItem.duration,
      takeRealDurationFromVideoElement: true,
      follow: streamData.follow === null ? 0 : streamData.follow,
      needEnterPin: channelItem.channels_forced_pin === 1,
      selectedChannelItem: channelItem,
      selectedRecordingItem: removeEpgEventsPrefix(recordingItem),
      startTime: 0,
    },
  ].map((dataForReduceItem) => ({
    ...dataForReduceItem,
    readAbleVideoActualPosition: convertToTimeCode(0),
    readAbleVideoDuration: convertToTimeCode(dataForReduceItem.duration),
  }))[0];

  dispatch({
    type: types.RECORDING_LOAD_PLAYER_SUCCESS,
    dataForReduce,
  });
};

export const recording_setParam = (param) => (dispatch) => {
  dispatch({
    type: types.RECORDING_SET_PARAM,
    param: param,
  });
};

export const recording_openDialogWithBoxItem = ({boxItem}) => async(dispatch) => {
  dispatch({
    type: types.RECORDING_DIALOG_WITH_BOX_ITEM_REQUEST,
    recordingUpdate: {
      dialogRec: getExtendedRecItem({...boxItem,
        desc: boxItem.description,
        genres: boxItem.genres,
        icon: boxItem.image,
      }),
      dialogRecIsOpen: true,
    }
  });

  const {
    eventItemResponse
  } = await Promise.all([
    dispatch(fetch_recording_getEventDetailV2(boxItem.id))
  ]).then(([eventItemResponse]) => ({eventItemResponse}));

  if(
    !isSuccessResponse(eventItemResponse)
    || eventItemResponse.response.length < 1
  ) {
    dispatch({type: types.RECORDING_DIALOG_WITH_BOX_ITEM_FAILURE});
    return;
  }

  dispatch({
    type: types.RECORDING_DIALOG_WITH_BOX_ITEM_SUCCESS,
    recordingUpdate: {
      dialogRec: getExtendedRecItem(eventItemResponse.response[0]),
      dialogRecIsOpen: true,
    }
  });
};


export const recording_getEventDetail = (id) => (dispatch) =>
  dispatch(fetch_recording_getEventDetailV2(id));
